
export function getLPInfo(query) {
    const mockData = {
        status: 200,
        data: [
            {
                "id": 1,
                "lp_name": "lp-1",
                "active": 1,
                "created_at": "2022-05-24 10:22:00",
                "updated_at": "2023-04-21 12:07:05"
            },
            {
                "id": 3,
                "lp_name": "demo_lp_3",
                "active": 0,
                "created_at": "2022-05-24 10:22:00",
                "updated_at": "2023-04-21 12:07:05"
            },
            {
                "id": 2,
                "lp_name": "lp-2",
                "active": 1,
                "created_at": "2022-05-24 10:22:00",
                "updated_at": "2023-04-21 12:07:05"
            }
        ]
    };
    const returnVal = new Promise((resolve, _) => resolve(mockData));
    return returnVal;
}

export function putLPInfo(query) {
    const mockData = {
        status: 200,
        data: { 'msg': 'Update LP name successfully' }
    };
    const returnVal = new Promise((resolve, _) => resolve(mockData));
    return returnVal;
}