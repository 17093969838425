
export function getBroker(query) {
    const mockData = {
        status: 200,
        data: [
            {
                "id": 1,
                "broker_name": "Demo",
                "description": "Demo Broker",
                "created_at": "2022-05-22 23:39:40",
                "updated_at": "2023-06-28 21:38:05"
            }
          ]
    };
    const returnVal = new Promise((resolve, _) => resolve(mockData));
    return returnVal;
}

export function postBroker(query) {
    const mockData = {
        status: 200,
        data: { 'msg': 'Create broker successfully' }
    };
    const returnVal = new Promise((resolve, _) => resolve(mockData));
    return returnVal;
}
export function putBroker(query) {
    const mockData = {
        status: 200,
        data: { 'msg': 'Update broker successfully' }
    };
    const returnVal = new Promise((resolve, _) => resolve(mockData));
    return returnVal;
}
export function deleteBroker(query) {
    const mockData = {
        status: 200,
        data: { 'msg': 'Delete broker successfully' }
    };
    const returnVal = new Promise((resolve, _) => resolve(mockData));
    return returnVal;
}
