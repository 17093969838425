export function getDataLargeVolumeUSD(query) {
    const mockData = {
        status: 200,
        data: {
            data: [
                {
                    server_id: 39,
                    login: "xxxxxx60",
                    name: "Dominiak",
                    volume: 546010,
                    symbol: "EURUSD",
                    books: "B",
                    order: "yyyyyy66",
                    direction: "LONG",
                    threshold_volume: 500000,
                    open_time: "2024-08-05 00:01:08",
                    close_time: "1970-01-01 00:00:00",
                    server_name: "MT4-Demo",
                    trigger_time: "2024-08-05 00:37:59",
                    updated_at: "2024-08-04 17:46:27",
                    action: "OPEN",
                },
                {
                    server_id: 24,
                    login: "xxxxxx88",
                    name: "Changalov",
                    volume: -1092320,
                    symbol: "EURUSD",
                    books: "B",
                    order: "yyyyyy30",
                    direction: "SHORT",
                    threshold_volume: 500000,
                    open_time: "2024-08-05 00:17:16",
                    close_time: "1970-01-01 00:00:00",
                    server_name: "MT4-Demo",
                    trigger_time: "2024-08-05 00:38:10",
                    updated_at: "2024-08-04 17:47:35",
                    action: "OPEN",
                },
                {
                    server_id: 18,
                    login: "xxxxxx79",
                    name: "Brown",
                    volume: 545950.56581728,
                    symbol: "EURCAD",
                    books: "B",
                    order: "yyyyyy73",
                    direction: "LONG",
                    threshold_volume: 500000,
                    open_time: "2024-08-05 00:08:55",
                    close_time: "1970-01-01 00:00:00",
                    server_name: "MT4-Demo",
                    trigger_time: "2024-08-05 00:50:01",
                    updated_at: "2024-08-04 18:10:01",
                    action: "OPEN",
                },
            ],
            count: 3,
        },
    };
    const returnVal = new Promise((resolve, _) => resolve(mockData));
    return returnVal;
}
