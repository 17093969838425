
export function getTick() {
    const mockData = {
        status: 200,
        data: {
            "data": [
                {
                    "last_update_time": "2024-08-05 04:24:17",
                    "current_time": "2024-08-05 04:38:00",
                    "seconds_threshold": 300,
                    "symbol": "EURHUF.c",
                    "server_id": "1",
                    "gap": 823,
                    "action": "required",
                    "server_name": "MT4-Demo",
                    "security": "Forex-cents"
                },
                {
                    "last_update_time": "2024-07-29 06:14:24",
                    "current_time": "2024-07-29 06:29:01",
                    "seconds_threshold": 300,
                    "symbol": "UKO09-R",
                    "server_id": "1",
                    "gap": 877,
                    "action": "required",
                    "server_name": "MT4-Demo",
                    "security": "Test"
                },
                {
                    "last_update_time": "2024-07-29 06:14:24",
                    "current_time": "2024-07-29 06:29:01",
                    "seconds_threshold": 300,
                    "symbol": "UKO09-P",
                    "server_id": "1",
                    "gap": 877,
                    "action": "required",
                    "server_name": "MT4-Demo",
                    "security": "Test"
                },
                {
                    "last_update_time": "2024-07-25 04:42:04",
                    "current_time": "2024-07-25 04:54:00",
                    "seconds_threshold": 300,
                    "symbol": "XAU08",
                    "server_id": "1",
                    "gap": 716,
                    "action": "required",
                    "server_name": "MT4-Demo",
                    "security": "Test"
                },
                {
                    "last_update_time": "2024-07-25 04:42:04",
                    "current_time": "2024-07-25 04:54:00",
                    "seconds_threshold": 300,
                    "symbol": "XAU08-P",
                    "server_id": "1",
                    "gap": 716,
                    "action": "required",
                    "server_name": "MT4-Demo",
                    "security": "Test"
                },
                {
                    "last_update_time": "2024-08-05 04:19:44",
                    "current_time": "2024-08-05 04:38:00",
                    "seconds_threshold": 300,
                    "symbol": "NGAS",
                    "server_id": "1",
                    "gap": 1096,
                    "action": "required",
                    "server_name": "MT4-Demo",
                    "security": "Commodity"
                },
                {
                    "last_update_time": "2024-08-05 04:24:17",
                    "current_time": "2024-08-05 04:38:00",
                    "seconds_threshold": 300,
                    "symbol": "EURHUF-E",
                    "server_id": "1",
                    "gap": 823,
                    "action": "required",
                    "server_name": "MT4-Demo",
                    "security": "Forex-E"
                },
                {
                    "last_update_time": "2024-07-29 06:14:24",
                    "current_time": "2024-07-29 06:29:01",
                    "seconds_threshold": 300,
                    "symbol": "UKO09",
                    "server_id": "1",
                    "gap": 877,
                    "action": "required",
                    "server_name": "MT4-Demo",
                    "security": "Test"
                },
                {
                    "last_update_time": "2024-08-05 04:24:17",
                    "current_time": "2024-08-05 04:38:00",
                    "seconds_threshold": 300,
                    "symbol": "EURHUF-R",
                    "server_id": "1",
                    "gap": 823,
                    "action": "required",
                    "server_name": "MT4-Demo",
                    "security": "Forex-R"
                },
                {
                    "last_update_time": "2024-07-25 04:42:04",
                    "current_time": "2024-07-25 04:54:00",
                    "seconds_threshold": 300,
                    "symbol": "XAU08-R",
                    "server_id": "1",
                    "gap": 716,
                    "action": "required",
                    "server_name": "MT4-Demo",
                    "security": "Test"
                },
                {
                    "last_update_time": "2024-07-25 04:42:04",
                    "current_time": "2024-07-25 04:54:00",
                    "seconds_threshold": 300,
                    "symbol": "XAU08-E",
                    "server_id": "1",
                    "gap": 716,
                    "action": "required",
                    "server_name": "MT4-Demo",
                    "security": "Test"
                },
                {
                    "last_update_time": "2024-07-21 10:27:54",
                    "current_time": "2024-07-21 10:49:01",
                    "seconds_threshold": 300,
                    "symbol": "XRPJPY-R",
                    "server_id": "1",
                    "gap": 1267,
                    "action": "required",
                    "server_name": "MT4-Demo",
                    "security": "Crypto-R"
                },
                {
                    "last_update_time": "2024-07-29 06:14:24",
                    "current_time": "2024-07-29 06:29:01",
                    "seconds_threshold": 300,
                    "symbol": "UKO09-E",
                    "server_id": "1",
                    "gap": 877,
                    "action": "required",
                    "server_name": "MT4-Demo",
                    "security": "Test"
                },
                {
                    "last_update_time": "2024-08-05 04:24:17",
                    "current_time": "2024-08-05 04:38:00",
                    "seconds_threshold": 300,
                    "symbol": "EURHUF-P",
                    "server_id": "1",
                    "gap": 823,
                    "action": "required",
                    "server_name": "MT4-Demo",
                    "security": "Forex-P"
                },
                {
                    "last_update_time": "2024-08-05 04:24:17",
                    "current_time": "2024-08-05 04:38:00",
                    "seconds_threshold": 300,
                    "symbol": "EURHUF",
                    "server_id": "1",
                    "gap": 823,
                    "action": "required",
                    "server_name": "MT4-Demo",
                    "security": "Forex"
                }
            ],
            "count": 15
        }
    };
    const returnVal = new Promise((resolve, _) => resolve(mockData));
    return returnVal;
}

