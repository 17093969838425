
export function getRouting(query) {
    if(query.server_id === 1){
        const mockData = {
            status: 200,
            data: [
                {
                    "id": 1440,
                    "server_id": 1,
                    "server_name": "MT5-Demo",
                    "priority": 1,
                    "filter_login": "*",
                    "filter_group": "regex[R_.*]",
                    "filter_symbol": "*",
                    "filter_symbol_group": "*",
                    "percentage_a": 100,
                    "percentage_b": 0,
                    "book_a_id": 7,
                    "book_a_name": "S",
                    "book_b_id": 3,
                    "book_b_name": "B",
                    "created_at": "2023-08-15 14:09:23",
                    "updated_at": "2023-08-15 14:09:23"
                },
                {
                    "id": 1441,
                    "server_id": 1,
                    "server_name": "MT5-Demo",
                    "priority": 2,
                    "filter_login": "*",
                    "filter_group": "regex[P_.*]",
                    "filter_symbol": "*",
                    "filter_symbol_group": "*",
                    "percentage_a": 100,
                    "percentage_b": 0,
                    "book_a_id": 8,
                    "book_a_name": "B",
                    "book_b_id": 3,
                    "book_b_name": "B",
                    "created_at": "2023-08-15 14:09:23",
                    "updated_at": "2023-08-15 14:09:23"
                },
                {
                    "id": 1442,
                    "server_id": 1,
                    "server_name": "MT5-Demo",
                    "priority": 3,
                    "filter_login": "*",
                    "filter_group": "regex[H_Hedge_USD]",
                    "filter_symbol": "*",
                    "filter_symbol_group": "*",
                    "percentage_a": 100,
                    "percentage_b": 0,
                    "book_a_id": 6,
                    "book_a_name": "Test",
                    "book_b_id": 4,
                    "book_b_name": "S",
                    "created_at": "2023-08-15 14:09:23",
                    "updated_at": "2023-08-15 14:09:23"
                },
                {
                    "id": 1443,
                    "server_id": 1,
                    "server_name": "MT5-Demo",
                    "priority": 4,
                    "filter_login": "*",
                    "filter_group": "regex[H_PRO_USD]",
                    "filter_symbol": "*",
                    "filter_symbol_group": "*",
                    "percentage_a": 100,
                    "percentage_b": 0,
                    "book_a_id": 10,
                    "book_a_name": "Test",
                    "book_b_id": 5,
                    "book_b_name": "B",
                    "created_at": "2023-08-15 14:09:23",
                    "updated_at": "2023-08-15 14:09:23"
                },
                {
                    "id": 1444,
                    "server_id": 1,
                    "server_name": "MT5-Demo",
                    "priority": 5,
                    "filter_login": "xxxxxx32",
                    "filter_group": "*",
                    "filter_symbol": "*",
                    "filter_symbol_group": "*",
                    "percentage_a": 100,
                    "percentage_b": 0,
                    "book_a_id": 13,
                    "book_a_name": "B",
                    "book_b_id": 4,
                    "book_b_name": "S",
                    "created_at": "2023-08-15 14:09:23",
                    "updated_at": "2023-08-15 14:09:23"
                },
            ]
        };
        const returnVal = new Promise((resolve, _) => resolve(mockData));
        return returnVal;
    }
    if(query.server_id === 2){
        const mockData = {
            status: 200,
            data: [
                {
                    "id": 12,
                    "server_id": 2,
                    "server_name": "MT4-Demo",
                    "priority": 1,
                    "filter_login": "regex[^(?:[a-z0-9!#$%&'*+/=?^_{|}~-]+(?:.[a-z0-9!#$%&'*+/=?^_{|}~-]+)*|\"(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21\\x23-\\x5b\\x5d-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])*\")@(?:(?!.*[^.]{64})(?:(?!-)[a-zA-Z0-9-]{1,63}(?<!-)\\.)+(?!.*[^.]{64})(?:[a-zA-Z]{2,63}|xn--[a-zA-Z0-9]+))$]",
                    "filter_group": "*",
                    "filter_symbol": "*",
                    "filter_symbol_group": "*",
                    "percentage_a": 100,
                    "percentage_b": 0,
                    "book_a_id": 4,
                    "book_a_name": "S",
                    "book_b_id": 5,
                    "book_b_name": "B",
                    "created_at": "2023-03-22 10:02:28",
                    "updated_at": "2023-03-22 10:02:28"
                }
            ]
        };
        const returnVal = new Promise((resolve, _) => resolve(mockData));
        return returnVal;
    }
}

export function postRouting(query) {
    const mockData = {
        status: 200,
        data: { 'msg': 'Create routing profile successfully', 'data': [
            {
                "id": 1439,
                "server_id": 1,
                "server_name": "MT5-Demo",
                "priority": 0,
                "filter_login": "*",
                "filter_group": "regex[T_.*]",
                "filter_symbol": "*",
                "filter_symbol_group": "*",
                "percentage_a": 100,
                "percentage_b": 0,
                "book_a_id": 9,
                "book_a_name": "Test",
                "book_b_id": 3,
                "book_b_name": "B",
                "created_at": "2023-08-15 14:09:23",
                "updated_at": "2023-08-15 14:09:23"
            },
            {
                "id": 1440,
                "server_id": 1,
                "server_name": "MT5-Demo",
                "priority": 1,
                "filter_login": "*",
                "filter_group": "regex[R_.*]",
                "filter_symbol": "*",
                "filter_symbol_group": "*",
                "percentage_a": 100,
                "percentage_b": 0,
                "book_a_id": 7,
                "book_a_name": "S",
                "book_b_id": 3,
                "book_b_name": "B",
                "created_at": "2023-08-15 14:09:23",
                "updated_at": "2023-08-15 14:09:23"
            },
            {
                "id": 1441,
                "server_id": 1,
                "server_name": "MT5-Demo",
                "priority": 2,
                "filter_login": "*",
                "filter_group": "regex[P_.*]",
                "filter_symbol": "*",
                "filter_symbol_group": "*",
                "percentage_a": 100,
                "percentage_b": 0,
                "book_a_id": 8,
                "book_a_name": "B",
                "book_b_id": 3,
                "book_b_name": "B",
                "created_at": "2023-08-15 14:09:23",
                "updated_at": "2023-08-15 14:09:23"
            },
            {
                "id": 1442,
                "server_id": 1,
                "server_name": "MT5-Demo",
                "priority": 3,
                "filter_login": "*",
                "filter_group": "regex[H_Hedge_USD]",
                "filter_symbol": "*",
                "filter_symbol_group": "*",
                "percentage_a": 100,
                "percentage_b": 0,
                "book_a_id": 6,
                "book_a_name": "Test",
                "book_b_id": 4,
                "book_b_name": "S",
                "created_at": "2023-08-15 14:09:23",
                "updated_at": "2023-08-15 14:09:23"
            },
            {
                "id": 1443,
                "server_id": 1,
                "server_name": "MT5-Demo",
                "priority": 4,
                "filter_login": "*",
                "filter_group": "regex[H_PRO_USD]",
                "filter_symbol": "*",
                "filter_symbol_group": "*",
                "percentage_a": 100,
                "percentage_b": 0,
                "book_a_id": 10,
                "book_a_name": "Test",
                "book_b_id": 5,
                "book_b_name": "B",
                "created_at": "2023-08-15 14:09:23",
                "updated_at": "2023-08-15 14:09:23"
            },
            {
                "id": 1444,
                "server_id": 1,
                "server_name": "MT5-Demo",
                "priority": 5,
                "filter_login": "xxxxxx32",
                "filter_group": "*",
                "filter_symbol": "*",
                "filter_symbol_group": "*",
                "percentage_a": 100,
                "percentage_b": 0,
                "book_a_id": 13,
                "book_a_name": "B",
                "book_b_id": 4,
                "book_b_name": "S",
                "created_at": "2023-08-15 14:09:23",
                "updated_at": "2023-08-15 14:09:23"
            },
            {
                "id": 1451,
                "server_id": 1,
                "server_name": "MT5-Demo",
                "priority": 11,
                "filter_login": "xxxxxx60",
                "filter_group": "*",
                "filter_symbol": "*",
                "filter_symbol_group": "*",
                "percentage_a": 100,
                "percentage_b": 0,
                "book_a_id": 13,
                "book_a_name": "All",
                "book_b_id": 4,
                "book_b_name": "S",
                "created_at": "2023-08-15 14:09:23",
                "updated_at": "2023-08-15 14:09:23"
            },
        ] }
    };
    const returnVal = new Promise((resolve, _) => resolve(mockData));
    return returnVal;
}

export function putRouting(query) {
    const mockData = {
        status: 200,
        data: { 'msg': 'Update routing profile successfully' }
    };
    const returnVal = new Promise((resolve, _) => resolve(mockData));
    return returnVal;
}

export function deleteRouting(query) {
    const mockData = {
        status: 200,
        data: { 'msg': 'Delete routing profile successfully' }
    };
    const returnVal = new Promise((resolve, _) => resolve(mockData));
    return returnVal;
}

// export function getOrderRoutingMapping(params) {
//     return request({
//         url: 'oz/settings-order-routing-mapping',
//         method: 'get',
//         params
//     })
// }