<template>
    <v-app id="inspire">
        <v-app-bar class="bar" app color="#1A3958" dark height="40px">
            <div class="d-flex align-center">
                <v-app-bar-nav-icon
                    v-if="VUETIFY_BREAKPOINT.mobile"
                    class="mr-4"
                    @click.stop="drawer = !drawer"
                ></v-app-bar-nav-icon>
                <v-img src="@assets/logo_icon.png" width="25"></v-img>

                <v-toolbar-title class="ml-4 mr-4">{{
                    pkTitle
                }}</v-toolbar-title>
            </div>
            <template v-if="!VUETIFY_BREAKPOINT.mobile">
                <a
                    class="mt-0 mb-0"
                    v-if="showMenuButton"
                    @click="() => moveDiv(-1)"
                >
                    <v-icon :color="disableToMoveLeft ? 'grey' : 'white'"
                        >mdi-menu-left</v-icon
                    >
                </a>
                <div class="scrollmenu" id="timeDiv">
                    <CurrentLiveTime :name="'NY'" :DST="true" />
                    <CurrentLiveTime :name="'LDN'" :DST="false" />
                    <CurrentLiveTime :name="'MT'" :DST="false" />
                    <CurrentLiveTime :name="'JKT'" :DST="true" />
                    <CurrentLiveTime :name="'BJ'" :DST="true" />
                    <CurrentLiveTime :name="'SYD'" :DST="true" />
                </div>
                <v-spacer></v-spacer>
                <a
                    class="mt-0 mb-0"
                    v-if="showMenuButton"
                    @click="() => moveDiv(1)"
                >
                    <v-icon :color="disableToMoveRight ? 'grey' : 'white'"
                        >mdi-menu-right</v-icon
                    >
                </a>
            </template>

            <div class="d-flex align-center">
                <v-tooltip
                    bottom
                    content-class="pumpingStatusTooltip"
                    color="rgba(97, 97, 97, 1)"
                    v-if="!VUETIFY_BREAKPOINT.mobile"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            dark
                            v-bind="attrs"
                            v-on="on"
                            :color="
                                pumpingStatus === 'success'
                                    ? 'green'
                                    : pumpingStatus === 'fail'
                                    ? 'red'
                                    : 'orange'
                            "
                            class="mr-1"
                            >mdi-list-status</v-icon
                        >
                    </template>
                    <table>
                        <tr v-for="i in rowNumber">
                            <td
                                v-for="j in Math.ceil(
                                    pumpingStatusList.length / rowNumber
                                )"
                            >
                                <div
                                    v-if="
                                        isValidServerItem(
                                            pumpingStatusList[
                                                i - 1 + (j - 1) * rowNumber
                                            ]
                                        )
                                    "
                                >
                                    <v-icon
                                        x-small
                                        :color="
                                            pumpingStatusList[
                                                i - 1 + (j - 1) * rowNumber
                                            ].alive
                                                ? 'green'
                                                : 'red'
                                        "
                                        class="pr-1"
                                        >mdi-circle</v-icon
                                    >
                                    <span
                                        >{{
                                            pumpingStatusList[
                                                i - 1 + (j - 1) * rowNumber
                                            ].server
                                        }}
                                    </span>
                                </div>
                            </td>
                        </tr>
                    </table>
                </v-tooltip>
                <!-- <v-tooltip bottom v-if="!VUETIFY_BREAKPOINT.mobile">
                <template v-slot:activator="{ on, attrs }">
                    <v-icon
                        dark
                        v-bind="attrs"
                        v-on="on"
                        color="orange"
                        class="mr-2"
                        @click="holidayDialog = true"
                        >mdi-calendar-weekend</v-icon
                    >
                </template>
                <span>Click to see holiday information</span>
            </v-tooltip> -->
                <v-menu
                    offset-y
                    :nudge-top="0"
                    open-on-hover
                    :close-on-content-click="false"
                    tile
                    z-index="1000000"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <a
                            v-bind="attrs"
                            v-on="on"
                            style="
                                color: white;
                                min-width: 80px;
                                white-space: nowrap;
                            "
                            class="mr-20"
                        >
                            Hi, {{ displayName }}
                        </a>
                    </template>
                    <v-list
                        class="pl-0 pr-0 pt-0 pb-0"
                        style="text-align: left; border-radius: 0px"
                    >
                        <template v-if="!VUETIFY_BREAKPOINT.mobile">
                            <v-list-item
                                dense
                                to="/account/permission"
                                v-if="loadPermission == 1"
                            >
                                <v-list-item-icon class="mr-0"
                                    ><v-icon small
                                        >mdi-shield</v-icon
                                    ></v-list-item-icon
                                >
                                <v-list-item-title
                                    >Permission</v-list-item-title
                                >
                            </v-list-item>

                            <v-list-item
                                dense
                                to="/account/management"
                                v-if="loadManagement == 1"
                            >
                                <v-list-item-icon class="mr-0"
                                    ><v-icon small
                                        >mdi-folder-multiple</v-icon
                                    ></v-list-item-icon
                                >
                                <v-list-item-title
                                    >Management</v-list-item-title
                                >
                            </v-list-item>

                            <v-list-item
                                dense
                                to="/system/settings"
                                v-if="loadSettings == 1"
                            >
                                <v-list-item-icon class="mr-0"
                                    ><v-icon small
                                        >mdi-cog-outline</v-icon
                                    ></v-list-item-icon
                                >
                                <v-list-item-title>Settings</v-list-item-title>
                            </v-list-item>

                            <v-list-item
                                dense
                                to="/account/security"
                                v-if="loadSecurity == 1"
                            >
                                <v-list-item-icon class="mr-0"
                                    ><v-icon small
                                        >mdi-shield-account</v-icon
                                    ></v-list-item-icon
                                >
                                <v-list-item-title>Security</v-list-item-title>
                            </v-list-item>

                            <v-divider></v-divider>
                        </template>

                        <v-list-item dense @click.stop="logout()">
                            <v-list-item-icon class="mr-0"
                                ><v-icon small
                                    >mdi-logout</v-icon
                                ></v-list-item-icon
                            >
                            <v-list-item-title
                                ><v-btn
                                    text
                                    :loading="logoutLoading"
                                    @click.prevent
                                    class="pl-0 pr-0"
                                    >Logout</v-btn
                                ></v-list-item-title
                            >
                        </v-list-item>
                    </v-list>
                </v-menu>
            </div>
        </v-app-bar>

        <v-navigation-drawer
            v-if="VUETIFY_BREAKPOINT.mobile"
            v-model="drawer"
            absolute
            temporary
        >
            <v-list nav dense>
                <v-list-item-group
                    v-model="group"
                    active-class="text--accent-4"
                >
                    <v-list-item
                        v-for="item in drawerRouteList"
                        :key="item.label"
                        :to="item.path"
                    >
                        <v-list-item-title>{{ item.label }}</v-list-item-title>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-navigation-drawer>

        <v-main class="pt-0">
            <v-container fluid id="global-container">
                <HeaderMenu />

                <router-view></router-view>

                <div class="text-center">
                    <v-overlay :value="this.$store.state.overlay">
                        <v-progress-circular
                            indeterminate
                            :size="70"
                            :width="7"
                            color="primary"
                        ></v-progress-circular>
                        <p>Loading Data...</p>
                    </v-overlay>
                </div>
            </v-container>
            <v-dialog
                v-model="holidayDialog"
                width="1000"
                :persistent="true"
                style="overflow: hidden"
            >
                <v-card class="pt-0">
                    <v-app-bar
                        flat
                        color="rgba(0, 0, 0, 0)"
                        style="background: rgb(26, 57, 88)"
                    >
                        <v-toolbar-title class="text-h6 white--text pl-0">
                            Holiday Information
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="white"
                            icon
                            @click="holidayDialog = false"
                        >
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-app-bar>

                    <v-card-actions>
                        <v-row>
                            <v-col cols="1"
                                ><div
                                    style="
                                        display: flex;
                                        align-items: end;
                                        justify-content: center;
                                        height: 100%;
                                    "
                                >
                                    Server:
                                </div></v-col
                            ><v-col cols="3">
                                <v-autocomplete
                                    single-line
                                    v-model="selectedServer"
                                    :items="serverOptions"
                                    item-text="server_name"
                                    item-value="id"
                                    clearable
                                    hide-details
                                >
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="1"
                                ><div
                                    style="
                                        display: flex;
                                        align-items: end;
                                        justify-content: center;
                                        height: 100%;
                                    "
                                >
                                    Symbol:
                                </div></v-col
                            ><v-col cols="3">
                                <v-autocomplete
                                    single-line
                                    v-model="selectedSymbol"
                                    :items="symbolOptions"
                                    clearable
                                    hide-details
                                >
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="2"
                                ><div
                                    style="
                                        display: flex;
                                        align-items: end;
                                        justify-content: center;
                                        height: 100%;
                                    "
                                >
                                    Show History:
                                </div></v-col
                            >
                            <v-col cols="2"
                                ><v-switch
                                    style="margin-top: 4px"
                                    v-model="showHistory"
                                    dense
                                    hide-details
                                ></v-switch
                            ></v-col>
                            <v-col cols="1"
                                ><div
                                    style="
                                        display: flex;
                                        align-items: end;
                                        justify-content: center;
                                        height: 100%;
                                    "
                                >
                                    Year:
                                </div></v-col
                            ><v-col cols="3">
                                <v-autocomplete
                                    single-line
                                    v-model="selectedYear"
                                    :items="yearOptions"
                                    clearable
                                    hide-details
                                >
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="1"
                                ><div
                                    style="
                                        display: flex;
                                        align-items: end;
                                        justify-content: center;
                                        height: 100%;
                                    "
                                >
                                    Month:
                                </div></v-col
                            ><v-col cols="3">
                                <v-autocomplete
                                    single-line
                                    v-model="selectedMonth"
                                    :items="MonthOptions"
                                    item-text="text"
                                    item-value="value"
                                    clearable
                                    hide-details
                                >
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="1"
                                ><div
                                    style="
                                        display: flex;
                                        align-items: end;
                                        justify-content: center;
                                        height: 100%;
                                    "
                                >
                                    Day:
                                </div></v-col
                            ><v-col cols="3">
                                <v-autocomplete
                                    single-line
                                    v-model="selectedDay"
                                    :items="dayOptions"
                                    clearable
                                    hide-details
                                >
                                </v-autocomplete>
                            </v-col>

                            <v-col cols="12"
                                ><v-data-table
                                    dense
                                    :headers="holidayTableHeader"
                                    :items="holidayTableData"
                                    :header-props="{ sortIcon: null }"
                                    item-key="id"
                                    class="elevation-0 flex-grow-1"
                                    :items-per-page="25"
                                    id="home-table"
                                    fixed-header
                                >
                                    <template
                                        v-slot:item.server_id="{ item }"
                                        >{{
                                            serverOptions.find(
                                                (data) =>
                                                    data.id === item.server_id
                                            ).server_name
                                        }}</template
                                    >
                                    <template
                                        v-slot:item.description="{ item }"
                                    >
                                        <div
                                            v-if="item.description.length < 53"
                                        >
                                            {{ item.description }}
                                        </div>
                                        <v-tooltip top v-else>
                                            <template
                                                v-slot:activator="{ on, attrs }"
                                            >
                                                <div v-bind="attrs" v-on="on">
                                                    {{
                                                        item.description.slice(
                                                            0,
                                                            53
                                                        )
                                                    }}...
                                                </div>
                                            </template>
                                            <span>{{ item.description }}</span>
                                        </v-tooltip>
                                    </template>
                                </v-data-table></v-col
                            >
                        </v-row>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-main>
    </v-app>
</template>

<script>
// import { List } from "immutable";
import HeaderMenu from "@components/HeaderMenu";
import CurrentLiveTime from "@components/ComponentCurrentLiveTime";
import { permission } from "@components/mixins/permission";
import { putUsers } from "@services/account/user";
import { logUserOut } from "@services/account/auth";
// import { getHolidayData } from "@services/holiday";
import dayjs from "dayjs";
import deepClone from "deep-clone";
import { mapState, mapActions } from "vuex";
import { getPumpingStatus } from "@services/pumping-status";
import { snackbar } from "@components/mixins/snackbar";

var isoWeek = require("dayjs/plugin/isoWeek");
dayjs.extend(isoWeek);

export default {
    name: "Layout",
    components: { CurrentLiveTime, HeaderMenu },
    mixins: [permission, snackbar],
    data: () => ({
        pumpingStatus: "success",
        pumpingStatusTimer: null,
        pumpingStatusList: [],

        loadManagement: 0,
        loadSecurity: 0,
        loadSettings: 0,
        loadPermission: 0,

        alertDrawer: false,
        channelList: [],
        consoleDrawer: false,
        selectedAlertPanel: 1,
        loadRing: true,
        profileUpdate: {
            home: false,
            alert: false,
        },
        logoutLoading: false,

        holidayDialog: false,
        holidayTableHeader: [
            { text: "Server", value: "server_id", width: 30, align: "left" },
            { text: "Symbol", value: "symbol", width: 60, align: "left" },
            { text: "Date", value: "date", width: 90, align: "left" },
            {
                text: "Description",
                value: "description",
                width: 200,
                align: "left",
            },
        ],
        holidayData: [],
        dayOfWeek: {
            1: "Monday",
            2: "Tuesday",
            3: "Wednesday",
            4: "Thursday",
            5: "Friday",
            6: "Saturday",
            7: "Sunday",
        },
        serverOptions: [],
        symbolOptions: [],
        yearOptions: [],
        MonthOptions: [
            { text: "January", value: 1 },
            { text: "February", value: 2 },
            { text: "March", value: 3 },
            { text: "April", value: 4 },
            { text: "May", value: 5 },
            { text: "June", value: 6 },
            { text: "July", value: 7 },
            { text: "August", value: 8 },
            { text: "September", value: 9 },
            { text: "October", value: 10 },
            { text: "November", value: 11 },
            { text: "December", value: 12 },
        ],
        dayOptions: [
            1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
            20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
        ],
        selectedServer: null,
        selectedSymbol: null,
        selectedYear: null,
        selectedMonth: null,
        selectedDay: null,
        showHistory: false,
        screenWidth: 0,
        menuWidth: 0,
        showMenuButton: false,
        disableToMoveLeft: false,
        disableToMoveRight: true,
        drawer: false,
        group: 0,
        drawerRouteList: [
            {
                path: "/",
                label: "Home",
            },
            {
                path: "/alert/mobile",
                label: "Alert",
            },
        ],
    }),
    computed: {
        ...mapState("SettingsServer", ["serverData"]),
        pkTitle() {
            return this.VUETIFY_BREAKPOINT.mobile ? "PK" : "Position Keeper";
        },
        /**
         * Display username
         * @return  {[type]}  [return description]
         */
        rowNumber() {
            const maxRowNumber = 25;
            if (this.pumpingStatusList.length > maxRowNumber) {
                const colNumber = Math.ceil(
                    this.pumpingStatusList.length / maxRowNumber
                );
                return Math.ceil(this.pumpingStatusList.length / colNumber);
            } else {
                return this.pumpingStatusList.length;
            }
        },
        displayName() {
            return localStorage.getItem("displayName");
        },
        holidayTableData() {
            let returnValue = deepClone(this.holidayData);
            if (this.selectedServer) {
                returnValue = returnValue.filter(
                    (item) => item.server_id === this.selectedServer
                );
            }
            if (this.selectedSymbol) {
                returnValue = returnValue.filter(
                    (item) => item.symbol === this.selectedSymbol
                );
            }
            if (this.selectedYear) {
                returnValue = returnValue.filter(
                    (item) => item.year === this.selectedYear
                );
            }
            if (this.selectedMonth) {
                returnValue = returnValue.filter(
                    (item) => item.month === this.selectedMonth
                );
            }
            if (this.selectedDay) {
                returnValue = returnValue.filter(
                    (item) => item.day === this.selectedDay
                );
            }
            if (!this.showHistory) {
                const today = dayjs(dayjs().format("YYYY-MM-DD")).unix();
                returnValue = returnValue.filter(
                    (item) =>
                        dayjs(
                            item.year + "-" + item.month + "-" + item.day
                        ).unix() >= today
                );
            }
            return returnValue;
        },
    },
    watch: {
        profileUpdate(nv) {
            if (nv.alert && nv.home) {
                this.$router.push({ name: "Signin" });
                this.$store.state.loginStatus = -1;
                logUserOut({ id: 0 }).then(() => {
                    localStorage.clear();
                });
            }
        },
        serverData(nv) {
            this.serverOptions = nv;
        },
    },
    methods: {
        ...mapActions("SettingsServer", ["getServerAction"]),
        isValidServerItem(item) {
            if (!!item) return true;
        },
        /**
         * Logout user account
         * @return  {[type]}  [return description]
         */
        logout() {
            this.logoutLoading = true;
            // this.updateAlertProfile();
            // this.updateHomeProfile();
            this.profileUpdate = {
                home: true,
                alert: true,
            };
        },
        changeDrawTab(key) {
            this.selectedAlertPanel = key;
        },
        darkMode() {
            this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
        },
        updateHomeProfile() {
            putUsers({
                meta_value: "home",
                profile_id:
                    localStorage.getItem("homeId") == "null"
                        ? 0
                        : localStorage.getItem("homeId"),
            }).then(() => {
                this.profileUpdate = {
                    home: true,
                    alert: this.profileUpdate.alert,
                };
            });
        },
        updateAlertProfile() {
            putUsers({
                meta_value: "alert",
                profile_id:
                    localStorage.getItem("alertId") == "null"
                        ? 0
                        : localStorage.getItem("alertId"),
            }).then(() => {
                this.profileUpdate = {
                    home: this.profileUpdate.home,
                    alert: true,
                };
            });
        },
        updateWidth() {
            if (window.innerWidth - 32 - 25 - 16 - 140 - 80 - 32 - 48 > 1250) {
                this.showMenuButton = false;
            } else {
                this.showMenuButton = true;

                this.checkMovability();
            }
        },
        onScreenResize() {
            window.addEventListener("resize", () => {
                this.updateWidth();
            });
        },
        moveDiv(direction) {
            let counter = 0;
            const interval = setInterval(() => {
                this.checkMovability();
                if (counter < 100) {
                    document.getElementById("timeDiv").scrollLeft +=
                        5 * direction;
                    counter += 2;
                } else clearInterval(interval);
            }, 1);
        },
        checkMovability() {
            if (document.getElementById("timeDiv")?.scrollLeft === 0) {
                this.disableToMoveLeft = true;
                this.disableToMoveRight = false;
            } else if (
                document.getElementById("timeDiv")?.scrollLeft +
                    document.getElementById("timeDiv")?.clientWidth >
                1430
            ) {
                this.disableToMoveRight = true;
                this.disableToMoveLeft = false;
            } else {
                this.disableToMoveLeft = false;
                this.disableToMoveRight = false;
            }
        },
        checkPumpingStatus() {
            getPumpingStatus().then((res) => {
                res.data.sort((a, b) => {
                    if (a.server.toLowerCase() < b.server.toLowerCase())
                        return -1;
                    if (a.server.toLowerCase() > b.server.toLowerCase())
                        return 1;
                    return 0;
                });
                this.pumpingStatusList = res.data;
                let trueCount = 0;
                res.data.map((item) => {
                    if (item.alive) {
                        trueCount += 1;
                    }
                });
                if (trueCount === res.data.length) {
                    this.pumpingStatus = "success";
                } else if (trueCount === 0) {
                    this.pumpingStatus = "fail";
                } else {
                    this.pumpingStatus = "partial";
                }
            });
        },
    },
    mounted() {
        if (this.pumpingStatusTimer !== null) {
            clearInterval(this.pumpingStatusTimer);
        }
        this.checkPumpingStatus();
        this.pumpingStatusTimer = setInterval(
            () => this.checkPumpingStatus(),
            30 * 1000
        );
        const accountChild = this.getChild("account");

        if (accountChild.includes("management")) this.loadManagement = 1;
        if (accountChild.includes("permission")) this.loadPermission = 1;
        if (accountChild.includes("config")) this.loadSettings = 1;
        if (accountChild.includes("profile")) this.loadSecurity = 1;

        this.profileUpdate.home = false;
        this.profileUpdate.alert = false;
        this.logoutLoading = false;
        setTimeout(() => {
            this.updateWidth();
            this.onScreenResize();
            document
                .getElementById("timeDiv")
                ?.addEventListener("scroll", () => {
                    this.checkMovability();
                });
        }, 1200);
    },
    destroyed() {
        clearInterval(this.pumpingStatusTimer);
    },
    created() {
        this.getServerAction();
        // if (!this.VUETIFY_BREAKPOINT.mobile) {
        // getHolidayData().then((res) => {
        //     const filtered = res?.data.filter((item) => item.enable === 1);
        //     const symbolOptions = [];
        //     const yearOptions = [];
        //     !!filtered && filtered.forEach((item) => {
        //         item.date =
        //             item.year +
        //             "-" +
        //             item.month +
        //             "-" +
        //             item.day +
        //             ", " +
        //             this.dayOfWeek[
        //                 dayjs(
        //                     item.year + "-" + item.month + "-" + item.day
        //                 ).day()
        //             ];
        //         if (!symbolOptions.includes(item.symbol)) {
        //             symbolOptions.push(item.symbol);
        //         }
        //         if (!yearOptions.includes(item.year)) {
        //             yearOptions.push(item.year);
        //         }
        //         this.symbolOptions = List(symbolOptions);
        //         this.yearOptions = List(yearOptions);
        //     });
        //     this.holidayData = filtered;
        // });
        // }
    },
};
</script>
<style lang="scss" scoped>
div >>> .v-application--wrap {
    min-height: calc(100vh - 40px);
}
div >>> .v-dialog {
    overflow: hidden;
}
div >>> .v-text-field {
    padding-top: 0px;
    margin-top: 0px;
}

div.scrollmenu {
    overflow: overlay;
    white-space: nowrap;
}
div.scrollmenu::-webkit-scrollbar {
    display: none;
}

div.scrollmenu div {
    display: inline-block;
    color: white;
    text-align: center;
    padding: 14px;
    text-decoration: none;
}
.pumpingStatusTooltip.v-tooltip__content.menuable__content__active {
    opacity: 1 !important;
}

::v-deep {
    .v-toolbar__content {
        justify-content: space-between;
    }
}
</style>
