
export function getVolume(query) {
    const mockData = {
        status: 200,
        data: [
            {
                login: "xxxxxx83",
                book: "B",
                volume: 0,
            },
            {
                login: "xxxxxx24",
                book: "S",
                volume: 0,
            },
            {
                login: "xxxxxx27",
                book: "B",
                volume: 0,
            },
            {
                login: "xxxxxx19",
                book: "B",
                volume: 0,
            },
            {
                login: "xxxxxx30",
                book: "B",
                volume: 0,
            },
            {
                login: "xxxxxx25",
                book: "B",
                volume: 0,
            },
            {
                login: "xxxxxx36",
                book: "B",
                volume: 0,
            },
            {
                login: "xxxxxx85",
                book: "B",
                volume: 0,
            },
            {
                login: "xxxxxx96",
                book: "B",
                volume: 0,
            },
            {
                login: "xxxxxx68",
                book: "B",
                volume: 0,
            },
            {
                login: "xxxxxx45",
                book: "Test",
                volume: 0,
            },
        ],
    };
    const returnVal = new Promise((resolve, _) => resolve(mockData));
    return returnVal;
}
