
export function getConfig(query) {
    if (query.type === "users") {
        const mockData = {
            status: 200,
            data: [
                {
                    id: 1,
                    user_name: "xxx.yyy",
                    email: "xxx.yyy@sigmatm.com.au",
                    sms: "+61 41234567",
                    enable: true,
                    created_at: "2022-10-25 03:11:01",
                    updated_at: "2023-08-21 11:07:53",
                },
                {
                    id: 2,
                    user_name: "demo.demo",
                    email: "demo.demo@sigmatm.com.au",
                    sms: "+61 42345678",
                    enable: true,
                    created_at: "2023-08-15 14:29:48",
                    updated_at: "2023-08-21 11:08:09",
                },
                {
                    id: 3,
                    user_name: "abc.efg",
                    email: "abc.efg@bfjfunds.com.au",
                    sms: "+61 43456789",
                    enable: true,
                    created_at: "2023-08-15 14:29:48",
                    updated_at: "2023-08-21 11:08:42",
                },
            ],
        };
        const returnVal = new Promise((resolve, _) => resolve(mockData));
        return returnVal;
    }
    if (query.type === "groups") {
        const mockData = {
            status: 200,
            data: [
                {
                    id: 1,
                    group_name: "IT Test",
                    telegram: "12345",
                    enable: true,
                    created_at: "2022-10-25 03:11:01",
                    updated_at: "2022-10-25 03:11:01",
                },
                {
                    id: 2,
                    group_name: "Pumping Alert Group",
                    telegram: "none",
                    enable: true,
                    created_at: "2023-08-15 14:29:44",
                    updated_at: "2023-08-15 14:29:44",
                },
            ],
        };
        const returnVal = new Promise((resolve, _) => resolve(mockData));
        return returnVal;
    }
    if (query.type === "rules") {
        const mockData = {
            status: 200,
            data: [
                {
                    id: 1,
                    rule_name: "Test",
                    enable: true,
                    created_at: "2022-10-25 03:11:02",
                    updated_at: "2022-10-25 03:11:02",
                },
                {
                    id: 2,
                    rule_name: "Pumping Alert Rule",
                    enable: true,
                    created_at: "2023-08-15 14:29:47",
                    updated_at: "2023-08-15 14:29:47",
                },
            ],
        };
        const returnVal = new Promise((resolve, _) => resolve(mockData));
        return returnVal;
    }
    if (query.type === "user_groups") {
        const mockData = {
            status: 200,
            data: [
                {
                    id: 1,
                    group_id: 1,
                    user_id: 1,
                    created_at: "2022-10-25 03:11:02",
                    updated_at: "2022-10-25 03:11:02",
                },
                {
                    id: 2,
                    group_id: 2,
                    user_id: 2,
                    created_at: "2023-08-15 14:29:50",
                    updated_at: "2023-08-15 14:29:50",
                },
                {
                    id: 3,
                    group_id: 2,
                    user_id: 3,
                    created_at: "2023-08-15 14:30:33",
                    updated_at: "2023-08-15 14:30:33",
                },
            ],
        };
        const returnVal = new Promise((resolve, _) => resolve(mockData));
        return returnVal;
    }
    if (query.type === "group_rules") {
        const mockData = {
            status: 200,
            data: [
                {
                    id: 1,
                    group_id: 1,
                    rule_id: 1,
                    created_at: "2022-10-25 03:11:02",
                    updated_at: "2022-10-25 03:11:02",
                },
            ],
        };
        const returnVal = new Promise((resolve, _) => resolve(mockData));
        return returnVal;
    }
}

export function postConfig(query) {
    const mockData = {
        status: 200,
        data: { 'msg': 'Created successfully' }
    };
    const returnVal = new Promise((resolve, _) => resolve(mockData));
    return returnVal;
}
export function putConfig(query) {
    const mockData = {
        status: 200,
        data: { 'msg': 'Updated successfully' }
    };
    const returnVal = new Promise((resolve, _) => resolve(mockData));
    return returnVal;
}
export function deleteConfig(query) {
    const mockData = {
        status: 200,
        data: { 'msg': 'Deleted successfully' }
    };
    const returnVal = new Promise((resolve, _) => resolve(mockData));
    return returnVal;
}
