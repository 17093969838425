import common from "@assets/js/common";

const state = {
    loading: false,
    monitorData: [
    {
        "user": "Demo:MT4-Demo:xxxxx22:Pham",
        "symbol": "XAUUSD",
        "books": [
            "B"
        ],
        "exposureVolume": -8.479999999999999,
        "exposureVolumeThreshold": 5,
        "broker": "Demo",
        "timestamp": 1722838397,
        "messageType": "MSG_TYPE_MONITOR_LARGE_EXPOSURE_VOLUME",
        "lifeCycle": "",
        "force": false,
        "priority": 1,
        "login": "MT4-Demo:xxxxx22",
        "name": "Pham",
        "trigger_time": "2024-08-05 16:13:17"
    },
    {
        "user": "Demo:MT4-Demo:xxxxx37:Vn",
        "symbol": "USDJPY",
        "books": [
            "B"
        ],
        "exposureVolume": 6,
        "exposureVolumeThreshold": 5,
        "broker": "Demo",
        "timestamp": 1722838345,
        "messageType": "MSG_TYPE_MONITOR_LARGE_EXPOSURE_VOLUME",
        "lifeCycle": "",
        "force": false,
        "priority": 1,
        "login": "MT4-Demo:xxxxx37",
        "name": "Vn",
        "trigger_time": "2024-08-05 16:12:25"
    },
    {
        "user": "Demo:MT4-Demo:xxxxx60:Weflex",
        "symbol": "XAUUSD",
        "books": [
            "Test"
        ],
        "exposureVolume": -20.66,
        "exposureVolumeThreshold": 5,
        "broker": "Demo",
        "timestamp": 1722838346,
        "messageType": "MSG_TYPE_MONITOR_LARGE_EXPOSURE_VOLUME",
        "lifeCycle": "",
        "force": false,
        "priority": 1,
        "login": "MT4-Demo:xxxxx60",
        "name": "Weflex",
        "trigger_time": "2024-08-05 16:12:26"
    }
],
    monitorHeader: [
        { text: "Login", value: "login", align: "left" },
        { text: "Name", value: "name", align: "left" },
        { text: "Books", value: "books", align: "left" },
        { text: "Symbol", value: "symbol", align: "left" },
        { text: "Volume", value: "exposureVolume", align: "left" },
    ],
    csvHeader: {
        Login: "login",
        Books: "books",
        Symbol: "symbol",
        Volume: "exposureVolume",
    },
};

const actions = {
    /**
     * Process alert data
     * @param   {[type]}  commit  [commit description]
     * @param   {[type]}  data    [data description]
     *
     * @return  {[type]}          [return description]
     */
    processLargeExposureVolume({ commit }, data) {
        let result = [];
        for (let item in data) {
            let temp = data[item];
            temp["login"] = item.split(":")[1]+":"+item.split(":")[2];
            temp["symbol"] = item.split(":").slice(-1)[0];
            temp['name'] = item.split(":").slice(3, -1).join(" ");
            temp["trigger_time"] = common.convertUnixtimeToDatetimeString(
                temp.timestamp
            );
            if (temp["books"].length == 2 && temp["books"].includes("STOCKS")) {
                temp["books"] = temp["books"].filter((i) => i !== "STOCKS");
            } else if (temp["books"].length >= 3) {
                temp["books"] = temp["books"].filter(
                    (i) =>
                        i !== "STOCKS" && i !== "EquityAU" && i !== "EquityUS"
                );
            }
            result.push(temp);
        }
        // console.log(result)
        commit("UPDATE_MONITOR_DATA", result);
    },
};

const mutations = {
    /**
     * Update monitor data
     * @param   {[type]}  state  [state description]
     * @param   {[type]}  data   [data description]
     * @return  {[type]}         [return description]
     */
    UPDATE_MONITOR_DATA(state, data) {
        state.monitorData = data;
    },
    /**
     * Update loading
     * @param   {[type]}  state  [state description]
     * @param   {[type]}  data   [data description]
     * @return  {[type]}         [return description]
     */
    UPDATE_LOADING(state, data) {
        state.loading = data;
    },
    UPDATE_MONITOR_HEADER(state, data) {
        state.monitorHeader = data;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
