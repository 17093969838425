
export function getSecurity(query) {
    const mockData = {
        status: 200,
        data: [
            { server_id: 1, name: "Forex Major", index: 1 },
            { server_id: 1, name: "Forex Minor", index: 2 },
            { server_id: 1, name: "Removed", index: 3 },
            { server_id: 1, name: "Spot Metals", index: 4 },
            { server_id: 1, name: "Indices", index: 5 },
            { server_id: 1, name: "Forex\\STD/L", index: 6 },
            { server_id: 1, name: "Metal\\STD/L", index: 7 },
            { server_id: 2, name: "Futures\\Oil\\ECN/H", index: 8 },
            { server_id: 2, name: "Oil\\ECN/H", index: 9 },
            { server_id: 2, name: "Oil\\ECN/L", index: 10 },
            { server_id: 2, name: "Oil\\STD/H", index: 11 },
            { server_id: 2, name: "Futures\\Oil\\STD/H", index: 12 },
          ]
    };
    const returnVal = new Promise((resolve, _) => resolve(mockData));
    return returnVal;
}