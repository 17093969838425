
export function getServer(query) {
    const mockData = {
        status: 200,
        data: [
            {
                "id": 1,
                "server_name": "MT5-Demo",
                "description": "MT5 Demo",
                "adapter_id": "MT4",
                "host": "xxx.xxx.xx.xx",
                "port": 443,
                "broker_id": 1,
                "broker_name": "Demo"
            },
            {
                "id": 2,
                "server_name": "MT4-Demo",
                "description": "MT4 Demo",
                "adapter_id": "MT4-Demo",
                "host": "xxx.xxx.xx.xx",
                "port": 443,
                "broker_id": 1,
                "broker_name": "Demo"
            }
          ]
    };
    const returnVal = new Promise((resolve, _) => resolve(mockData));
    return returnVal;
}

export function postServer(query) {
    const mockData = {
        status: 200,
        data: { 'msg': 'Create broker server successfully' }
    };
    const returnVal = new Promise((resolve, _) => resolve(mockData));
    return returnVal;
}
export function putServer(query) {
    const mockData = {
        status: 200,
        data: { 'msg': 'Update broker server successfully' }
    };
    const returnVal = new Promise((resolve, _) => resolve(mockData));
    return returnVal;
}
export function deleteServer(query) {
    const mockData = {
        status: 200,
        data: { 'msg': 'Delete broker server successfully' }
    };
    const returnVal = new Promise((resolve, _) => resolve(mockData));
    return returnVal;
}
