
export function getCopyTradeData(query) {
    const mockData = {
        status: 200,
        data: [
            {
                "target": "xxxxx40",
                "source": "xxxxx39",
                "target_trades": [],
                "source_trades": []
            },
            {
                "target": "xxxxx18",
                "source": "xxxxx17",
                "target_trades": [],
                "source_trades": []
            },
            {
                "target": "xxxxx91",
                "source": "xxxxx90",
                "target_trades": [],
                "source_trades": []
            }
        ]
    };
    const returnVal = new Promise((resolve, _) => resolve(mockData));
    return returnVal;
}