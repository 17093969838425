const state = {
    autoRefreshTimeOffset: 1,
    loading: false,
    alertData: [
        {
            "user": "Demo:MT4-Demo:xxxxx13:",
            "symbol": "XAUUSD",
            "books": "Test",
            "lockingVolume": 1.7500000000000007,
            "lockingVolumeThreshold": 1,
            "broker": "Demo",
            "timestamp": 1722842743,
            "messageType": "MSG_TYPE_MONITOR_LOCKING_POSITION",
            "lifeCycle": "",
            "force": false,
            "priority": 1,
            "name": "Dao"
        },
        {
            "user": "Demo:MT4-Demo:xxxxx65:",
            "symbol": "GBPUSD",
            "books": "Test",
            "lockingVolume": 84.65,
            "lockingVolumeThreshold": 1,
            "broker": "Demo",
            "timestamp": 1722842743,
            "messageType": "MSG_TYPE_MONITOR_LOCKING_POSITION",
            "lifeCycle": "",
            "force": false,
            "priority": 1,
            "name": "Sim"
        },
        {
            "user": "Demo:MT4-Demo:xxxxx88:",
            "symbol": "USTEC",
            "books": "Test",
            "lockingVolume": 1,
            "lockingVolumeThreshold": 1,
            "broker": "Demo",
            "timestamp": 1722842743,
            "messageType": "MSG_TYPE_MONITOR_LOCKING_POSITION",
            "lifeCycle": "",
            "force": false,
            "priority": 1,
            "name": "Wen"
        },
        {
            "user": "Demo:MT4-Demo:xxxxx90:",
            "symbol": "EURUSD",
            "books": "B",
            "lockingVolume": 4.64,
            "lockingVolumeThreshold": 1,
            "broker": "Demo",
            "timestamp": 1722842743,
            "messageType": "MSG_TYPE_MONITOR_LOCKING_POSITION",
            "lifeCycle": "",
            "force": false,
            "priority": 1,
            "name": "0xc"
        },
        {
            "user": "Demo:MT4-Demo:xxxxx04:",
            "symbol": "XAUUSD",
            "books": "Test",
            "lockingVolume": 4,
            "lockingVolumeThreshold": 1,
            "broker": "Demo",
            "timestamp": 1722842743,
            "messageType": "MSG_TYPE_MONITOR_LOCKING_POSITION",
            "lifeCycle": "",
            "force": false,
            "priority": 1,
            "name": "Ngu"
        },
        {
            "user": "Demo:MT4-Demo:xxxxx28:",
            "symbol": "XAUUSD",
            "books": "B",
            "lockingVolume": 1,
            "lockingVolumeThreshold": 1,
            "broker": "Demo",
            "timestamp": 1722842743,
            "messageType": "MSG_TYPE_MONITOR_LOCKING_POSITION",
            "lifeCycle": "",
            "force": false,
            "priority": 1,
            "name": "0xb"
        },
        {
            "user": "Demo:MT4-Demo:xxxxx56:",
            "symbol": "XAUUSD",
            "books": "Test",
            "lockingVolume": 21.439999999999998,
            "lockingVolumeThreshold": 1,
            "broker": "Demo",
            "timestamp": 1722842743,
            "messageType": "MSG_TYPE_MONITOR_LOCKING_POSITION",
            "lifeCycle": "",
            "force": false,
            "priority": 1,
            "name": "ngu"
        },
        {
            "user": "Demo:MT4-Demo:xxxxx78:",
            "symbol": "XAUUSD",
            "books": "B",
            "lockingVolume": 1.2,
            "lockingVolumeThreshold": 1,
            "broker": "Demo",
            "timestamp": 1722842743,
            "messageType": "MSG_TYPE_MONITOR_LOCKING_POSITION",
            "lifeCycle": "",
            "force": false,
            "priority": 1,
            "name": "0xd"
        },
        {
            "user": "Demo:MT4-Demo:xxxxx60:",
            "symbol": "XAUUSD",
            "books": "Test",
            "lockingVolume": 9.98,
            "lockingVolumeThreshold": 1,
            "broker": "Demo",
            "timestamp": 1722842743,
            "messageType": "MSG_TYPE_MONITOR_LOCKING_POSITION",
            "lifeCycle": "",
            "force": false,
            "priority": 1,
            "name": "Wef"
        },
        {
            "user": "Demo:MT4-Demo:xxxxx91:",
            "symbol": "EURUSD",
            "books": "B",
            "lockingVolume": 4.64,
            "lockingVolumeThreshold": 1,
            "broker": "Demo",
            "timestamp": 1722842743,
            "messageType": "MSG_TYPE_MONITOR_LOCKING_POSITION",
            "lifeCycle": "",
            "force": false,
            "priority": 1,
            "name": "0xc"
        },
        {
            "user": "Demo:MT4-Demo:xxxxx94:",
            "symbol": "USOUSD",
            "books": "Test",
            "lockingVolume": 10.04,
            "lockingVolumeThreshold": 1,
            "broker": "Demo",
            "timestamp": 1722842743,
            "messageType": "MSG_TYPE_MONITOR_LOCKING_POSITION",
            "lifeCycle": "",
            "force": false,
            "priority": 1,
            "name": "Wef"
        },
        {
            "user": "Demo:MT4-Demo:xxxxx87:",
            "symbol": "EURUSD",
            "books": "Test",
            "lockingVolume": 5.0200000000000005,
            "lockingVolumeThreshold": 1,
            "broker": "Demo",
            "timestamp": 1722842743,
            "messageType": "MSG_TYPE_MONITOR_LOCKING_POSITION",
            "lifeCycle": "",
            "force": false,
            "priority": 1,
            "name": "wef"
        },
        {
            "user": "Demo:MT4-Demo:xxxxx81:",
            "symbol": "XAUUSD",
            "books": "Test",
            "lockingVolume": 4.61,
            "lockingVolumeThreshold": 1,
            "broker": "Demo",
            "timestamp": 1722842743,
            "messageType": "MSG_TYPE_MONITOR_LOCKING_POSITION",
            "lifeCycle": "",
            "force": false,
            "priority": 1,
            "name": "Qau"
        },
        {
            "user": "Demo:MT4-Demo:xxxxx82:",
            "symbol": "XAUUSD",
            "books": "Test",
            "lockingVolume": 2.39,
            "lockingVolumeThreshold": 1,
            "broker": "Demo",
            "timestamp": 1722842743,
            "messageType": "MSG_TYPE_MONITOR_LOCKING_POSITION",
            "lifeCycle": "",
            "force": false,
            "priority": 1,
            "name": "Blu"
        }
    ],
    alertHeader: [
        { text: "Login", value: "user", align: "left" },
        { text: "Name", value: "name", align: "left" },
        { text: "Symbol", value: "symbol", align: "left" },
        { text: "Books", value: "books", align: "left" },
        {
            text: "Locking Pos",
            value: "lockingVolume",
            align: "right",
        },
    ],
    csvHeader: {
        Login: "user",
        Symbol: "symbol",
        Books: "books",
        "Locking Position": "lockingVolume",
        "Locking Position Threshold": "lockingVolumeThreshold",
    },
    selectedDate: null,
};

const actions = {
    processLockingPosition({ commit }, data) {
        let result = [];
        for (let item in data) {
            let temp = data[item];
            temp.books = temp.books.join(",");
            temp.name = item.split(":").slice(3, -1).join(" ");

            result.push(temp);
        }
        console.log(result)
        commit("UPDATE_ALERT_DATA", result);
    },
};

const mutations = {
    /**
     * Update alert data
     * @param   {[type]}  state  [state description]
     * @param   {[type]}  data   [data description]
     * @return  {[type]}         [return description]
     */
    UPDATE_ALERT_DATA(state, data) {
        state.alertData = data;
    },
    /**
     * Update loading
     * @param   {[type]}  state  [state description]
     * @param   {[type]}  data   [data description]
     * @return  {[type]}         [return description]
     */
    UPDATE_LOADING(state, data) {
        state.loading = data;
    },
    UPDATE_ALERT_HEADER(state, data) {
        state.alertHeader = data;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
