export function login(query) {
    const mockData = {
        status: 200,
        data: {
            access_token:
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmcmVzaCI6ZmFsc2UsImlhdCI6MTcyMjg0MTY0MCwianRpIjoiOWQxNzU1NGYtMDQ5MS00NzYzLWExYTgtMTRkZDg2NGM5NDM5IiwidHlwZSI6ImFjY2VzcyIsInN1YiI6InRlc3QudGVzdEBzaWdtYXRtLmNvbS5hdT8yNT9GUEciLCJuYmYiOjE3MjI4NDE2NDAsImV4cCI6MTcyMzM4MTY0MH0.SeCwcAo9ktEV3dHkAOejFlb202EwWQjcXQHynJqS3Tc",
            refresh_token:
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmcmVzaCI6ZmFsc2UsImlhdCI6MTcyMjg0MTY0MCwianRpIjoiODVhZjVjYmQtMDlmYy00NDYzLWIwZjctNTAxYmQyNDc0ZDMxIiwidHlwZSI6InJlZnJlc2giLCJzdWIiOiJ0ZXN0LnRlc3RAc2lnbWF0bS5jb20uYXUiLCJuYmYiOjE3MjI4NDE2NDAsImV4cCI6MTcyNTQzMzY0MH0.BhIXV3XXed0znGkH74RRbbSAeEBpK0pMLM5-KnZG6bg",
            permission: {
                broker: [],
                frontPermission: {
                    book: {
                        child: [],
                        function: [
                            "homeLoginDetailSummary",
                            "extraSymbolDetails",
                            "dailySummaryIcon",
                            "mtdVolumeColumn",
                            "mtdPnlColumn",
                            "loginSummaryInfo",
                            "loginSummaryStatistic",
                            "loginSummaryTrades",
                            "symbolSnapshot",
                            "loginSnapshot",
                        ],
                    },
                    lp: {
                        child: [],
                    },
                    alert: {
                        child: ["overview"],
                    },
                    validation: {
                        child: ["net"],
                    },
                    settings: {
                        child: [
                            "alertConfig",
                            "brokerConfig",
                            "serverConfig",
                            "bookConfig",
                            "routingConfig",
                            "copyTradeConfig",
                            "lpConfig",
                            "notificationConfig",
                        ],
                        function: ["alertConfigEdit"],
                    },
                    system: {
                        child: [
                            "group",
                            "bookGroupRules",
                            "userPerformance",
                            "snapshot",
                            "symbolRule",
                            "tick",
                            "ticklive",
                            "alertConfiguration",
                            "notification",
                        ],
                    },
                    ibMonitor: {
                        child: ["index"],
                        function: [
                            "ibLoginDetailSummary",
                            "ibExtraSymbolDetails",
                            "ibLoginSummaryInfo",
                            "ibLoginSummaryStatistic",
                            "ibLoginSummaryTrades",
                        ],
                    },
                    account: {
                        child: [],
                    },
                    risk: {
                        child: ["prometheus", "notification"],
                        function: [],
                    },

                    bookSelection: ["B", "TEST"],
                },
                allowJsonEditor: 1,
                whitelist: ["*"],
                blocklist: [],
            },
            username: "demo.demo",
            home_page_profile_id: 0,
            alert_page_profile_id: 28,
            tutorial_presented: "",
        },
    };
    const returnVal = new Promise((resolve, _) => resolve(mockData));
    return returnVal;
}

export function logUserOut(query) {
    const mockData = {
        status: 200,
        data: { msg: "Log user out" },
    };
    const returnVal = new Promise((resolve, _) => resolve(mockData));
    return returnVal;
}

export function putAuthUser(query) {
    const mockData = {
        status: 200,
        data: {
            msg: "Tutorial page updated",
        },
    };
    const returnVal = new Promise((resolve, _) => resolve(mockData));
    return returnVal;
}
