export function getBooksPk(query) {
    const mockData = {
        status: 200,
        data: [
            {
                "book_id": 4,
                "parent_book_id": 1,
                "book_name": "S"
            },
            {
                "book_id": 5,
                "parent_book_id": 1,
                "book_name": "B",
                "children": [
                    {
                        "book_id": 7,
                        "parent_book_id": 5,
                        "book_name": "B2"
                    }
                ]
            },
            {
                "book_id": 6,
                "parent_book_id": 1,
                "book_name": "Test"
            },
        ]
    };
    const returnVal = new Promise((resolve, _) => resolve(mockData));
    return returnVal;
}

export function getBooks() {
    const mockData = {
        status: 200,
        data: [
            {
                "book_id": 1,
                "broker_id": 1,
                "parent_book_id": 1,
                "book_name": "ALL",
                "accessible": false,
                "created_at": "2022-04-28 01:14:55",
                "updated_at": "2022-04-28 01:14:55"
            },
            {
                "book_id": 4,
                "broker_id": 1,
                "parent_book_id": 1,
                "book_name": "S",
                "accessible": true,
                "created_at": "2022-04-28 01:14:55",
                "updated_at": "2022-04-28 01:14:55"
            },
            {
                "book_id": 5,
                "broker_id": 1,
                "parent_book_id": 1,
                "book_name": "B",
                "accessible": true,
                "created_at": "2022-04-28 01:14:55",
                "updated_at": "2022-04-28 01:14:55"
            },
            {
                "book_id": 6,
                "broker_id": 1,
                "parent_book_id": 1,
                "book_name": "Test",
                "accessible": true,
                "created_at": "2022-04-28 01:14:55",
                "updated_at": "2023-08-04 16:46:32"
            },
            {
                "book_id": 7,
                "broker_id": 1,
                "parent_book_id": 5,
                "book_name": "B2",
                "accessible": true,
                "created_at": "2022-04-28 01:14:55",
                "updated_at": "2023-08-04 16:46:32"
            }
          ]
    };
    const returnVal = new Promise((resolve, _) => resolve(mockData));
    return returnVal;
}
export function postBooks(query) {
   const mockData = {
        status: 200,
        data: { 'msg': 'Create Book successfully' }
    };
    const returnVal = new Promise((resolve, _) => resolve(mockData));
    return returnVal;
}
export function putBooks(query) {
   const mockData = {
        status: 200,
        data: { 'msg': 'Update Book successfully' }
    };
    const returnVal = new Promise((resolve, _) => resolve(mockData));
    return returnVal;
}
export function deleteBooks(query) {
    const mockData = {
        status: 200,
        data: { 'msg': 'Delete Book successfully' }
    };
    const returnVal = new Promise((resolve, _) => resolve(mockData));
    return returnVal;
}
