
export function getLPPosition(query) {
    const mockData = {
        status: 200,
        data: [
            {
                "LP": "lp-1",
                "Symbol": "USDJPY",
                "Margin Rate": "13.15",
                "Position": -900000,
                "Total VWAP": 145.010,
                "Margin": 90000,
                "Unrealized P&L": 779.72,
                "updated_at": "2023-08-16 05:31:26"
            },
            {
                "LP": "lp-1",
                "Symbol": "XAUUSD",
                "Margin Rate": "11.46",
                "Position": 321,
                "Total VWAP": 1914.8,
                "Margin": 60990.45,
                "Unrealized P&L": -3435,
                "updated_at": "2023-08-16 05:31:26"
            },
            {
                "LP": "lp-1",
                "Symbol": "lp-1_N-AQZIY",
                "Margin Rate": "8.15",
                "Position": -100,
                "Total VWAP": 32030.5,
                "Margin": 1213.21,
                "Unrealized P&L": -4944,
                "updated_at": "2023-08-16 05:31:26"
            },
            {
                "LP": "lp-1",
                "Symbol": "BTCUSD",
                "Margin Rate": "1.25",
                "Position": 2.54,
                "Total VWAP": 26155.45,
                "Margin": 2615.54,
                "Unrealized P&L": -31205.78,
                "updated_at": "2023-08-16 05:31:26"
            },
            {
                "LP": "lp-2",
                "Symbol": "WTIUSD",
                "Margin Rate": "4.68",
                "Position": -1000,
                "Total VWAP": 81.05,
                "Margin": 8105.75,
                "Unrealized P&L": 413.48,
                "updated_at": "2023-08-24 09:32:00"
            },
            {
                "LP": "lp-2",
                "Symbol": "EURAUD",
                "Margin Rate": "10.48",
                "Position": -100000,
                "Total VWAP": 1.68205,
                "Margin": 44169.15,
                "Unrealized P&L": 0,
                "updated_at": "2023-08-24 09:32:00"
            },
            {
                "LP": "lp-2",
                "Symbol": "GBPCAD",
                "Margin Rate": "4.19",
                "Position": 100000,
                "Total VWAP": 1.71085,
                "Margin": 7168.18,
                "Unrealized P&L": 7859.48,
                "updated_at": "2023-08-24 09:32:00"
            },
            {
                "LP": "lp-2",
                "Symbol": "EURUSD",
                "Margin Rate": "1.95",
                "Position": 9000,
                "Total VWAP": 1.08815,
                "Margin": 413.97,
                "Unrealized P&L": 19.98,
                "updated_at": "2023-08-24 09:32:00"
            }
        ]
    };
    const returnVal = new Promise((resolve, _) => resolve(mockData));
    return returnVal;
}
