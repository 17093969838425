
export function getSymbol(query) {
    const mockData = {
        status: 200,
        data: [
            {
                "name": "AUDSGD.i",
                "server_id": 1
            },
            {
                "name": "USDHUF.rg",
                "server_id": 1
            },
            {
                "name": "CHFJPY.i",
                "server_id": 1
            },
            {
                "name": "GBPCAD.i",
                "server_id": 1
            },
            {
                "name": "GBPUSD.rg",
                "server_id": 1
            },
            {
                "name": "EURJPY.i",
                "server_id": 1
            },
            {
                "name": "USDTRY.rg",
                "server_id": 1
            },
            {
                "name": "EURCHF.rg",
                "server_id": 1
            },
            {
                "name": "EURHUF.i",
                "server_id": 1
            },
            {
                "name": "GBPCAD.rg",
                "server_id": 1
            },
            {
                "name": "EURPLN.i",
                "server_id": 1
            },
            {
                "name": "GBPNZD.i",
                "server_id": 1
            },
            {
                "name": "CHFJPY.rg",
                "server_id": 1
            },
            {
                "name": "EURGBP.rg",
                "server_id": 1
            },
            {
                "name": "EURCHF.i",
                "server_id": 1
            },
            {
                "name": "GBPAUD.i",
                "server_id": 1
            },
            {
                "name": "EURAUD.rg",
                "server_id": 1
            },
            {
                "name": "EURCAD.rg",
                "server_id": 1
            },
            {
                "name": "EURTRY.i",
                "server_id": 1
            },
            {
                "name": "EURJPY.rg",
                "server_id": 1
            },
            {
                "name": "EURUSD.i",
                "server_id": 1
            },
            {
                "name": "EURDKK",
                "server_id": 1
            },
            {
                "name": "NZDCAD.i",
                "server_id": 1
            },
            {
                "name": "NZDJPY.i",
                "server_id": 1
            },
            {
                "name": "GER30",
                "server_id": 1
            },
            {
                "name": "USDNOK.i",
                "server_id": 1
            },
            {
                "name": "NZDCHF.i",
                "server_id": 1
            },
            {
                "name": "GBPNZD.rg",
                "server_id": 1
            },
            {
                "name": "USDCHF.i",
                "server_id": 1
            },
            {
                "name": "NZDUSD.i",
                "server_id": 1
            },
            {
                "name": "GBPUSD.i",
                "server_id": 1
            },
            {
                "name": "GBPAUD.rg",
                "server_id": 1
            },
            {
                "name": "JPN225",
                "server_id": 1
            },
            {
                "name": "USDX",
                "server_id": 1
            },
            {
                "name": "BTCUSD.f",
                "server_id": 1
            },
            {
                "name": "EURGBP.i",
                "server_id": 1
            },
            {
                "name": "GBPCHF.rg",
                "server_id": 1
            },
            {
                "name": "USDHUF.i",
                "server_id": 1
            },
            {
                "name": "GBPCHF.i",
                "server_id": 1
            },
            {
                "name": "NZDCHF.rg",
                "server_id": 1
            },
            {
                "name": "EURDKK.c",
                "server_id": 1
            },
            {
                "name": "NZDJPY.rg",
                "server_id": 1
            },
            {
                "name": "AUDJPY.i",
                "server_id": 1
            },
            {
                "name": "GBPJPY.i",
                "server_id": 1
            },
            {
                "name": "EURDKK.rg",
                "server_id": 1
            },
            {
                "name": "USDDKK.rg",
                "server_id": 1
            },
            {
                "name": "USDNOK.rg",
                "server_id": 1
            },
            {
                "name": "EURCAD.i",
                "server_id": 1
            },
            {
                "name": "EURNOK.rg",
                "server_id": 1
            },
            {
                "name": "USDSGD.rg",
                "server_id": 1
            },
            {
                "name": "EURDKK.co",
                "server_id": 1
            },
            {
                "name": "AUDUSD.i",
                "server_id": 1
            },
            {
                "name": "USDJPY.i",
                "server_id": 1
            },
            {
                "name": "USDSGD.i",
                "server_id": 1
            },
            {
                "name": "UKOUSD",
                "server_id": 1
            },
            {
                "name": "USDHKD.rg",
                "server_id": 1
            },
            {
                "name": "EURHUF.rg",
                "server_id": 1
            },
            {
                "name": "EURNOK.i",
                "server_id": 1
            },
            {
                "name": "USDTRY.i",
                "server_id": 1
            },
            {
                "name": "FRA40",
                "server_id": 1
            },
            {
                "name": "USDOLLAR.f",
                "server_id": 1
            },
            {
                "name": "XRPUSD.f",
                "server_id": 1
            },
            {
                "name": "EURUSD.rg",
                "server_id": 1
            },
            {
                "name": "USDZAR.rg",
                "server_id": 1
            },
            {
                "name": "BTGUSD.f",
                "server_id": 1
            },
            {
                "name": "USDHKD.i",
                "server_id": 1
            },
            {
                "name": "UK100",
                "server_id": 1
            },
            {
                "name": "SGDJPY.i",
                "server_id": 1
            },
            {
                "name": "US30",
                "server_id": 1
            },
            {
                "name": "EURAUD.i",
                "server_id": 1
            },
            {
                "name": "EURDKK.i",
                "server_id": 1
            },
            {
                "name": "XAGUSD.rg",
                "server_id": 1
            },
            {
                "name": "EURTRY.rg",
                "server_id": 1
            },
            {
                "name": "HK50",
                "server_id": 1
            },
            {
                "name": "AUDNZD.i",
                "server_id": 1
            },
            {
                "name": "USDSEK.rg",
                "server_id": 1
            },
            {
                "name": "CADJPY.i",
                "server_id": 1
            },
            {
                "name": "USDOLLAR",
                "server_id": 1
            },
            {
                "name": "LTCUSD.f",
                "server_id": 1
            },
            {
                "name": "XAGUSD",
                "server_id": 1
            },
            {
                "name": "US2000",
                "server_id": 1
            },
            {
                "name": "JP225",
                "server_id": 1
            },
            {
                "name": "AUDCAD.rg",
                "server_id": 1
            },
            {
                "name": "ETHUSD.f",
                "server_id": 1
            },
            {
                "name": "XAUUSD",
                "server_id": 1
            },
            {
                "name": "XAUUSD.rg",
                "server_id": 1
            },
            {
                "name": "silver",
                "server_id": 1
            },
            {
                "name": "USDOLLAR.co",
                "server_id": 1
            },
            {
                "name": "ESP35",
                "server_id": 1
            },
            {
                "name": "STOXX50",
                "server_id": 1
            },
            {
                "name": "USDHKD.co",
                "server_id": 1
            },
            {
                "name": "gold",
                "server_id": 1
            },
            {
                "name": "AUDSGD.rg",
                "server_id": 1
            },
            {
                "name": "SPX500",
                "server_id": 1
            },
            {
                "name": "DAX30",
                "server_id": 1
            },
            {
                "name": "USDOLLAR.rg",
                "server_id": 1
            },
            {
                "name": "AUDJPY.rg",
                "server_id": 1
            },
            {
                "name": "CADJPY.rg",
                "server_id": 1
            },
            {
                "name": "USDOLLAR.i",
                "server_id": 1
            },
            {
                "name": "NDX100",
                "server_id": 1
            },
            {
                "name": "WS30",
                "server_id": 1
            },
            {
                "name": "CAC40",
                "server_id": 1
            },
            {
                "name": "GBPJPY.rg",
                "server_id": 1
            },
            {
                "name": "USDCHF.rg",
                "server_id": 1
            },
            {
                "name": "US500",
                "server_id": 1
            },
            {
                "name": "USDPLN.i",
                "server_id": 1
            },
            {
                "name": "USDZAR.i",
                "server_id": 1
            },
            {
                "name": "AUDNZD.rg",
                "server_id": 1
            },
            {
                "name": "AUDUSD.rg",
                "server_id": 1
            },
            {
                "name": "USDMXN.i",
                "server_id": 1
            },
            {
                "name": "USDSEK.i",
                "server_id": 1
            },
            {
                "name": "EURNZD.i",
                "server_id": 1
            },
            {
                "name": "USDHKD.f",
                "server_id": 1
            },
            {
                "name": "NZDUSD.rg",
                "server_id": 1
            },
            {
                "name": "EUSTX50",
                "server_id": 1
            },
            {
                "name": "NZDCAD.rg",
                "server_id": 1
            },
            {
                "name": "USDJPY.rg",
                "server_id": 1
            },
            {
                "name": "SGDJPY.rg",
                "server_id": 1
            },
            {
                "name": "USDCAD.rg",
                "server_id": 1
            },
            {
                "name": "USDDKK.i",
                "server_id": 1
            },
            {
                "name": "EURDKK.t",
                "server_id": 1
            },
            {
                "name": "FTSE100",
                "server_id": 1
            },
            {
                "name": "AUDCHF.rg",
                "server_id": 1
            },
            {
                "name": "AUS200",
                "server_id": 1
            },
            {
                "name": "AUDCHF.i",
                "server_id": 1
            },
            {
                "name": "AUDCAD.i",
                "server_id": 1
            },
            {
                "name": "CADCHF.rg",
                "server_id": 1
            },
            {
                "name": "JAPAN225",
                "server_id": 1
            },
            {
                "name": "USDMXN.rg",
                "server_id": 1
            },
            {
                "name": "EURDKK.f",
                "server_id": 1
            },
            {
                "name": "USDPLN.rg",
                "server_id": 1
            },
            {
                "name": "CADCHF.i",
                "server_id": 1
            },
            {
                "name": "EURNZD.rg",
                "server_id": 1
            },
            {
                "name": "EURPLN.rg",
                "server_id": 1
            },
            {
                "name": "ASX200",
                "server_id": 1
            },
            {
                "name": "BTGUSD",
                "server_id": 1
            },
            {
                "name": "BCHUSD.f",
                "server_id": 1
            },
            {
                "name": "USDCAD.i",
                "server_id": 1
            },
            {
                "name": "CHINA50",
                "server_id": 1
            }
        ]
    };
    const returnVal = new Promise((resolve, _) => resolve(mockData));
    return returnVal;
}

export function getSymbolDP() {
    const mockData = {
        status: 200,
        data: [
            {
                name: "AUDCAD",
                digits: 5,
            },
            {
                name: "EURCAD",
                digits: 5,
            },
            {
                name: "EURGBP",
                digits: 5,
            },
            {
                name: "EURUSD",
                digits: 5,
            },
            {
                name: "ETHUSD (Ethereum)",
                digits: 3,
            },
            {
                name: "GBPHKD",
                digits: 5,
            },
            {
                name: "F40",
                digits: 2,
            },
            {
                name: "JP225",
                digits: 2,
            },
            {
                name: "USDCHF",
                digits: 5,
            },
            {
                name: "JP225",
                digits: 2,
            },
            {
                name: "XAUUSD (Gold)",
                digits: 2,
            },
            {
                name: "GBPCHF",
                digits: 5,
            },
            {
                name: "US Oil (WTI)",
                digits: 3,
            },
            {
                name: "BCHJPY",
                digits: 1,
            },
            {
                name: "EURNOK",
                digits: 5,
            },
            {
                name: "LNKUSD",
                digits: 5,
            },
            {
                name: "USDCAD",
                digits: 5,
            },
            {
                name: "AUDCAD",
                digits: 5,
            },
            {
                name: "CADCHF",
                digits: 5,
            },
            {
                name: "EURTRY",
                digits: 5,
            },
            {
                name: "BTCUSD (Bitcoin)",
                digits: 3,
            },
            {
                name: "AUDJPY",
                digits: 3,
            },
            {
                name: "INDIA50",
                digits: 2,
            },
            {
                name: "EURNZD",
                digits: 5,
            },
            {
                name: "SPX (S&P 500)",
                digits: 2,
            },
            {
                name: "USDCHF",
                digits: 5,
            },
            {
                name: "CADDKK",
                digits: 5,
            },
            {
                name: "XPDUSD (Palladium)",
                digits: 2,
            },
            {
                name: "DE30",
                digits: 2,
            },
            {
                name: "AUDCHF",
                digits: 5,
            },
            {
                name: "AUDNZD",
                digits: 5,
            },
            {
                name: "Adidas",
                digits: 3,
            },
            {
                name: "AUDUSD",
                digits: 5,
            },
            {
                name: "AUDJPY",
                digits: 3,
            },
            {
                name: "EURAUD",
                digits: 5,
            },
            {
                name: "GBPUSD",
                digits: 5,
            },
            {
                name: "DSHUSD",
                digits: 3,
            },
            {
                name: "AAPL",
                digits: 2,
            },
            {
                name: "NZDCHF",
                digits: 5,
            },
            {
                name: "NZDCAD",
                digits: 5,
            },
            {
                name: "EURCHF",
                digits: 5,
            },
            {
                name: "GBPCHF",
                digits: 5,
            },
            {
                name: "DOGUSD",
                digits: 5,
            },
            {
                name: "EURCAD",
                digits: 5,
            },
            {
                name: "GBPCAD",
                digits: 5,
            },
            {
                name: "EURHUF",
                digits: 3,
            },
            {
                name: "EURJPY",
                digits: 3,
            },
            {
                name: "DJIUSD",
                digits: 2,
            },
            {
                name: "USDSGD",
                digits: 5,
            },
            {
                name: "EURJPY",
                digits: 3,
            },
            {
                name: "NZDCHF",
                digits: 5,
            },
            {
                name: "USDZAR",
                digits: 5,
            },
            {
                name: "Makita",
                digits: 0,
            },
            {
                name: "H",
                digits: 2,
            },
            {
                name: "XBRUSD",
                digits: 3,
            },
            {
                name: "UKOUSDs",
                digits: 3,
            },
            {
                name: "SPDR Gold Trust",
                digits: 2,
            },
            {
                name: "MCD",
                digits: 2,
            },
            {
                name: "LTCUSD",
                digits: 3,
            },
            {
                name: "DOGUSD",
                digits: 5,
            },
            {
                name: "ORCL",
                digits: 2,
            },
            {
                name: "XAU12",
                digits: 2,
            },
            {
                name: "Chevron Corporation",
                digits: 2,
            },
            {
                name: "EURNOK",
                digits: 5,
            },
            {
                name: "USDMXN",
                digits: 5,
            },
            {
                name: "EURJPY",
                digits: 3,
            },
            {
                name: "MAM",
                digits: 4,
            },
            {
                name: "XLMUSD",
                digits: 5,
            },
            {
                name: "Nissan Motor Co",
                digits: 1,
            },
            {
                name: "LNKUSD",
                digits: 5,
            },
            {
                name: "AUS200",
                digits: 2,
            },
            {
                name: "MC",
                digits: 2,
            },
            {
                name: "DE40",
                digits: 2,
            },
            {
                name: "USDDKK",
                digits: 5,
            },
            {
                name: "Ford Motor Company",
                digits: 2,
            },
            {
                name: "EURNZD",
                digits: 5,
            },
            {
                name: "USDSEK",
                digits: 5,
            },
            {
                name: "BCHUSD",
                digits: 3,
            },
            {
                name: "ETCUSD",
                digits: 3,
            },
            {
                name: "MCD",
                digits: 2,
            },
            {
                name: "GBPUSD",
                digits: 5,
            },
            {
                name: "GBPSGD",
                digits: 5,
            },
            {
                name: "GBPHUF",
                digits: 3,
            },
            {
                name: "FTSGBP",
                digits: 2,
            },
            {
                name: "USDTRY",
                digits: 5,
            },
            {
                name: "XAU10",
                digits: 2,
            },
            {
                name: "AUDCHF",
                digits: 5,
            },
            {
                name: "XAU06",
                digits: 2,
            },
            {
                name: "Walt Disney Company",
                digits: 2,
            },
            {
                name: "MSFT",
                digits: 2,
            },
            {
                name: "Alphabet Class A",
                digits: 2,
            },
            {
                name: "GBPCHF",
                digits: 5,
            },
            {
                name: "XAU02",
                digits: 2,
            },
            {
                name: "HomeDepot",
                digits: 2,
            },
            {
                name: "USDJPY",
                digits: 3,
            },
            {
                name: "Lufthanse",
                digits: 3,
            },
            {
                name: "EURCHF",
                digits: 5,
            },
            {
                name: "LTCUSD",
                digits: 5,
            },
            {
                name: "XAU04",
                digits: 2,
            },
            {
                name: "XAU08",
                digits: 2,
            },
            {
                name: "Pfizer",
                digits: 2,
            },
            {
                name: "International Business Machines",
                digits: 2,
            },
            {
                name: "WKHS",
                digits: 2,
            },
            {
                name: "DIS",
                digits: 2,
            },
            {
                name: "GBPNOK",
                digits: 4,
            },
            {
                name: "Cocoa",
                digits: 1,
            },
            {
                name: "XPDUSD",
                digits: 2,
            },
            {
                name: "BTCUSD",
                digits: 2,
            },
            {
                name: "ONTUSD",
                digits: 4,
            },
            {
                name: "NVIDIA",
                digits: 2,
            },
            {
                name: "XPTUSD",
                digits: 2,
            },
            {
                name: "Copper",
                digits: 4,
            },
            {
                name: "USDMXN",
                digits: 5,
            },
            {
                name: "Adidas",
                digits: 3,
            },
            {
                name: "AUDHUF",
                digits: 3,
            },
            {
                name: "Cotton",
                digits: 5,
            },
            {
                name: "Wheat",
                digits: 4,
            },
            {
                name: "USDNOK",
                digits: 5,
            },
            {
                name: "NGAS",
                digits: 3,
            },
            {
                name: "CADCHF",
                digits: 5,
            },
            {
                name: "GBPUSD",
                digits: 5,
            },
            {
                name: "USDJPY",
                digits: 3,
            },
            {
                name: "Corn",
                digits: 4,
            },
            {
                name: "EURJPY",
                digits: 3,
            },
            {
                name: "Soybean",
                digits: 4,
            },
            {
                name: "XAGUSD (Silver)",
                digits: 3,
            },
            {
                name: "USDX09",
                digits: 3,
            },
            {
                name: "CADJPY",
                digits: 3,
            },
            {
                name: "PYPL",
                digits: 2,
            },
            {
                name: "CL",
                digits: 2,
            },
            {
                name: "PLTR",
                digits: 2,
            },
            {
                name: "PG",
                digits: 2,
            },
            {
                name: "BRENT Oil",
                digits: 3,
            },
            {
                name: "ATVI",
                digits: 2,
            },
            {
                name: "BAC",
                digits: 2,
            },
            {
                name: "SRCL",
                digits: 2,
            },
            {
                name: "Airbus Group",
                digits: 3,
            },
            {
                name: "BNBUSD",
                digits: 3,
            },
            {
                name: "AEP",
                digits: 2,
            },
            {
                name: "CLX",
                digits: 2,
            },
            {
                name: "CI",
                digits: 2,
            },
            {
                name: "ABNB",
                digits: 2,
            },
            {
                name: "EURCHF",
                digits: 5,
            },
            {
                name: "AUDZAR",
                digits: 5,
            },
            {
                name: "USDX06",
                digits: 3,
            },
            {
                name: "EURAUD",
                digits: 5,
            },
            {
                name: "USDX12",
                digits: 3,
            },
            {
                name: "GBPZAR",
                digits: 5,
            },
            {
                name: "DSHUSD",
                digits: 3,
            },
            {
                name: "UBER",
                digits: 2,
            },
            {
                name: "VZ",
                digits: 2,
            },
            {
                name: "ALIUSD",
                digits: 3,
            },
            {
                name: "NKE",
                digits: 2,
            },
            {
                name: "LMT",
                digits: 2,
            },
            {
                name: "GL",
                digits: 2,
            },
            {
                name: "GM",
                digits: 2,
            },
            {
                name: "CRM",
                digits: 2,
            },
            {
                name: "BBY",
                digits: 2,
            },
            {
                name: "NIO",
                digits: 2,
            },
            {
                name: "DOTUSD",
                digits: 3,
            },
            {
                name: "EURNOK",
                digits: 4,
            },
            {
                name: "USDX03",
                digits: 3,
            },
            {
                name: "BIDU",
                digits: 2,
            },
            {
                name: "MA",
                digits: 2,
            },
            {
                name: "F",
                digits: 2,
            },
            {
                name: "GILD",
                digits: 2,
            },
            {
                name: "CSCO",
                digits: 2,
            },
            {
                name: "U",
                digits: 2,
            },
            {
                name: "AFRM",
                digits: 2,
            },
            {
                name: "MRK",
                digits: 2,
            },
            {
                name: "CHFJPY",
                digits: 3,
            },
            {
                name: "TTWO",
                digits: 2,
            },
            {
                name: "PFE",
                digits: 2,
            },
            {
                name: "Mattel Inc",
                digits: 2,
            },
            {
                name: "KO",
                digits: 2,
            },
            {
                name: "PM",
                digits: 2,
            },
            {
                name: "AMD",
                digits: 2,
            },
            {
                name: "PDD",
                digits: 2,
            },
            {
                name: "V",
                digits: 2,
            },
            {
                name: "NKLA",
                digits: 2,
            },
            {
                name: "DELL",
                digits: 2,
            },
            {
                name: "FOX",
                digits: 2,
            },
            {
                name: "QCOM",
                digits: 2,
            },
            {
                name: "WFC",
                digits: 2,
            },
            {
                name: "EURDKK",
                digits: 5,
            },
            {
                name: "WMT",
                digits: 2,
            },
            {
                name: "CADSEK",
                digits: 5,
            },
            {
                name: "GOOG",
                digits: 2,
            },
            {
                name: "EA",
                digits: 2,
            },
            {
                name: "COST",
                digits: 2,
            },
            {
                name: "ZM",
                digits: 2,
            },
            {
                name: "IBM",
                digits: 2,
            },
            {
                name: "PG",
                digits: 2,
            },
            {
                name: "DOW",
                digits: 2,
            },
            {
                name: "GE",
                digits: 2,
            },
            {
                name: "T",
                digits: 2,
            },
            {
                name: "XAUCNH",
                digits: 2,
            },
            {
                name: "SWKS",
                digits: 2,
            },
            {
                name: "KHC",
                digits: 2,
            },
            {
                name: "QS",
                digits: 2,
            },
            {
                name: "USDTHB",
                digits: 3,
            },
            {
                name: "CMCSA",
                digits: 2,
            },
            {
                name: "OXY",
                digits: 2,
            },
            {
                name: "EBAY",
                digits: 2,
            },
            {
                name: "SBUX",
                digits: 2,
            },
            {
                name: "IBKR",
                digits: 2,
            },
            {
                name: "SIRI",
                digits: 2,
            },
            {
                name: "MitsubisUFJ",
                digits: 1,
            },
            {
                name: "ADBE",
                digits: 2,
            },
            {
                name: "FIS",
                digits: 2,
            },
            {
                name: "CADSGD",
                digits: 5,
            },
            {
                name: "GAUCNH",
                digits: 3,
            },
            {
                name: "NZDCAD",
                digits: 5,
            },
            {
                name: "Alphabet",
                digits: 2,
            },
            {
                name: "SLB",
                digits: 2,
            },
            {
                name: "C",
                digits: 2,
            },
            {
                name: "SQ",
                digits: 2,
            },
            {
                name: "CVX",
                digits: 2,
            },
            {
                name: "EXC",
                digits: 2,
            },
            {
                name: "HP",
                digits: 2,
            },
            {
                name: "EURCAD",
                digits: 5,
            },
            {
                name: "Air Liquide",
                digits: 2,
            },
            {
                name: "ROST",
                digits: 2,
            },
            {
                name: "DKKSEK",
                digits: 5,
            },
            {
                name: "ORCL",
                digits: 2,
            },
            {
                name: "ASML",
                digits: 2,
            },
            {
                name: "EURSEK",
                digits: 5,
            },
            {
                name: "TGT",
                digits: 2,
            },
            {
                name: "GBPAUD",
                digits: 5,
            },
            {
                name: "XPTUSD (Platinum)",
                digits: 2,
            },
            {
                name: "INTC",
                digits: 2,
            },
            {
                name: "AAPL",
                digits: 2,
            },
            {
                name: "MXNJPY",
                digits: 3,
            },
            {
                name: "",
                digits: 0,
            },
            {
                name: "XRPUSD",
                digits: 5,
            },
            {
                name: "YFIUSD",
                digits: 0,
            },
            {
                name: "IOTUSD",
                digits: 4,
            },
            {
                name: "##DIS",
                digits: 2,
            },
            {
                name: "SXPUSD",
                digits: 4,
            },
            {
                name: "NEOUSD",
                digits: 2,
            },
            {
                name: "CHFJPY",
                digits: 3,
            },
            {
                name: "AUDCNH",
                digits: 5,
            },
            {
                name: "JPN225",
                digits: 2,
            },
            {
                name: "CUCUSD",
                digits: 5,
            },
            {
                name: "HKDJPY",
                digits: 3,
            },
            {
                name: "EURGBP",
                digits: 5,
            },
            {
                name: "SPDR Gold Trust",
                digits: 2,
            },
            {
                name: "CLM",
                digits: 2,
            },
            {
                name: "GBPUSD",
                digits: 5,
            },
            {
                name: "Canon",
                digits: 1,
            },
            {
                name: "AIA Group",
                digits: 2,
            },
            {
                name: "American International Group",
                digits: 2,
            },
            {
                name: "GBPCAD",
                digits: 5,
            },
            {
                name: "Hermes",
                digits: 2,
            },
            {
                name: "Sanofi",
                digits: 2,
            },
            {
                name: "EURJPY",
                digits: 3,
            },
            {
                name: "GBPUSD",
                digits: 5,
            },
            {
                name: "CITIC Securities",
                digits: 2,
            },
            {
                name: "CLN",
                digits: 2,
            },
            {
                name: "INTC",
                digits: 2,
            },
            {
                name: "USOUSD",
                digits: 3,
            },
            {
                name: "Siemens",
                digits: 2,
            },
            {
                name: "USDJPY",
                digits: 3,
            },
            {
                name: "XAGUSD (Silver)",
                digits: 3,
            },
            {
                name: "BRENT Oil",
                digits: 3,
            },
            {
                name: "KO",
                digits: 2,
            },
            {
                name: "NOKSEK",
                digits: 5,
            },
            {
                name: "VIX09",
                digits: 3,
            },
            {
                name: "TRYJPY",
                digits: 3,
            },
            {
                name: "ETHUSD (Ethereum)",
                digits: 3,
            },
            {
                name: "GBPJPY",
                digits: 3,
            },
            {
                name: "USDCAD",
                digits: 5,
            },
            {
                name: "France 40",
                digits: 2,
            },
            {
                name: "GBPUSD",
                digits: 5,
            },
            {
                name: "MKRUSD",
                digits: 2,
            },
            {
                name: "SOLUSD",
                digits: 3,
            },
            {
                name: "SGDCNH",
                digits: 5,
            },
            {
                name: "GBPAUD",
                digits: 5,
            },
            {
                name: "XPTUSD",
                digits: 2,
            },
            {
                name: "FILUSD",
                digits: 2,
            },
            {
                name: "Vanguard FTSE DM",
                digits: 2,
            },
            {
                name: "US30",
                digits: 2,
            },
            {
                name: "AUDCHF",
                digits: 5,
            },
            {
                name: "NZDJPY",
                digits: 3,
            },
            {
                name: "",
                digits: 0,
            },
            {
                name: "HK50",
                digits: 2,
            },
            {
                name: "CHFJPY",
                digits: 3,
            },
            {
                name: "CHFDKK",
                digits: 5,
            },
            {
                name: "NZDDKK",
                digits: 5,
            },
            {
                name: "Japan 225",
                digits: 1,
            },
            {
                name: "CADJPY",
                digits: 3,
            },
            {
                name: "AUDCAD",
                digits: 5,
            },
            {
                name: "ETHJPY",
                digits: 1,
            },
            {
                name: "BNBUSD",
                digits: 3,
            },
            {
                name: "XNGUSD",
                digits: 3,
            },
            {
                name: "CHFHUF",
                digits: 3,
            },
            {
                name: "WTI07",
                digits: 3,
            },
            {
                name: "OMGUSD",
                digits: 3,
            },
            {
                name: "LNKUSD",
                digits: 3,
            },
            {
                name: "BATUSD",
                digits: 5,
            },
            {
                name: "GBPJPY",
                digits: 3,
            },
            {
                name: "CNOOC",
                digits: 2,
            },
            {
                name: "USDILS",
                digits: 5,
            },
            {
                name: "NZDNOK",
                digits: 4,
            },
            {
                name: "FILUSD",
                digits: 2,
            },
            {
                name: "EOSUSD",
                digits: 4,
            },
            {
                name: "US Oil (WTI)",
                digits: 3,
            },
            {
                name: "DSHUSD",
                digits: 3,
            },
            {
                name: "AUDSEK",
                digits: 5,
            },
            {
                name: "BCHUSD",
                digits: 3,
            },
            {
                name: "EURAUD",
                digits: 5,
            },
            {
                name: "Keyence",
                digits: 0,
            },
            {
                name: "DOTUSD",
                digits: 3,
            },
            {
                name: "XPTUSD (Platinum)",
                digits: 2,
            },
            {
                name: "Vanguard S&P500",
                digits: 2,
            },
            {
                name: "XPDUSD",
                digits: 2,
            },
            {
                name: "American International Group",
                digits: 2,
            },
            {
                name: "US 30 (Dow Jones)",
                digits: 2,
            },
            {
                name: "Honda Motor Company",
                digits: 1,
            },
            {
                name: "USDJPY",
                digits: 3,
            },
            {
                name: "DLPN",
                digits: 2,
            },
            {
                name: "ChinaMobile",
                digits: 2,
            },
            {
                name: "EURHKD",
                digits: 5,
            },
            {
                name: "KDDI",
                digits: 1,
            },
            {
                name: "Toshiba",
                digits: 1,
            },
            {
                name: "Bayer",
                digits: 3,
            },
            {
                name: "EURJPY",
                digits: 3,
            },
            {
                name: "AUDCAD",
                digits: 5,
            },
            {
                name: "Cisco Systems",
                digits: 2,
            },
            {
                name: "XPDUSD (Palladium)",
                digits: 2,
            },
            {
                name: "XAUUSDf",
                digits: 2,
            },
            {
                name: "EURO 50",
                digits: 2,
            },
            {
                name: "Electricite de France",
                digits: 3,
            },
            {
                name: "Natural Gas",
                digits: 4,
            },
            {
                name: "EURUSD",
                digits: 5,
            },
            {
                name: "HKDJPY",
                digits: 3,
            },
            {
                name: "USDJPY",
                digits: 3,
            },
            {
                name: "GlaxoSmithKline",
                digits: 4,
            },
            {
                name: "NZDSGD",
                digits: 5,
            },
            {
                name: "Cisco Systems",
                digits: 2,
            },
            {
                name: "ETHUSD",
                digits: 2,
            },
            {
                name: "Air Liquide",
                digits: 2,
            },
            {
                name: "Mizuho Financial Group",
                digits: 1,
            },
            {
                name: "American Airlines Group",
                digits: 2,
            },
            {
                name: "NZDUSD",
                digits: 5,
            },
            {
                name: "Disney",
                digits: 2,
            },
            {
                name: "Lenovo Group",
                digits: 2,
            },
            {
                name: "CLF",
                digits: 2,
            },
            {
                name: "EURCAD",
                digits: 5,
            },
            {
                name: "NZDJPY",
                digits: 3,
            },
            {
                name: "EOSUSD",
                digits: 4,
            },
            {
                name: "Ford Motor Company",
                digits: 2,
            },
            {
                name: "EDF",
                digits: 3,
            },
            {
                name: "ETCUSD",
                digits: 3,
            },
            {
                name: "CK Hutchison Holdings",
                digits: 2,
            },
            {
                name: "BABA",
                digits: 2,
            },
            {
                name: "AUDNZD",
                digits: 5,
            },
            {
                name: "UKO09",
                digits: 3,
            },
            {
                name: "EURUSD",
                digits: 5,
            },
            {
                name: "XMRUSD",
                digits: 3,
            },
            {
                name: "EOSUSD",
                digits: 4,
            },
            {
                name: "Glencore",
                digits: 4,
            },
            {
                name: "AUDNZD",
                digits: 5,
            },
            {
                name: "GBPCAD",
                digits: 5,
            },
            {
                name: "EURNZD",
                digits: 5,
            },
            {
                name: "LNKUSD",
                digits: 5,
            },
            {
                name: "EURUSD",
                digits: 5,
            },
            {
                name: "Goldman Sachs Group Inc",
                digits: 2,
            },
            {
                name: "EURJPY",
                digits: 3,
            },
            {
                name: "US500",
                digits: 2,
            },
            {
                name: "YFIUSD",
                digits: 0,
            },
            {
                name: "iShares Russell 2000",
                digits: 2,
            },
            {
                name: "Japan Tobacco",
                digits: 0,
            },
            {
                name: "AUDCAD",
                digits: 5,
            },
            {
                name: "USDJPY",
                digits: 3,
            },
            {
                name: "CITIC Securities",
                digits: 2,
            },
            {
                name: "USDJPY",
                digits: 3,
            },
            {
                name: "XPTUSD (Platinum)",
                digits: 2,
            },
            {
                name: "ALGUSD",
                digits: 4,
            },
            {
                name: "EURAUD",
                digits: 5,
            },
            {
                name: "Bayerische Motoren Werke",
                digits: 3,
            },
            {
                name: "Spain 35",
                digits: 2,
            },
            {
                name: "XTZUSD",
                digits: 3,
            },
            {
                name: "Airbus Group",
                digits: 3,
            },
            {
                name: "American International Group",
                digits: 2,
            },
            {
                name: "IOTUSD",
                digits: 4,
            },
            {
                name: "ZECUSD",
                digits: 3,
            },
            {
                name: "Keyence",
                digits: 0,
            },
            {
                name: "UNIUSD",
                digits: 4,
            },
            {
                name: "MKRUSD",
                digits: 2,
            },
            {
                name: "USDCHF",
                digits: 5,
            },
            {
                name: "UKOUSD",
                digits: 3,
            },
            {
                name: "AUDHKD",
                digits: 5,
            },
            {
                name: "PEPUSD",
                digits: 5,
            },
            {
                name: "Vanguard FTSE DM",
                digits: 2,
            },
            {
                name: "KDDI",
                digits: 1,
            },
            {
                name: "CK Hutchison Holdings",
                digits: 2,
            },
            {
                name: "CADJPY",
                digits: 3,
            },
            {
                name: "CADHKD",
                digits: 5,
            },
            {
                name: "NZDJPY",
                digits: 3,
            },
            {
                name: "Boeing Company",
                digits: 2,
            },
            {
                name: "CNHJPY",
                digits: 3,
            },
            {
                name: "GBPCAD",
                digits: 5,
            },
            {
                name: "AUDUSD",
                digits: 5,
            },
            {
                name: "COPPER",
                digits: 5,
            },
            {
                name: "Japan 225",
                digits: 1,
            },
            {
                name: "Allianz",
                digits: 3,
            },
            {
                name: "Amazon",
                digits: 2,
            },
            {
                name: "CHFCNH",
                digits: 5,
            },
            {
                name: "OMGUSD",
                digits: 3,
            },
            {
                name: "SOLUSD",
                digits: 3,
            },
            {
                name: "TRXUSD",
                digits: 5,
            },
            {
                name: "AUDDKK",
                digits: 5,
            },
            {
                name: "EURCNH",
                digits: 5,
            },
            {
                name: "GBPCNH",
                digits: 5,
            },
            {
                name: "NZDCHF",
                digits: 5,
            },
            {
                name: "FILUSD",
                digits: 2,
            },
            {
                name: "Schwab US Dividend equity",
                digits: 2,
            },
            {
                name: "Keyence",
                digits: 0,
            },
            {
                name: "CNOOC",
                digits: 2,
            },
            {
                name: "GBPAUD",
                digits: 5,
            },
            {
                name: "Citigroup",
                digits: 2,
            },
            {
                name: "USDCHF",
                digits: 5,
            },
            {
                name: "Hong Kong 50",
                digits: 1,
            },
            {
                name: "NZDCAD",
                digits: 5,
            },
            {
                name: "BASF",
                digits: 3,
            },
            {
                name: "OMGUSD",
                digits: 3,
            },
            {
                name: "NZDCNH",
                digits: 5,
            },
            {
                name: "USDCNH",
                digits: 5,
            },
            {
                name: "Boeing Company",
                digits: 2,
            },
            {
                name: "TRXUSD",
                digits: 5,
            },
            {
                name: "FTMUSD",
                digits: 4,
            },
            {
                name: "AVAUSD",
                digits: 2,
            },
            {
                name: "CLU",
                digits: 2,
            },
            {
                name: "NZDJPY",
                digits: 3,
            },
            {
                name: "USDHKD",
                digits: 5,
            },
            {
                name: "USDSGD",
                digits: 5,
            },
            {
                name: "YFIUSD",
                digits: 0,
            },
            {
                name: "AVAUSD",
                digits: 2,
            },
            {
                name: "NERUSD",
                digits: 3,
            },
            {
                name: "NERUSD",
                digits: 3,
            },
            {
                name: "USDNOK",
                digits: 5,
            },
            {
                name: "SXPUSD",
                digits: 4,
            },
            {
                name: "MRK",
                digits: 2,
            },
            {
                name: "United States Oil Fund",
                digits: 2,
            },
            {
                name: "Makita",
                digits: 0,
            },
            {
                name: "Henderson Land",
                digits: 2,
            },
            {
                name: "GBPJPY",
                digits: 3,
            },
            {
                name: "GBPCHF",
                digits: 5,
            },
            {
                name: "Henderson Land",
                digits: 2,
            },
            {
                name: "SANUSD",
                digits: 4,
            },
            {
                name: "Australia 200",
                digits: 1,
            },
            {
                name: "British American Tobacco",
                digits: 4,
            },
            {
                name: "Bank of America Corporation",
                digits: 2,
            },
            {
                name: "GBPJPY",
                digits: 3,
            },
            {
                name: "CADSEK",
                digits: 5,
            },
            {
                name: "XAGUSD",
                digits: 3,
            },
            {
                name: "BARUSD",
                digits: 5,
            },
            {
                name: "Australia 200",
                digits: 1,
            },
            {
                name: "XLMUSD",
                digits: 5,
            },
            {
                name: "ATMUSD",
                digits: 3,
            },
            {
                name: "NZDUSD",
                digits: 5,
            },
            {
                name: "AAVUSD",
                digits: 2,
            },
            {
                name: "USDCAD",
                digits: 5,
            },
            {
                name: "France 40",
                digits: 2,
            },
            {
                name: "QQQ",
                digits: 2,
            },
            {
                name: "Mitsubishi UFJ Financial Group",
                digits: 1,
            },
            {
                name: "Bank of China",
                digits: 2,
            },
            {
                name: "COMUSD",
                digits: 2,
            },
            {
                name: "XAUUSD",
                digits: 2,
            },
            {
                name: "USDCAD",
                digits: 5,
            },
            {
                name: "WOOUSD",
                digits: 5,
            },
            {
                name: "China 50",
                digits: 2,
            },
            {
                name: "Bayer",
                digits: 3,
            },
            {
                name: "SPDR Gold Trust",
                digits: 2,
            },
            {
                name: "##TSLA",
                digits: 2,
            },
            {
                name: "Citigroup",
                digits: 2,
            },
            {
                name: "ADAUSD",
                digits: 5,
            },
            {
                name: "AXSUSD",
                digits: 2,
            },
            {
                name: "Industrial and Commercial Bank of China",
                digits: 2,
            },
            {
                name: "ZECUSD",
                digits: 3,
            },
            {
                name: "KSMUSD",
                digits: 2,
            },
            {
                name: "CHFPLN",
                digits: 5,
            },
            {
                name: "Mitsui & Co",
                digits: 1,
            },
            {
                name: "HSBC Holdings",
                digits: 2,
            },
            {
                name: "MTCUSD",
                digits: 5,
            },
            {
                name: "EURGBP",
                digits: 5,
            },
            {
                name: "Germany 40",
                digits: 2,
            },
            {
                name: "Bayerische Motoren Werke",
                digits: 3,
            },
            {
                name: "Cisco Systems",
                digits: 2,
            },
            {
                name: "Lenovo",
                digits: 2,
            },
            {
                name: "ARWUSD",
                digits: 3,
            },
            {
                name: "QTMUSD",
                digits: 3,
            },
            {
                name: "XAUUSD",
                digits: 2,
            },
            {
                name: "DOGUSD",
                digits: 5,
            },
            {
                name: "APEUSD",
                digits: 3,
            },
            {
                name: "ZENUSD",
                digits: 3,
            },
            {
                name: "NZDUSD",
                digits: 5,
            },
            {
                name: "NZDUSD",
                digits: 5,
            },
            {
                name: "CLV",
                digits: 2,
            },
            {
                name: "AUDJPY",
                digits: 3,
            },
            {
                name: "COMUSD",
                digits: 2,
            },
            {
                name: "Mizuho Financial Group",
                digits: 1,
            },
            {
                name: "SNXUSD",
                digits: 3,
            },
            {
                name: "Industrial and Commercial Bank of China",
                digits: 2,
            },
            {
                name: "EURCHF",
                digits: 5,
            },
            {
                name: "US 100 (Nasdaq)",
                digits: 2,
            },
            {
                name: "GBPUSD",
                digits: 5,
            },
            {
                name: "UK 100",
                digits: 2,
            },
            {
                name: "Danone",
                digits: 2,
            },
            {
                name: "Microsoft Corporation",
                digits: 2,
            },
            {
                name: "Chevron Corporation",
                digits: 2,
            },
            {
                name: "GBPAUD",
                digits: 5,
            },
            {
                name: "Wells Fargo & Company",
                digits: 2,
            },
            {
                name: "EURILS",
                digits: 5,
            },
            {
                name: "MTCUSD",
                digits: 5,
            },
            {
                name: "ARWUSD",
                digits: 3,
            },
            {
                name: "China Shenhua Energy",
                digits: 2,
            },
            {
                name: "NZDCHF",
                digits: 5,
            },
            {
                name: "QQQ",
                digits: 2,
            },
            {
                name: "SGDJPY",
                digits: 3,
            },
            {
                name: "EURPLN",
                digits: 5,
            },
            {
                name: "EGLUSD",
                digits: 2,
            },
            {
                name: "Nissan Motor Co",
                digits: 1,
            },
            {
                name: "CADJPY",
                digits: 3,
            },
            {
                name: "Lenovo Group",
                digits: 2,
            },
            {
                name: "AUDJPY",
                digits: 3,
            },
            {
                name: "Danone",
                digits: 2,
            },
            {
                name: "CHFJPY",
                digits: 3,
            },
            {
                name: "US 30 (Dow Jones)",
                digits: 2,
            },
            {
                name: "BNP Paribas",
                digits: 3,
            },
            {
                name: "Walt Disney Company",
                digits: 2,
            },
            {
                name: "USDCAD",
                digits: 5,
            },
            {
                name: "COPPER",
                digits: 5,
            },
            {
                name: "FedEx Corporation",
                digits: 2,
            },
            {
                name: "ALGUSD",
                digits: 4,
            },
            {
                name: "USDCNH",
                digits: 5,
            },
            {
                name: "QTMUSD",
                digits: 3,
            },
            {
                name: "AUDCAD",
                digits: 5,
            },
            {
                name: "AUDCNH",
                digits: 5,
            },
            {
                name: "USDIDRc",
                digits: 0,
            },
            {
                name: "Japan 225",
                digits: 0,
            },
            {
                name: "DYDUSD",
                digits: 3,
            },
            {
                name: "Seven & i Holdings Co",
                digits: 0,
            },
            {
                name: "RSRUSD",
                digits: 6,
            },
            {
                name: "PICC Property and Casualty",
                digits: 2,
            },
            {
                name: "NZDUSD",
                digits: 5,
            },
            {
                name: "US 100 (Nasdaq)",
                digits: 2,
            },
            {
                name: "BARUSD",
                digits: 5,
            },
            {
                name: "GBPPLN",
                digits: 5,
            },
            {
                name: "AXA",
                digits: 3,
            },
            {
                name: "Ford Motor Company",
                digits: 2,
            },
            {
                name: "VECUSD",
                digits: 4,
            },
            {
                name: "Schwab US Dividend equity",
                digits: 2,
            },
            {
                name: "AUDCHF",
                digits: 5,
            },
            {
                name: "HSIU",
                digits: 0,
            },
            {
                name: "AUDNOK",
                digits: 4,
            },
            {
                name: "NZDCHF",
                digits: 5,
            },
            {
                name: "SNXUSD",
                digits: 3,
            },
            {
                name: "Softbank",
                digits: 0,
            },
            {
                name: "EURUSD",
                digits: 5,
            },
            {
                name: "China Shenhua Energy",
                digits: 2,
            },
            {
                name: "AUDSGD",
                digits: 5,
            },
            {
                name: "L'Oreal",
                digits: 2,
            },
            {
                name: "Johnson & Johnson",
                digits: 2,
            },
            {
                name: "CHFJPY",
                digits: 3,
            },
            {
                name: "CNHJPY",
                digits: 3,
            },
            {
                name: "Diageo",
                digits: 4,
            },
            {
                name: "AUDCHF",
                digits: 5,
            },
            {
                name: "Meta Platforms",
                digits: 2,
            },
            {
                name: "NOKSEK",
                digits: 5,
            },
            {
                name: "GBPAUD",
                digits: 5,
            },
            {
                name: "Vodafone Group",
                digits: 4,
            },
            {
                name: "SANUSD",
                digits: 4,
            },
            {
                name: "LPTUSD",
                digits: 3,
            },
            {
                name: "Sony",
                digits: 0,
            },
            {
                name: "EURNOK",
                digits: 4,
            },
            {
                name: "SHK Ppt",
                digits: 1,
            },
            {
                name: "ALGUSD",
                digits: 4,
            },
            {
                name: "AUDZAR",
                digits: 5,
            },
            {
                name: "EURAUD",
                digits: 5,
            },
            {
                name: "Deutsche Telekom",
                digits: 3,
            },
            {
                name: "USDCHF",
                digits: 5,
            },
            {
                name: "KNCUSD",
                digits: 3,
            },
            {
                name: "Nike",
                digits: 2,
            },
            {
                name: "FedEx Corporation",
                digits: 2,
            },
            {
                name: "SUSUSD",
                digits: 3,
            },
            {
                name: "EURSGD",
                digits: 5,
            },
            {
                name: "SKLUSD",
                digits: 5,
            },
            {
                name: "Hong Kong 50",
                digits: 1,
            },
            {
                name: "USDDKK",
                digits: 5,
            },
            {
                name: "ZENUSD",
                digits: 3,
            },
            {
                name: "EURNZD",
                digits: 5,
            },
            {
                name: "EURTRY",
                digits: 5,
            },
            {
                name: "Tencent Holdings",
                digits: 1,
            },
            {
                name: "NERUSD",
                digits: 3,
            },
            {
                name: "UK100",
                digits: 2,
            },
            {
                name: "AT&T",
                digits: 2,
            },
            {
                name: "XAUUSD",
                digits: 2,
            },
            {
                name: "USTEC",
                digits: 2,
            },
            {
                name: "GBPCHF",
                digits: 5,
            },
            {
                name: "CADJPY",
                digits: 3,
            },
            {
                name: "Electricite de France",
                digits: 3,
            },
            {
                name: "Tesla",
                digits: 2,
            },
            {
                name: "United Continental Holdings",
                digits: 2,
            },
            {
                name: "CRVUSD",
                digits: 3,
            },
            {
                name: "USDJPY",
                digits: 3,
            },
            {
                name: "DYDUSD",
                digits: 3,
            },
            {
                name: "Toshiba Corporation",
                digits: 1,
            },
            {
                name: "GBPCZK",
                digits: 4,
            },
            {
                name: "Boeing Company",
                digits: 2,
            },
            {
                name: "Xiaomi",
                digits: 2,
            },
            {
                name: "GBPAUD",
                digits: 5,
            },
            {
                name: "VIX06",
                digits: 3,
            },
            {
                name: "CADJPY",
                digits: 3,
            },
            {
                name: "Glencore",
                digits: 4,
            },
            {
                name: "ONTUSD",
                digits: 4,
            },
            {
                name: "Electricite de France",
                digits: 3,
            },
            {
                name: "USDTRY",
                digits: 5,
            },
            {
                name: "Alphabet Class A",
                digits: 2,
            },
            {
                name: "ONEUSD",
                digits: 5,
            },
            {
                name: "CADNOK",
                digits: 4,
            },
            {
                name: "CLX",
                digits: 2,
            },
            {
                name: "L'Oreal",
                digits: 2,
            },
            {
                name: "Visa",
                digits: 2,
            },
            {
                name: "STOUSD",
                digits: 4,
            },
            {
                name: "CADCNH",
                digits: 5,
            },
            {
                name: "ALIUSD",
                digits: 3,
            },
            {
                name: "Toyota Motor Corp",
                digits: 0,
            },
            {
                name: "EURCAD",
                digits: 5,
            },
            {
                name: "UKO02",
                digits: 3,
            },
            {
                name: "GBPJPY",
                digits: 3,
            },
            {
                name: "NZDCHF",
                digits: 5,
            },
            {
                name: "GlaxoSmithKline",
                digits: 4,
            },
            {
                name: "Goldman Sachs Group Inc",
                digits: 2,
            },
            {
                name: "LRCUSD",
                digits: 4,
            },
            {
                name: "Verizon Communications",
                digits: 2,
            },
            {
                name: "KIE",
                digits: 0,
            },
            {
                name: "UKO03",
                digits: 3,
            },
            {
                name: "GBPSEK",
                digits: 5,
            },
            {
                name: "CHRUSD",
                digits: 4,
            },
            {
                name: "EURZAR",
                digits: 4,
            },
            {
                name: "AUDCAD",
                digits: 5,
            },
            {
                name: "Iberdrola",
                digits: 3,
            },
            {
                name: "Hyatt Hotels Corporation",
                digits: 2,
            },
            {
                name: "UKO04",
                digits: 3,
            },
            {
                name: "INCUSD",
                digits: 4,
            },
            {
                name: "GBPCAD",
                digits: 5,
            },
            {
                name: "ATMUSD",
                digits: 3,
            },
            {
                name: "USDZAR",
                digits: 5,
            },
            {
                name: "EURDKK",
                digits: 5,
            },
            {
                name: "GBPNZD",
                digits: 5,
            },
            {
                name: "UKO08",
                digits: 3,
            },
            {
                name: "Lufthanse",
                digits: 3,
            },
            {
                name: "Home Depot",
                digits: 2,
            },
            {
                name: "Bank of America Corporation",
                digits: 2,
            },
            {
                name: "GBPMXN",
                digits: 4,
            },
            {
                name: "HSIH",
                digits: 0,
            },
            {
                name: "UKO09",
                digits: 3,
            },
            {
                name: "Exxon Mobil Corporation",
                digits: 2,
            },
            {
                name: "EURMXN",
                digits: 5,
            },
            {
                name: "XTZUSD",
                digits: 3,
            },
            {
                name: "NZDSEK",
                digits: 5,
            },
            {
                name: "British American Tobacco",
                digits: 4,
            },
            {
                name: "Nvidia",
                digits: 2,
            },
            {
                name: "MCD",
                digits: 2,
            },
            {
                name: "NZDSGD",
                digits: 5,
            },
            {
                name: "WTI05",
                digits: 3,
            },
            {
                name: "UKO10",
                digits: 3,
            },
            {
                name: "UKOUSD",
                digits: 3,
            },
            {
                name: "AUDNZD",
                digits: 5,
            },
            {
                name: "USDSEK",
                digits: 5,
            },
            {
                name: "EURZAR",
                digits: 5,
            },
            {
                name: "GBPPLN",
                digits: 5,
            },
            {
                name: "USOUSD",
                digits: 3,
            },
            {
                name: "BARUSD",
                digits: 5,
            },
            {
                name: "NGCUSD",
                digits: 4,
            },
            {
                name: "MMM",
                digits: 2,
            },
            {
                name: "EURAUD",
                digits: 5,
            },
            {
                name: "BRENT Oil",
                digits: 3,
            },
            {
                name: "WTI01",
                digits: 3,
            },
            {
                name: "ETHUSD (Ethereum)",
                digits: 3,
            },
            {
                name: "XRPUSD",
                digits: 5,
            },
            {
                name: "NZDJPY",
                digits: 3,
            },
            {
                name: "LVMH Moet Hennessy Louis Vuitton",
                digits: 2,
            },
            {
                name: "International Business Machines",
                digits: 2,
            },
            {
                name: "WTI05",
                digits: 3,
            },
            {
                name: "MANUSD",
                digits: 4,
            },
            {
                name: "USDNOK",
                digits: 4,
            },
            {
                name: "Nestle",
                digits: 2,
            },
            {
                name: "AUDCAD",
                digits: 5,
            },
            {
                name: "FDX",
                digits: 2,
            },
            {
                name: "WTI06",
                digits: 3,
            },
            {
                name: "EURO 50",
                digits: 2,
            },
            {
                name: "Natural Gas",
                digits: 4,
            },
            {
                name: "ING Group",
                digits: 2,
            },
            {
                name: "TETUSD",
                digits: 3,
            },
            {
                name: "AUDCHF",
                digits: 5,
            },
            {
                name: "GS",
                digits: 2,
            },
            {
                name: "WTI07",
                digits: 3,
            },
            {
                name: "SPX (S&P 500)",
                digits: 2,
            },
            {
                name: "iShares Russell 2000",
                digits: 2,
            },
            {
                name: "Novartis",
                digits: 2,
            },
            {
                name: "Intel",
                digits: 2,
            },
            {
                name: "EURHKD",
                digits: 5,
            },
            {
                name: "WTI11",
                digits: 3,
            },
            {
                name: "BCHUSD",
                digits: 3,
            },
            {
                name: "FTMUSD",
                digits: 4,
            },
            {
                name: "USDSGD",
                digits: 5,
            },
            {
                name: "USDMXN",
                digits: 4,
            },
            {
                name: "NOKJPY",
                digits: 3,
            },
            {
                name: "NOKSEK",
                digits: 5,
            },
            {
                name: "WTI12",
                digits: 3,
            },
            {
                name: "USDJPY",
                digits: 3,
            },
            {
                name: "L'Oreal",
                digits: 2,
            },
            {
                name: "USDTRY",
                digits: 5,
            },
            {
                name: "Johnson & Johnson",
                digits: 2,
            },
            {
                name: "CLK",
                digits: 2,
            },
            {
                name: "LTCUSD",
                digits: 5,
            },
            {
                name: "AIA Group",
                digits: 2,
            },
            {
                name: "GMTUSD",
                digits: 4,
            },
            {
                name: "GBPNZD",
                digits: 5,
            },
            {
                name: "IOTUSD",
                digits: 4,
            },
            {
                name: "NZDSEK",
                digits: 5,
            },
            {
                name: "DE40",
                digits: 2,
            },
            {
                name: "BATUSD",
                digits: 5,
            },
            {
                name: "Shell PLC",
                digits: 3,
            },
            {
                name: "USDTHB",
                digits: 4,
            },
            {
                name: "JPMorgan Chase & Co",
                digits: 2,
            },
            {
                name: "FLWUSD",
                digits: 3,
            },
            {
                name: "AUS200",
                digits: 2,
            },
            {
                name: "EURNZD",
                digits: 5,
            },
            {
                name: "SGDHKD",
                digits: 5,
            },
            {
                name: "WTIUSD",
                digits: 3,
            },
            {
                name: "Rio Tinto",
                digits: 4,
            },
            {
                name: "Vanguard S&P500",
                digits: 2,
            },
            {
                name: "ES35",
                digits: 1,
            },
            {
                name: "AUDUSD",
                digits: 5,
            },
            {
                name: "GRTUSD",
                digits: 5,
            },
            {
                name: "CADCHF",
                digits: 5,
            },
            {
                name: "Roche Holding",
                digits: 1,
            },
            {
                name: "ING",
                digits: 2,
            },
            {
                name: "US500",
                digits: 2,
            },
            {
                name: "COPPER",
                digits: 5,
            },
            {
                name: "ETHUSD",
                digits: 3,
            },
            {
                name: "Mastercard",
                digits: 2,
            },
            {
                name: "ZILUSD",
                digits: 5,
            },
            {
                name: "GBPHKD",
                digits: 5,
            },
            {
                name: "USDSGD",
                digits: 5,
            },
            {
                name: "USTEC",
                digits: 2,
            },
            {
                name: "SAP",
                digits: 3,
            },
            {
                name: "Marriott International",
                digits: 2,
            },
            {
                name: "GALUSD",
                digits: 5,
            },
            {
                name: "USDSEK",
                digits: 5,
            },
            {
                name: "SOLUSD",
                digits: 3,
            },
            {
                name: "Siemens",
                digits: 3,
            },
            {
                name: "McDonald's",
                digits: 2,
            },
            {
                name: "CEOUSD",
                digits: 3,
            },
            {
                name: "NZDCAD",
                digits: 5,
            },
            {
                name: "Unilever",
                digits: 4,
            },
            {
                name: "3M Company",
                digits: 2,
            },
            {
                name: "CHZUSD",
                digits: 5,
            },
            {
                name: "EURSEK",
                digits: 5,
            },
            {
                name: "##BABA",
                digits: 2,
            },
            {
                name: "Schwab US Dividend equity",
                digits: 2,
            },
            {
                name: "Vodafone Group",
                digits: 4,
            },
            {
                name: "Merck & Company",
                digits: 2,
            },
            {
                name: "ENJUSD",
                digits: 4,
            },
            {
                name: "GBPSGD",
                digits: 5,
            },
            {
                name: "##GOOG",
                digits: 2,
            },
            {
                name: "Volkswagen",
                digits: 3,
            },
            {
                name: "Microsoft Corporation",
                digits: 2,
            },
            {
                name: "KAVUSD",
                digits: 4,
            },
            {
                name: "EURNOK",
                digits: 4,
            },
            {
                name: "##GE",
                digits: 2,
            },
            {
                name: "NASDAQ OMX Group",
                digits: 2,
            },
            {
                name: "KNCUSD",
                digits: 3,
            },
            {
                name: "EURHUF",
                digits: 3,
            },
            {
                name: "Netflix",
                digits: 2,
            },
            {
                name: "XEMUSD",
                digits: 4,
            },
            {
                name: "USDPLN",
                digits: 5,
            },
            {
                name: "Nike",
                digits: 2,
            },
            {
                name: "WTI01",
                digits: 3,
            },
            {
                name: "SKLUSD",
                digits: 5,
            },
            {
                name: "USDDKK",
                digits: 5,
            },
            {
                name: "Pepsico",
                digits: 2,
            },
            {
                name: "ANKUSD",
                digits: 5,
            },
            {
                name: "GBPNOK",
                digits: 4,
            },
            {
                name: "WTI09",
                digits: 3,
            },
            {
                name: "Procter & Gamble Company",
                digits: 2,
            },
            {
                name: "SXPUSD",
                digits: 4,
            },
            {
                name: "AUDDKK",
                digits: 5,
            },
            {
                name: "AT&T",
                digits: 2,
            },
            {
                name: "WTI12",
                digits: 3,
            },
            {
                name: "RVNUSD",
                digits: 5,
            },
            {
                name: "NZDCHF",
                digits: 5,
            },
            {
                name: "Tesla",
                digits: 2,
            },
            {
                name: "WOOUSD",
                digits: 5,
            },
            {
                name: "ETCUSD",
                digits: 3,
            },
            {
                name: "GBPCHF",
                digits: 5,
            },
            {
                name: "United Continental Holdings",
                digits: 2,
            },
            {
                name: "ONTUSD",
                digits: 4,
            },
            {
                name: "USDTRY",
                digits: 5,
            },
            {
                name: "UnitedHealth Group",
                digits: 2,
            },
            {
                name: "IMXUSD",
                digits: 4,
            },
            {
                name: "EURTRY",
                digits: 5,
            },
            {
                name: "Visa",
                digits: 2,
            },
            {
                name: "XMRUSD",
                digits: 3,
            },
            {
                name: "STOUSD",
                digits: 4,
            },
            {
                name: "EURHKD",
                digits: 5,
            },
            {
                name: "Verizon Communications",
                digits: 2,
            },
            {
                name: "RENUSD",
                digits: 4,
            },
            {
                name: "EURZAR",
                digits: 4,
            },
            {
                name: "Wells Fargo & Company",
                digits: 2,
            },
            {
                name: "CELUSD",
                digits: 5,
            },
            {
                name: "SGDJPY",
                digits: 3,
            },
            {
                name: "Workhorse Group",
                digits: 2,
            },
            {
                name: "CNEUSD",
                digits: 4,
            },
            {
                name: "USDHKD",
                digits: 5,
            },
            {
                name: "USDSGD",
                digits: 5,
            },
            {
                name: "PEPUSD",
                digits: 5,
            },
            {
                name: "USDZAR",
                digits: 5,
            },
            {
                name: "Exxon Mobil Corporation",
                digits: 2,
            },
            {
                name: "CHRUSD",
                digits: 4,
            },
            {
                name: "GALUSD",
                digits: 5,
            },
            {
                name: "EURMXN",
                digits: 5,
            },
            {
                name: "Nvidia",
                digits: 2,
            },
            {
                name: "COTUSD",
                digits: 5,
            },
            {
                name: "EURPLN",
                digits: 5,
            },
            {
                name: "Mattel Inc",
                digits: 2,
            },
            {
                name: "CTSUSD",
                digits: 4,
            },
            {
                name: "GBPZAR",
                digits: 5,
            },
            {
                name: "Dolphin Entertainment Inc",
                digits: 2,
            },
            {
                name: "ZRXUSD",
                digits: 4,
            },
            {
                name: "NZDCHF",
                digits: 5,
            },
            {
                name: "NZDSGD",
                digits: 5,
            },
            {
                name: "USDHUF",
                digits: 3,
            },
            {
                name: "EURCZK",
                digits: 4,
            },
            {
                name: "USDCZK",
                digits: 4,
            },
            {
                name: "EURDKK",
                digits: 5,
            },
            {
                name: "USDCNH",
                digits: 5,
            },
            {
                name: "GBPSEK",
                digits: 5,
            },
            {
                name: "GBPPLN",
                digits: 5,
            },
            {
                name: "AUDSEK",
                digits: 5,
            },
            {
                name: "AUDSGD",
                digits: 5,
            },
            {
                name: "AUDZAR",
                digits: 5,
            },
            {
                name: "CADDKK",
                digits: 5,
            },
            {
                name: "CADMXN",
                digits: 5,
            },
            {
                name: "CADNOK",
                digits: 5,
            },
            {
                name: "CADSEK",
                digits: 5,
            },
            {
                name: "CADSGD",
                digits: 5,
            },
            {
                name: "CHFNOK",
                digits: 5,
            },
            {
                name: "CHFPLN",
                digits: 5,
            },
            {
                name: "CHFSEK",
                digits: 5,
            },
            {
                name: "CHFSGD",
                digits: 5,
            },
            {
                name: "CHFZAR",
                digits: 5,
            },
            {
                name: "DKKNOK",
                digits: 5,
            },
            {
                name: "DKKSEK",
                digits: 5,
            },
            {
                name: "EURCNH",
                digits: 5,
            },
            {
                name: "EURILS",
                digits: 5,
            },
            {
                name: "EURSGD",
                digits: 5,
            },
            {
                name: "GBPDKK",
                digits: 5,
            },
            {
                name: "GBPTRY",
                digits: 5,
            },
            {
                name: "HKDJPY",
                digits: 3,
            },
            {
                name: "NOKJPY",
                digits: 3,
            },
            {
                name: "NOKSEK",
                digits: 5,
            },
            {
                name: "NZDSEK",
                digits: 5,
            },
            {
                name: "TRYJPY",
                digits: 3,
            },
            {
                name: "USDTHB",
                digits: 4,
            },
            {
                name: "ZARJPY",
                digits: 3,
            },
            {
                name: "AUDHUF",
                digits: 3,
            },
            {
                name: "AUDNOK",
                digits: 4,
            },
            {
                name: "CHFDKK",
                digits: 5,
            },
            {
                name: "CHFHUF",
                digits: 3,
            },
            {
                name: "CNHJPY",
                digits: 3,
            },
            {
                name: "GBPCZK",
                digits: 4,
            },
            {
                name: "GBPHKD",
                digits: 5,
            },
            {
                name: "GBPHUF",
                digits: 5,
            },
            {
                name: "GBPMXN",
                digits: 4,
            },
            {
                name: "MXNJPY",
                digits: 3,
            },
            {
                name: "NZDDKK",
                digits: 5,
            },
            {
                name: "NZDHUF",
                digits: 3,
            },
            {
                name: "NZDNOK",
                digits: 4,
            },
            {
                name: "SEKJPY",
                digits: 3,
            },
            {
                name: "SGDHKD",
                digits: 5,
            },
            {
                name: "USDILS",
                digits: 5,
            },
            {
                name: "GBPCAD",
                digits: 5,
            },
            {
                name: "Mitsubishi UFJ Financial Group",
                digits: 1,
            },
            {
                name: "US500",
                digits: 2,
            },
            {
                name: "USDNOK",
                digits: 4,
            },
            {
                name: "GBPZAR",
                digits: 5,
            },
            {
                name: "CHINA50",
                digits: 2,
            },
            {
                name: "GBPDKK",
                digits: 5,
            },
            {
                name: "ADAUSD",
                digits: 5,
            },
            {
                name: "XINUSD",
                digits: 2,
            },
            {
                name: "USDX12",
                digits: 3,
            },
            {
                name: "VZ",
                digits: 2,
            },
            {
                name: "AXSUSD",
                digits: 2,
            },
            {
                name: "RBTUSD",
                digits: 5,
            },
            {
                name: "EURJPY",
                digits: 3,
            },
            {
                name: "GSK",
                digits: 4,
            },
            {
                name: "SMICHF",
                digits: 2,
            },
            {
                name: "LEAD",
                digits: 3,
            },
            {
                name: "AAPL",
                digits: 2,
            },
            {
                name: "Rio Tinto",
                digits: 4,
            },
            {
                name: "XRPJPY",
                digits: 3,
            },
            {
                name: "BRNUSD",
                digits: 3,
            },
            {
                name: "AUDHUF",
                digits: 3,
            },
            {
                name: "KSMUSD",
                digits: 2,
            },
            {
                name: "XAGUSD",
                digits: 3,
            },
            {
                name: "EURCNH",
                digits: 4,
            },
            {
                name: "USDCNH",
                digits: 5,
            },
            {
                name: "C",
                digits: 2,
            },
            {
                name: "USDCAD",
                digits: 5,
            },
            {
                name: "USO",
                digits: 2,
            },
            {
                name: "Japan Tobacco",
                digits: 0,
            },
            {
                name: "IBM",
                digits: 2,
            },
            {
                name: "GBPSGD",
                digits: 5,
            },
            {
                name: "SPXUSD",
                digits: 2,
            },
            {
                name: "USDOLLAR",
                digits: 1,
            },
            {
                name: "DGE",
                digits: 4,
            },
            {
                name: "VOO",
                digits: 2,
            },
            {
                name: "USDJPY",
                digits: 3,
            },
            {
                name: "KSMUSD",
                digits: 2,
            },
            {
                name: "Nissan",
                digits: 1,
            },
            {
                name: "EURUSD",
                digits: 5,
            },
            {
                name: "USDJPY",
                digits: 3,
            },
            {
                name: "US2000",
                digits: 2,
            },
            {
                name: "AUDNZD",
                digits: 5,
            },
            {
                name: "EURNZD",
                digits: 5,
            },
            {
                name: "GBPJPY",
                digits: 3,
            },
            {
                name: "Spain 35",
                digits: 2,
            },
            {
                name: "GBPUSD",
                digits: 5,
            },
            {
                name: "CHFJPY",
                digits: 3,
            },
            {
                name: "CADCHF",
                digits: 5,
            },
            {
                name: "AUDUSD",
                digits: 5,
            },
            {
                name: "EURGBP",
                digits: 5,
            },
            {
                name: "GBPCHF",
                digits: 5,
            },
            {
                name: "CADJPY",
                digits: 3,
            },
            {
                name: "GBPNZD",
                digits: 5,
            },
            {
                name: "USDSEK",
                digits: 5,
            },
            {
                name: "AUDCNH",
                digits: 5,
            },
            {
                name: "NZDUSD",
                digits: 5,
            },
            {
                name: "NZDCAD",
                digits: 5,
            },
            {
                name: "UKO01",
                digits: 3,
            },
            {
                name: "UKO07",
                digits: 3,
            },
            {
                name: "WTI02",
                digits: 3,
            },
            {
                name: "WTI08",
                digits: 3,
            },
            {
                name: "UKO06",
                digits: 3,
            },
            {
                name: "UKO12",
                digits: 3,
            },
            {
                name: "WTI04",
                digits: 3,
            },
            {
                name: "WTI10",
                digits: 3,
            },
            {
                name: "UK100",
                digits: 2,
            },
            {
                name: "UKO05",
                digits: 3,
            },
            {
                name: "UKO11",
                digits: 3,
            },
            {
                name: "WTI03",
                digits: 3,
            },
            {
                name: "WTI09",
                digits: 3,
            },
            {
                name: "F40",
                digits: 2,
            },
            {
                name: "JP225",
                digits: 2,
            },
            {
                name: "UNIUSD",
                digits: 4,
            },
            {
                name: "SHBUSD",
                digits: 8,
            },
            {
                name: "CADJPY",
                digits: 3,
            },
            {
                name: "US30",
                digits: 2,
            },
            {
                name: "HK50",
                digits: 2,
            },
            {
                name: "AIG",
                digits: 2,
            },
            {
                name: "MKRUSD",
                digits: 2,
            },
            {
                name: "UKBRENT",
                digits: 3,
            },
            {
                name: "SUSUSD",
                digits: 3,
            },
            {
                name: "YFIUSD",
                digits: 0,
            },
            {
                name: "Nike",
                digits: 2,
            },
            {
                name: "",
                digits: 0,
            },
            {
                name: "AUDUSD",
                digits: 5,
            },
            {
                name: "Toshiba Corporation",
                digits: 1,
            },
            {
                name: "AMZN",
                digits: 2,
            },
            {
                name: "XAGAUD",
                digits: 3,
            },
            {
                name: "MBG",
                digits: 2,
            },
            {
                name: "UKO01",
                digits: 3,
            },
            {
                name: "AAPL",
                digits: 2,
            },
            {
                name: "ETCUSD",
                digits: 3,
            },
            {
                name: "LRCUSD",
                digits: 4,
            },
            {
                name: "USDZAR",
                digits: 5,
            },
            {
                name: "ANKUSD",
                digits: 5,
            },
            {
                name: "CADMXN",
                digits: 4,
            },
            {
                name: "CSCO",
                digits: 2,
            },
            {
                name: "EURAUD",
                digits: 5,
            },
            {
                name: "KAVUSD",
                digits: 4,
            },
            {
                name: "EURCHF",
                digits: 5,
            },
            {
                name: "COIN",
                digits: 3,
            },
            {
                name: "FR40",
                digits: 2,
            },
            {
                name: "HKK",
                digits: 0,
            },
            {
                name: "DKKNOK",
                digits: 4,
            },
            {
                name: "USJ",
                digits: 0,
            },
            {
                name: "NASDAQ OMX Group",
                digits: 2,
            },
            {
                name: "LTCUSD",
                digits: 5,
            },
            {
                name: "XPDUSD",
                digits: 3,
            },
            {
                name: "SXPUSD",
                digits: 4,
            },
            {
                name: "BATUSD",
                digits: 5,
            },
            {
                name: "ASXAUD",
                digits: 1,
            },
            {
                name: "EURCZK",
                digits: 4,
            },
            {
                name: "NOKSEK",
                digits: 5,
            },
            {
                name: "EURGBP",
                digits: 5,
            },
            {
                name: "USDNOK",
                digits: 5,
            },
            {
                name: "Procter & Gamble Company",
                digits: 2,
            },
            {
                name: "AUDCHF",
                digits: 5,
            },
            {
                name: "EOSUSD",
                digits: 4,
            },
            {
                name: "NZDCAD",
                digits: 5,
            },
            {
                name: "TRYJPY",
                digits: 3,
            },
            {
                name: "WOOUSD",
                digits: 5,
            },
            {
                name: "AUDJPY",
                digits: 3,
            },
            {
                name: "GBPNZD",
                digits: 5,
            },
            {
                name: "CRVUSD",
                digits: 3,
            },
            {
                name: "US500",
                digits: 2,
            },
            {
                name: "USDHKD",
                digits: 5,
            },
            {
                name: "AT&T",
                digits: 2,
            },
            {
                name: "NZDJPY",
                digits: 3,
            },
            {
                name: "HSIHKD",
                digits: 1,
            },
            {
                name: "GBPPLN",
                digits: 5,
            },
            {
                name: "USJZ",
                digits: 0,
            },
            {
                name: "ALUMINIUM",
                digits: 3,
            },
            {
                name: "DJ30",
                digits: 2,
            },
            {
                name: "GBPCHF",
                digits: 5,
            },
            {
                name: "GBPJPY",
                digits: 3,
            },
            {
                name: "F40EUR",
                digits: 2,
            },
            {
                name: "AUDCNH",
                digits: 5,
            },
            {
                name: "USDTRY",
                digits: 5,
            },
            {
                name: "QQQ",
                digits: 2,
            },
            {
                name: "GBPUSD",
                digits: 5,
            },
            {
                name: "MTCUSD",
                digits: 5,
            },
            {
                name: "Keyence",
                digits: 0,
            },
            {
                name: "NZDUSD",
                digits: 5,
            },
            {
                name: "United Continental Holdings",
                digits: 2,
            },
            {
                name: "VIXIndex",
                digits: 3,
            },
            {
                name: "NZDJPY",
                digits: 3,
            },
            {
                name: "EU50",
                digits: 2,
            },
            {
                name: "NIKZ",
                digits: 0,
            },
            {
                name: "RENUSD",
                digits: 4,
            },
            {
                name: "CK Hutchison Holdings",
                digits: 2,
            },
            {
                name: "UnitedHealth Group",
                digits: 2,
            },
            {
                name: "CLG",
                digits: 2,
            },
            {
                name: "CLK",
                digits: 2,
            },
            {
                name: "BASF",
                digits: 3,
            },
            {
                name: "EURHKD",
                digits: 5,
            },
            {
                name: "ES35",
                digits: 1,
            },
            {
                name: "USDCHF",
                digits: 5,
            },
            {
                name: "DTE",
                digits: 3,
            },
            {
                name: "CELUSD",
                digits: 5,
            },
            {
                name: "Sony",
                digits: 0,
            },
            {
                name: "RENUSD",
                digits: 4,
            },
            {
                name: "CLF",
                digits: 2,
            },
            {
                name: "USDCAD",
                digits: 5,
            },
            {
                name: "NETH25",
                digits: 2,
            },
            {
                name: "UAL",
                digits: 2,
            },
            {
                name: "##FB",
                digits: 2,
            },
            {
                name: "CLSK",
                digits: 2,
            },
            {
                name: "Microsoft",
                digits: 2,
            },
            {
                name: "PEPUSD",
                digits: 5,
            },
            {
                name: "AUDDKK",
                digits: 5,
            },
            {
                name: "GMTUSD",
                digits: 4,
            },
            {
                name: "DOGUSD",
                digits: 4,
            },
            {
                name: "KDDI",
                digits: 1,
            },
            {
                name: "BTGUSD",
                digits: 2,
            },
            {
                name: "USDCNH",
                digits: 5,
            },
            {
                name: "CHRUSD",
                digits: 4,
            },
            {
                name: "Wells Fargo & Company",
                digits: 2,
            },
            {
                name: "NVDA",
                digits: 2,
            },
            {
                name: "OMGUSD",
                digits: 3,
            },
            {
                name: "LNKUSD",
                digits: 3,
            },
            {
                name: "EURJPY",
                digits: 3,
            },
            {
                name: "COTUSD",
                digits: 5,
            },
            {
                name: "AUDUSD",
                digits: 5,
            },
            {
                name: "NZDCAD",
                digits: 5,
            },
            {
                name: "EURPLN",
                digits: 5,
            },
            {
                name: "Workhorse Group",
                digits: 2,
            },
            {
                name: "OR",
                digits: 2,
            },
            {
                name: "SP500",
                digits: 2,
            },
            {
                name: "Amazon",
                digits: 2,
            },
            {
                name: "HSIJ",
                digits: 0,
            },
            {
                name: "COTUSD",
                digits: 5,
            },
            {
                name: "Allianz",
                digits: 3,
            },
            {
                name: "EURNZD",
                digits: 5,
            },
            {
                name: "Mattel Inc",
                digits: 2,
            },
            {
                name: "EU50",
                digits: 2,
            },
            {
                name: "CHFJPY",
                digits: 3,
            },
            {
                name: "HK50",
                digits: 2,
            },
            {
                name: "EURCHF",
                digits: 5,
            },
            {
                name: "ANKUSD",
                digits: 5,
            },
            {
                name: "HSIX",
                digits: 0,
            },
            {
                name: "NAS100",
                digits: 2,
            },
            {
                name: "GBPAUD",
                digits: 5,
            },
            {
                name: "CTSUSD",
                digits: 4,
            },
            {
                name: "Netflix",
                digits: 2,
            },
            {
                name: "HSIM",
                digits: 0,
            },
            {
                name: "USDHUF",
                digits: 3,
            },
            {
                name: "CHINAH",
                digits: 2,
            },
            {
                name: "##AAPL",
                digits: 2,
            },
            {
                name: "XMRUSD",
                digits: 2,
            },
            {
                name: "EURCZK",
                digits: 4,
            },
            {
                name: "JPN225USD",
                digits: 2,
            },
            {
                name: "EURDKK",
                digits: 5,
            },
            {
                name: "NZDUSD",
                digits: 5,
            },
            {
                name: "HK50",
                digits: 2,
            },
            {
                name: "TRXUSD",
                digits: 5,
            },
            {
                name: "EURMXN",
                digits: 5,
            },
            {
                name: "USDCNH",
                digits: 5,
            },
            {
                name: "GBPSEK",
                digits: 5,
            },
            {
                name: "HSIV",
                digits: 0,
            },
            {
                name: "ETHUSD",
                digits: 2,
            },
            {
                name: "GLEN",
                digits: 4,
            },
            {
                name: "GBPCHF",
                digits: 5,
            },
            {
                name: "LHA",
                digits: 3,
            },
            {
                name: "USDIndex",
                digits: 3,
            },
            {
                name: "AUDSEK",
                digits: 5,
            },
            {
                name: "UKOUSDeq",
                digits: 3,
            },
            {
                name: "USDCAD",
                digits: 5,
            },
            {
                name: "AUDSGD",
                digits: 5,
            },
            {
                name: "CADSEK",
                digits: 5,
            },
            {
                name: "CADSGD",
                digits: 5,
            },
            {
                name: "GBPNZD",
                digits: 5,
            },
            {
                name: "##SBUX",
                digits: 2,
            },
            {
                name: "NIKH",
                digits: 0,
            },
            {
                name: "XAGSGD",
                digits: 3,
            },
            {
                name: "DOGUSD",
                digits: 5,
            },
            {
                name: "HKDJPY",
                digits: 3,
            },
            {
                name: "ULVR",
                digits: 4,
            },
            {
                name: "CHFJPYecn",
                digits: 3,
            },
            {
                name: "USDCHF",
                digits: 5,
            },
            {
                name: "AUDCHF",
                digits: 5,
            },
            {
                name: "Seven&I",
                digits: 0,
            },
            {
                name: "ShenhuaEnrg",
                digits: 2,
            },
            {
                name: "NDX100",
                digits: 2,
            },
            {
                name: "BCHUSD",
                digits: 3,
            },
            {
                name: "TRYJPY",
                digits: 3,
            },
            {
                name: "CLG",
                digits: 2,
            },
            {
                name: "CADCHF",
                digits: 5,
            },
            {
                name: "XRPUSD",
                digits: 5,
            },
            {
                name: "APEUSD",
                digits: 3,
            },
            {
                name: "USDHKD",
                digits: 5,
            },
            {
                name: "USOILyy",
                digits: 3,
            },
            {
                name: "GBPNZD",
                digits: 5,
            },
            {
                name: "EURUSD",
                digits: 5,
            },
            {
                name: "XAUUSD (Gold)",
                digits: 2,
            },
            {
                name: "DE40",
                digits: 2,
            },
            {
                name: "NZDCHF",
                digits: 5,
            },
            {
                name: "HD",
                digits: 2,
            },
            {
                name: "CLJ",
                digits: 2,
            },
            {
                name: "US Oil (WTI)",
                digits: 3,
            },
            {
                name: "BTCUSD (Bitcoin)",
                digits: 3,
            },
            {
                name: "XAGEUR",
                digits: 3,
            },
            {
                name: "XAUTHB",
                digits: 2,
            },
            {
                name: "NZDCAD",
                digits: 5,
            },
            {
                name: "NEOUSD",
                digits: 2,
            },
            {
                name: "USDCAD",
                digits: 5,
            },
            {
                name: "FRA40",
                digits: 2,
            },
            {
                name: "EURPLN",
                digits: 5,
            },
            {
                name: "Canon",
                digits: 1,
            },
            {
                name: "Natural Gas",
                digits: 4,
            },
            {
                name: "GER40",
                digits: 0,
            },
            {
                name: "ChinaMobile",
                digits: 2,
            },
            {
                name: "EURDKK",
                digits: 5,
            },
            {
                name: "XAUCNH",
                digits: 2,
            },
            {
                name: "BNBUSD",
                digits: 3,
            },
            {
                name: "GBPCAD",
                digits: 5,
            },
            {
                name: "Germany 40",
                digits: 2,
            },
            {
                name: "EURO 50",
                digits: 2,
            },
            {
                name: "##MSFT",
                digits: 2,
            },
            {
                name: "EURGBP",
                digits: 5,
            },
            {
                name: "JNJ",
                digits: 2,
            },
            {
                name: "USWTI",
                digits: 3,
            },
            {
                name: "NOVN",
                digits: 2,
            },
            {
                name: "EOSUSD",
                digits: 4,
            },
            {
                name: "AVAUSD",
                digits: 2,
            },
            {
                name: "AUDUSD",
                digits: 5,
            },
            {
                name: "EURTRY",
                digits: 5,
            },
            {
                name: "DOTUSD",
                digits: 3,
            },
            {
                name: "iShares Russell 2000",
                digits: 2,
            },
            {
                name: "American Airlines Group",
                digits: 2,
            },
            {
                name: "XAUGBP",
                digits: 2,
            },
            {
                name: "EURUSD",
                digits: 5,
            },
            {
                name: "VECUSD",
                digits: 4,
            },
            {
                name: "CITIC Securities",
                digits: 2,
            },
            {
                name: "AVAUSD",
                digits: 2,
            },
            {
                name: "MKRUSD",
                digits: 2,
            },
            {
                name: "ATMUSD",
                digits: 3,
            },
            {
                name: "USDNOK",
                digits: 5,
            },
            {
                name: "Vanguard FTSE DM",
                digits: 2,
            },
            {
                name: "United States Oil Fund",
                digits: 2,
            },
            {
                name: "Airbus Group",
                digits: 3,
            },
            {
                name: "AXSUSD",
                digits: 2,
            },
            {
                name: "USDPLN",
                digits: 5,
            },
            {
                name: "CNOOC",
                digits: 2,
            },
            {
                name: "Henderson Land",
                digits: 2,
            },
            {
                name: "GBPJPY",
                digits: 3,
            },
            {
                name: "JPM",
                digits: 2,
            },
            {
                name: "Australia 200",
                digits: 1,
            },
            {
                name: "APEUSD",
                digits: 3,
            },
            {
                name: "NZDJPY",
                digits: 3,
            },
            {
                name: "BASF",
                digits: 3,
            },
            {
                name: "XMRUSD",
                digits: 3,
            },
            {
                name: "XLMUSD",
                digits: 5,
            },
            {
                name: "China 50",
                digits: 2,
            },
            {
                name: "VIX01",
                digits: 3,
            },
            {
                name: "SOLUSD",
                digits: 3,
            },
            {
                name: "SANUSD",
                digits: 4,
            },
            {
                name: "AUDUSD",
                digits: 5,
            },
            {
                name: "ADAUSD",
                digits: 5,
            },
            {
                name: "XEMUSD",
                digits: 4,
            },
            {
                name: "XAGUSD",
                digits: 3,
            },
            {
                name: "MA",
                digits: 2,
            },
            {
                name: "GBPUSD",
                digits: 5,
            },
            {
                name: "XRPUSD",
                digits: 5,
            },
            {
                name: "MTCUSD",
                digits: 5,
            },
            {
                name: "DOGUSD",
                digits: 5,
            },
            {
                name: "AAVUSD",
                digits: 2,
            },
            {
                name: "NZDUSD",
                digits: 5,
            },
            {
                name: "HSBC Holdings",
                digits: 2,
            },
            {
                name: "FILUSD",
                digits: 2,
            },
            {
                name: "AUDJPY",
                digits: 3,
            },
            {
                name: "Bank of China",
                digits: 2,
            },
            {
                name: "XAUEUR",
                digits: 2,
            },
            {
                name: "UK 100",
                digits: 2,
            },
            {
                name: "SKLUSD",
                digits: 5,
            },
            {
                name: "USDCZK",
                digits: 4,
            },
            {
                name: "USDNOK",
                digits: 5,
            },
            {
                name: "AXSUSD",
                digits: 2,
            },
            {
                name: "GBPNZD",
                digits: 5,
            },
            {
                name: "CNHJPY",
                digits: 3,
            },
            {
                name: "CELUSD",
                digits: 5,
            },
            {
                name: "STOXX50",
                digits: 2,
            },
            {
                name: "Mitsubishi UFJ Financial Group",
                digits: 1,
            },
            {
                name: "Citigroup",
                digits: 2,
            },
            {
                name: "NDAQ",
                digits: 2,
            },
            {
                name: "Bayer",
                digits: 3,
            },
            {
                name: "GBPJPY",
                digits: 3,
            },
            {
                name: "GALUSD",
                digits: 5,
            },
            {
                name: "GBPAUD",
                digits: 5,
            },
            {
                name: "XAUSGD",
                digits: 2,
            },
            {
                name: "GBPNZD",
                digits: 5,
            },
            {
                name: "BNP Paribas",
                digits: 3,
            },
            {
                name: "XALUSD",
                digits: 2,
            },
            {
                name: "TSLA",
                digits: 3,
            },
            {
                name: "Meta Platforms",
                digits: 2,
            },
            {
                name: "USDCNH",
                digits: 5,
            },
            {
                name: "SGDJPY",
                digits: 3,
            },
            {
                name: "NERUSD",
                digits: 3,
            },
            {
                name: "EURGBP",
                digits: 5,
            },
            {
                name: "ZRXUSD",
                digits: 4,
            },
            {
                name: "NIKU",
                digits: 0,
            },
            {
                name: "ESXEUR",
                digits: 2,
            },
            {
                name: "Mitsui & Co",
                digits: 1,
            },
            {
                name: "AUDZAR",
                digits: 5,
            },
            {
                name: "V",
                digits: 2,
            },
            {
                name: "CRVUSD",
                digits: 3,
            },
            {
                name: "AUDNZD",
                digits: 5,
            },
            {
                name: "XAGUSD",
                digits: 3,
            },
            {
                name: "NVDA",
                digits: 3,
            },
            {
                name: "EURCHF",
                digits: 5,
            },
            {
                name: "AMZN",
                digits: 3,
            },
            {
                name: "WFC",
                digits: 2,
            },
            {
                name: "COMUSD",
                digits: 2,
            },
            {
                name: "Industrial and Commercial Bank of China",
                digits: 2,
            },
            {
                name: "Mizuho Financial Group",
                digits: 1,
            },
            {
                name: "HPQ",
                digits: 2,
            },
            {
                name: "GER30",
                digits: 2,
            },
            {
                name: "ARWUSD",
                digits: 3,
            },
            {
                name: "CHFNOK",
                digits: 5,
            },
            {
                name: "NZDCAD",
                digits: 5,
            },
            {
                name: "BABA",
                digits: 2,
            },
            {
                name: "WMT",
                digits: 2,
            },
            {
                name: "EGLUSD",
                digits: 2,
            },
            {
                name: "META",
                digits: 3,
            },
            {
                name: "AUDSGD",
                digits: 5,
            },
            {
                name: "CADCHF",
                digits: 5,
            },
            {
                name: "SHOP",
                digits: 3,
            },
            {
                name: "ARWUSD",
                digits: 3,
            },
            {
                name: "WTI04",
                digits: 3,
            },
            {
                name: "AUDJPY",
                digits: 3,
            },
            {
                name: "CHFSGD",
                digits: 5,
            },
            {
                name: "EURHKD",
                digits: 5,
            },
            {
                name: "USDCAD",
                digits: 5,
            },
            {
                name: "MAT",
                digits: 2,
            },
            {
                name: "PICC Property and Casualty",
                digits: 2,
            },
            {
                name: "CHFZAR",
                digits: 5,
            },
            {
                name: "MSFT",
                digits: 3,
            },
            {
                name: "Seven & i Holdings Co",
                digits: 0,
            },
            {
                name: "HSIK",
                digits: 0,
            },
            {
                name: "NZDCNH",
                digits: 5,
            },
            {
                name: "VECUSD",
                digits: 4,
            },
            {
                name: "US 100 (Nasdaq)",
                digits: 2,
            },
            {
                name: "GBPNOK",
                digits: 5,
            },
            {
                name: "JPM",
                digits: 2,
            },
            {
                name: "Diageo",
                digits: 4,
            },
            {
                name: "FNKO",
                digits: 2,
            },
            {
                name: "CNEUSD",
                digits: 4,
            },
            {
                name: "TKAT",
                digits: 2,
            },
            {
                name: "EURPLN",
                digits: 5,
            },
            {
                name: "AXA",
                digits: 3,
            },
            {
                name: "NICKEL",
                digits: 3,
            },
            {
                name: "USDHUF",
                digits: 3,
            },
            {
                name: "NIKM",
                digits: 0,
            },
            {
                name: "EURUSD",
                digits: 5,
            },
            {
                name: "USDCHF",
                digits: 5,
            },
            {
                name: "CHFJPY",
                digits: 3,
            },
            {
                name: "CADJPY",
                digits: 3,
            },
            {
                name: "USDHUF",
                digits: 3,
            },
            {
                name: "BAS",
                digits: 3,
            },
            {
                name: "CADMXN",
                digits: 5,
            },
            {
                name: "IWM",
                digits: 2,
            },
            {
                name: "China Shenhua Energy",
                digits: 2,
            },
            {
                name: "BN",
                digits: 2,
            },
            {
                name: "Softbank",
                digits: 0,
            },
            {
                name: "LPTUSD",
                digits: 3,
            },
            {
                name: "USDIDR",
                digits: 0,
            },
            {
                name: "EURCNH",
                digits: 5,
            },
            {
                name: "SCHD",
                digits: 2,
            },
            {
                name: "BNP",
                digits: 3,
            },
            {
                name: "YMU",
                digits: 0,
            },
            {
                name: "EURAUD",
                digits: 5,
            },
            {
                name: "EURILS",
                digits: 5,
            },
            {
                name: "AMGN",
                digits: 3,
            },
            {
                name: "MAR",
                digits: 2,
            },
            {
                name: "NFLX",
                digits: 2,
            },
            {
                name: "USDIDRb",
                digits: 0,
            },
            {
                name: "BCHUSD",
                digits: 3,
            },
            {
                name: "Sony",
                digits: 0,
            },
            {
                name: "SUSUSD",
                digits: 3,
            },
            {
                name: "AUDJPY",
                digits: 3,
            },
            {
                name: "CLZ",
                digits: 2,
            },
            {
                name: "EURSGD",
                digits: 5,
            },
            {
                name: "PLBY",
                digits: 2,
            },
            {
                name: "XAGUSD",
                digits: 3,
            },
            {
                name: "CTSUSD",
                digits: 4,
            },
            {
                name: "ZENUSD",
                digits: 3,
            },
            {
                name: "GBPUSD",
                digits: 5,
            },
            {
                name: "FedEx Corporation",
                digits: 2,
            },
            {
                name: "CRVUSD",
                digits: 3,
            },
            {
                name: "HSIG",
                digits: 0,
            },
            {
                name: "SHK Ppt",
                digits: 1,
            },
            {
                name: "BTCJPY",
                digits: 1,
            },
            {
                name: "USDCHF",
                digits: 5,
            },
            {
                name: "Deutsche Telekom",
                digits: 3,
            },
            {
                name: "HSIN",
                digits: 0,
            },
            {
                name: "USDSEK",
                digits: 5,
            },
            {
                name: "DYDUSD",
                digits: 3,
            },
            {
                name: "EURHKD",
                digits: 5,
            },
            {
                name: "USDJPY",
                digits: 3,
            },
            {
                name: "USDSGD",
                digits: 5,
            },
            {
                name: "NESN",
                digits: 2,
            },
            {
                name: "USDCHFecn",
                digits: 5,
            },
            {
                name: "Goldman Sachs Group Inc",
                digits: 2,
            },
            {
                name: "Nestle",
                digits: 2,
            },
            {
                name: "ETHUST",
                digits: 2,
            },
            {
                name: "UKO06",
                digits: 3,
            },
            {
                name: "Intel",
                digits: 2,
            },
            {
                name: "SHEL",
                digits: 3,
            },
            {
                name: "XNIUSD",
                digits: 2,
            },
            {
                name: "Home Depot",
                digits: 2,
            },
            {
                name: "HSIK",
                digits: 0,
            },
            {
                name: "AUDNZD",
                digits: 5,
            },
            {
                name: "RIO",
                digits: 4,
            },
            {
                name: "AUDHKD",
                digits: 5,
            },
            {
                name: "LVMH Moet Hennessy Louis Vuitton",
                digits: 2,
            },
            {
                name: "AUDCADecn",
                digits: 5,
            },
            {
                name: "VOW3",
                digits: 3,
            },
            {
                name: "GBPNZD",
                digits: 5,
            },
            {
                name: "NZDHUF",
                digits: 3,
            },
            {
                name: "FLWUSD",
                digits: 3,
            },
            {
                name: "Marriott International",
                digits: 2,
            },
            {
                name: "CADSGD",
                digits: 5,
            },
            {
                name: "EURNZD",
                digits: 5,
            },
            {
                name: "CEOUSD",
                digits: 3,
            },
            {
                name: "SGDHKD",
                digits: 5,
            },
            {
                name: "EURSGD",
                digits: 5,
            },
            {
                name: "CHFSGD",
                digits: 5,
            },
            {
                name: "AUDNOK",
                digits: 4,
            },
            {
                name: "CADJPY",
                digits: 3,
            },
            {
                name: "SGDJPY",
                digits: 3,
            },
            {
                name: "XAUUSD",
                digits: 2,
            },
            {
                name: "GBPSGD",
                digits: 5,
            },
            {
                name: "EURZAR",
                digits: 5,
            },
            {
                name: "Tencent Holdings",
                digits: 1,
            },
            {
                name: "AUS200",
                digits: 2,
            },
            {
                name: "A",
                digits: 2,
            },
            {
                name: "USDIRP",
                digits: 0,
            },
            {
                name: "USDNOKs",
                digits: 5,
            },
            {
                name: "ZARJPY",
                digits: 5,
            },
            {
                name: "Toshiba Corporation",
                digits: 1,
            },
            {
                name: "SIE",
                digits: 3,
            },
            {
                name: "ChinaMobile",
                digits: 2,
            },
            {
                name: "Xiaomi",
                digits: 2,
            },
            {
                name: "UK100",
                digits: 2,
            },
            {
                name: "ZILUSD",
                digits: 5,
            },
            {
                name: "US30",
                digits: 2,
            },
            {
                name: "UKOILyy",
                digits: 3,
            },
            {
                name: "Mastercard",
                digits: 2,
            },
            {
                name: "CADNOK",
                digits: 5,
            },
            {
                name: "Canon",
                digits: 1,
            },
            {
                name: "USDX06",
                digits: 3,
            },
            {
                name: "JpnTobacco",
                digits: 0,
            },
            {
                name: "USDCHF",
                digits: 5,
            },
            {
                name: "GBPAUD",
                digits: 5,
            },
            {
                name: "USOUSD",
                digits: 3,
            },
            {
                name: "XAGUSDecn",
                digits: 3,
            },
            {
                name: "Glencore",
                digits: 4,
            },
            {
                name: "EURCNH",
                digits: 5,
            },
            {
                name: "Unilever",
                digits: 4,
            },
            {
                name: "ONEUSD",
                digits: 5,
            },
            {
                name: "CKHutchison",
                digits: 2,
            },
            {
                name: "ENJUSD",
                digits: 4,
            },
            {
                name: "Toyota Motor Corp",
                digits: 0,
            },
            {
                name: "TRXUSD",
                digits: 5,
            },
            {
                name: "EGLUSD",
                digits: 2,
            },
            {
                name: "NZDDKK",
                digits: 5,
            },
            {
                name: "EURCAD",
                digits: 5,
            },
            {
                name: "LRCUSD",
                digits: 4,
            },
            {
                name: "Merck & Company",
                digits: 2,
            },
            {
                name: "XNGUSDecn",
                digits: 4,
            },
            {
                name: "ES35",
                digits: 1,
            },
            {
                name: "VIX05",
                digits: 3,
            },
            {
                name: "PEP",
                digits: 2,
            },
            {
                name: "F",
                digits: 2,
            },
            {
                name: "KAVUSD",
                digits: 4,
            },
            {
                name: "HK50",
                digits: 2,
            },
            {
                name: "GlaxoSmithKline",
                digits: 4,
            },
            {
                name: "AUDCAD",
                digits: 5,
            },
            {
                name: "DSHUSD",
                digits: 2,
            },
            {
                name: "USDCNH",
                digits: 5,
            },
            {
                name: "META",
                digits: 2,
            },
            {
                name: "JNJ",
                digits: 2,
            },
            {
                name: "INCUSD",
                digits: 4,
            },
            {
                name: "Hyatt Hotels Corporation",
                digits: 2,
            },
            {
                name: "NASDAQ OMX Group",
                digits: 2,
            },
            {
                name: "GBPCAD",
                digits: 5,
            },
            {
                name: "EURHUF",
                digits: 3,
            },
            {
                name: "EURCAD",
                digits: 5,
            },
            {
                name: "VEA",
                digits: 2,
            },
            {
                name: "Iberdrola",
                digits: 3,
            },
            {
                name: "XTZUSD",
                digits: 3,
            },
            {
                name: "CAC40",
                digits: 2,
            },
            {
                name: "BCHUST",
                digits: 2,
            },
            {
                name: "CS",
                digits: 3,
            },
            {
                name: "ROG",
                digits: 1,
            },
            {
                name: "VIX10",
                digits: 3,
            },
            {
                name: "BARUSD",
                digits: 5,
            },
            {
                name: "NZDSGD",
                digits: 5,
            },
            {
                name: "NZDJPY",
                digits: 3,
            },
            {
                name: "ChinaA50",
                digits: 1,
            },
            {
                name: "NZDHUF",
                digits: 3,
            },
            {
                name: "NZDUSD",
                digits: 5,
            },
            {
                name: "Pepsico",
                digits: 2,
            },
            {
                name: "AUDNZD",
                digits: 5,
            },
            {
                name: "USDNOK",
                digits: 4,
            },
            {
                name: "MANUSD",
                digits: 4,
            },
            {
                name: "SAP",
                digits: 3,
            },
            {
                name: "Tesla",
                digits: 2,
            },
            {
                name: "EURJPY",
                digits: 3,
            },
            {
                name: "GBPNOK",
                digits: 4,
            },
            {
                name: "CHFJPY",
                digits: 3,
            },
            {
                name: "GBPJPY",
                digits: 3,
            },
            {
                name: "USDHKD",
                digits: 5,
            },
            {
                name: "UKO03",
                digits: 3,
            },
            {
                name: "TETUSD",
                digits: 3,
            },
            {
                name: "HKDJPY",
                digits: 3,
            },
            {
                name: "Exxon Mobil Corporation",
                digits: 2,
            },
            {
                name: "AUDUSDeq",
                digits: 5,
            },
            {
                name: "WTI02",
                digits: 3,
            },
            {
                name: "EURUSD",
                digits: 5,
            },
            {
                name: "EURMXN",
                digits: 5,
            },
            {
                name: "GBPSEK",
                digits: 5,
            },
            {
                name: "Nvidia",
                digits: 2,
            },
            {
                name: "EURDKK",
                digits: 5,
            },
            {
                name: "GBPSEK",
                digits: 5,
            },
            {
                name: "UKOUSD",
                digits: 3,
            },
            {
                name: "XAUUSD (Gold)",
                digits: 2,
            },
            {
                name: "HSIZ",
                digits: 0,
            },
            {
                name: "GBPCAD",
                digits: 5,
            },
            {
                name: "NZDSGD",
                digits: 5,
            },
            {
                name: "Dolphin Entertainment Inc",
                digits: 2,
            },
            {
                name: "CADDKK",
                digits: 5,
            },
            {
                name: "AUDUSD",
                digits: 5,
            },
            {
                name: "UKOILxx",
                digits: 3,
            },
            {
                name: "HSBC",
                digits: 2,
            },
            {
                name: "USDTHB",
                digits: 4,
            },
            {
                name: "BTCUSD (Bitcoin)",
                digits: 3,
            },
            {
                name: "XTIUSD",
                digits: 3,
            },
            {
                name: "USDJPYeq",
                digits: 3,
            },
            {
                name: "ZARJPY",
                digits: 3,
            },
            {
                name: "EURGBP",
                digits: 5,
            },
            {
                name: "UKO04",
                digits: 3,
            },
            {
                name: "XAUAUD",
                digits: 2,
            },
            {
                name: "SPX (S&P 500)",
                digits: 2,
            },
            {
                name: "USDX03",
                digits: 3,
            },
            {
                name: "Ind&ComBank",
                digits: 2,
            },
            {
                name: "CHFDKK",
                digits: 5,
            },
            {
                name: "CHFPLN",
                digits: 5,
            },
            {
                name: "USDCHF",
                digits: 5,
            },
            {
                name: "Adidas",
                digits: 3,
            },
            {
                name: "UKO05",
                digits: 3,
            },
            {
                name: "AUDSGD",
                digits: 5,
            },
            {
                name: "USDSEK",
                digits: 5,
            },
            {
                name: "XAGUSDeq",
                digits: 3,
            },
            {
                name: "EURSEK",
                digits: 5,
            },
            {
                name: "UKO06",
                digits: 3,
            },
            {
                name: "BCHUSD",
                digits: 3,
            },
            {
                name: "GBPCAD",
                digits: 5,
            },
            {
                name: "ING Group",
                digits: 2,
            },
            {
                name: "EURCNH",
                digits: 5,
            },
            {
                name: "USDX12",
                digits: 3,
            },
            {
                name: "DKKNOK",
                digits: 5,
            },
            {
                name: "EURCHF",
                digits: 5,
            },
            {
                name: "ETHUSTecn",
                digits: 2,
            },
            {
                name: "GBPTRY",
                digits: 5,
            },
            {
                name: "Makita",
                digits: 0,
            },
            {
                name: "FTMUSD",
                digits: 4,
            },
            {
                name: "XRPUSD",
                digits: 5,
            },
            {
                name: "LTCUSD",
                digits: 5,
            },
            {
                name: "BTCUST",
                digits: 2,
            },
            {
                name: "SHK",
                digits: 1,
            },
            {
                name: "BTCUSTecn",
                digits: 2,
            },
            {
                name: "NOKJPY",
                digits: 3,
            },
            {
                name: "NEOUSD",
                digits: 2,
            },
            {
                name: "Novartis",
                digits: 2,
            },
            {
                name: "NZDSEK",
                digits: 5,
            },
            {
                name: "GMTUSD",
                digits: 4,
            },
            {
                name: "AI",
                digits: 2,
            },
            {
                name: "UKO08",
                digits: 3,
            },
            {
                name: "USDMXN",
                digits: 4,
            },
            {
                name: "Johnson & Johnson",
                digits: 2,
            },
            {
                name: "200AUDeq",
                digits: 2,
            },
            {
                name: "BCHUSTecn",
                digits: 2,
            },
            {
                name: "Canon",
                digits: 1,
            },
            {
                name: "GBPCZK",
                digits: 4,
            },
            {
                name: "XBRUSD",
                digits: 3,
            },
            {
                name: "Sumitomo",
                digits: 0,
            },
            {
                name: "EURCAD",
                digits: 5,
            },
            {
                name: "USOUSDeq",
                digits: 3,
            },
            {
                name: "JPMorgan Chase & Co",
                digits: 2,
            },
            {
                name: "GBPHKD",
                digits: 5,
            },
            {
                name: "CITIC",
                digits: 2,
            },
            {
                name: "GBPHUF",
                digits: 5,
            },
            {
                name: "AIA Group",
                digits: 2,
            },
            {
                name: "GBPCNH",
                digits: 5,
            },
            {
                name: "NZDUSDecn",
                digits: 5,
            },
            {
                name: "GBPMXN",
                digits: 4,
            },
            {
                name: "USOILxx",
                digits: 3,
            },
            {
                name: "USDCNH",
                digits: 5,
            },
            {
                name: "Tencent",
                digits: 1,
            },
            {
                name: "XAGUSD (Silver)",
                digits: 3,
            },
            {
                name: "Shell PLC",
                digits: 3,
            },
            {
                name: "UKOUSD",
                digits: 3,
            },
            {
                name: "NASUSD",
                digits: 2,
            },
            {
                name: "EURAUD",
                digits: 5,
            },
            {
                name: "USDCAD",
                digits: 5,
            },
            {
                name: "France 40",
                digits: 2,
            },
            {
                name: "UKO02",
                digits: 3,
            },
            {
                name: "USDCNH",
                digits: 4,
            },
            {
                name: "GRTUSD",
                digits: 5,
            },
            {
                name: "CADCHF",
                digits: 5,
            },
            {
                name: "BNBUSD",
                digits: 3,
            },
            {
                name: "EURUSDecn",
                digits: 5,
            },
            {
                name: "AAL",
                digits: 2,
            },
            {
                name: "BATUSD",
                digits: 5,
            },
            {
                name: "HSIZ",
                digits: 0,
            },
            {
                name: "DOTUSD",
                digits: 3,
            },
            {
                name: "Honda",
                digits: 1,
            },
            {
                name: "Vanguard S&P500",
                digits: 2,
            },
            {
                name: "SAP",
                digits: 3,
            },
            {
                name: "IBXEUR",
                digits: 2,
            },
            {
                name: "AF",
                digits: 3,
            },
            {
                name: "EURGBPeq",
                digits: 5,
            },
            {
                name: "NIKH",
                digits: 0,
            },
            {
                name: "Honda Motor Company",
                digits: 1,
            },
            {
                name: "Roche Holding",
                digits: 1,
            },
            {
                name: "NIKM",
                digits: 0,
            },
            {
                name: "NOKJPY",
                digits: 3,
            },
            {
                name: "McDonald's",
                digits: 2,
            },
            {
                name: "ChinaMobile",
                digits: 2,
            },
            {
                name: "EURCHFeq",
                digits: 5,
            },
            {
                name: "EURJPY",
                digits: 3,
            },
            {
                name: "BTCUSD",
                digits: 3,
            },
            {
                name: "SPX500",
                digits: 2,
            },
            {
                name: "Siemens",
                digits: 3,
            },
            {
                name: "XPDUSD (Palladium)",
                digits: 2,
            },
            {
                name: "UKO10",
                digits: 3,
            },
            {
                name: "USOUSD",
                digits: 3,
            },
            {
                name: "EOSUSD",
                digits: 4,
            },
            {
                name: "USDCADeq",
                digits: 5,
            },
            {
                name: "NZDCAD",
                digits: 5,
            },
            {
                name: "Air Liquide",
                digits: 2,
            },
            {
                name: "CADCHF",
                digits: 5,
            },
            {
                name: "American Airlines Group",
                digits: 2,
            },
            {
                name: "T",
                digits: 2,
            },
            {
                name: "PEP",
                digits: 2,
            },
            {
                name: "XLMUSD",
                digits: 5,
            },
            {
                name: "XMRUSD",
                digits: 3,
            },
            {
                name: "gold",
                digits: 2,
            },
            {
                name: "Spain 35",
                digits: 2,
            },
            {
                name: "AUDCAD",
                digits: 5,
            },
            {
                name: "CHZUSD",
                digits: 5,
            },
            {
                name: "UNIUSD",
                digits: 4,
            },
            {
                name: "USDCNHecn",
                digits: 5,
            },
            {
                name: "NVDA",
                digits: 3,
            },
            {
                name: "Japan Tobacco",
                digits: 0,
            },
            {
                name: "USDX09",
                digits: 3,
            },
            {
                name: "EURUSDeq",
                digits: 5,
            },
            {
                name: "ZARJPY",
                digits: 3,
            },
            {
                name: "EURSEK",
                digits: 5,
            },
            {
                name: "3M Company",
                digits: 2,
            },
            {
                name: "ZECUSD",
                digits: 3,
            },
            {
                name: "AUDCADeq",
                digits: 5,
            },
            {
                name: "UNIUSD",
                digits: 4,
            },
            {
                name: "NZDCHF",
                digits: 5,
            },
            {
                name: "GBPUSDeq",
                digits: 5,
            },
            {
                name: "USDX",
                digits: 2,
            },
            {
                name: "Vodafone Group",
                digits: 4,
            },
            {
                name: "Allianz",
                digits: 3,
            },
            {
                name: "Amazon",
                digits: 2,
            },
            {
                name: "EURGBP",
                digits: 5,
            },
            {
                name: "NIKJPY",
                digits: 0,
            },
            {
                name: "JNJ",
                digits: 2,
            },
            {
                name: "EURAUDeq",
                digits: 5,
            },
            {
                name: "XNGUSD",
                digits: 4,
            },
            {
                name: "silver",
                digits: 3,
            },
            {
                name: "Microsoft Corporation",
                digits: 2,
            },
            {
                name: "CNOOC",
                digits: 2,
            },
            {
                name: "COPPER",
                digits: 3,
            },
            {
                name: "MANU",
                digits: 3,
            },
            {
                name: "AUDCHF",
                digits: 5,
            },
            {
                name: "ESP35",
                digits: 2,
            },
            {
                name: "Volkswagen",
                digits: 3,
            },
            {
                name: "TSLA",
                digits: 3,
            },
            {
                name: "AUDNZD",
                digits: 5,
            },
            {
                name: "GBPCNH",
                digits: 5,
            },
            {
                name: "UKO12",
                digits: 3,
            },
            {
                name: "Hong Kong 50",
                digits: 1,
            },
            {
                name: "COIN",
                digits: 3,
            },
            {
                name: "GLD",
                digits: 2,
            },
            {
                name: "USJU",
                digits: 0,
            },
            {
                name: "ONEUSD",
                digits: 5,
            },
            {
                name: "GBPCADeq",
                digits: 5,
            },
            {
                name: "AMZN",
                digits: 3,
            },
            {
                name: "United States Oil Fund",
                digits: 2,
            },
            {
                name: "Makita",
                digits: 0,
            },
            {
                name: "SHOP",
                digits: 3,
            },
            {
                name: "GBPJPYeq",
                digits: 3,
            },
            {
                name: "NZDJPY",
                digits: 3,
            },
            {
                name: "KNCUSD",
                digits: 3,
            },
            {
                name: "Bank of America Corporation",
                digits: 2,
            },
            {
                name: "EURNOK",
                digits: 4,
            },
            {
                name: "NZDCNH",
                digits: 5,
            },
            {
                name: "TSM",
                digits: 3,
            },
            {
                name: "British American Tobacco",
                digits: 4,
            },
            {
                name: "XLMUSD",
                digits: 5,
            },
            {
                name: "XEMUSD",
                digits: 4,
            },
            {
                name: "ATMUSD",
                digits: 3,
            },
            {
                name: "JPK",
                digits: 0,
            },
            {
                name: "AMGN",
                digits: 3,
            },
            {
                name: "Netflix",
                digits: 2,
            },
            {
                name: "GOOGL",
                digits: 2,
            },
            {
                name: "NET",
                digits: 2,
            },
            {
                name: "USDPLN",
                digits: 5,
            },
            {
                name: "QQQ",
                digits: 2,
            },
            {
                name: "USJM",
                digits: 0,
            },
            {
                name: "AUDJPY",
                digits: 3,
            },
            {
                name: "USOUSD",
                digits: 3,
            },
            {
                name: "AAVUSD",
                digits: 2,
            },
            {
                name: "EURJPYeq",
                digits: 3,
            },
            {
                name: "ADS",
                digits: 3,
            },
            {
                name: "225JPYeq",
                digits: 2,
            },
            {
                name: "XTZUSD",
                digits: 3,
            },
            {
                name: "U30USDeq",
                digits: 2,
            },
            {
                name: "Bank of China",
                digits: 2,
            },
            {
                name: "GBPCHF",
                digits: 5,
            },
            {
                name: "CHFJPY",
                digits: 3,
            },
            {
                name: "USDTHB",
                digits: 5,
            },
            {
                name: "SKLUSD",
                digits: 5,
            },
            {
                name: "LTCUSD",
                digits: 3,
            },
            {
                name: "China 50",
                digits: 2,
            },
            {
                name: "GBPAUD",
                digits: 5,
            },
            {
                name: "ZILUSD",
                digits: 5,
            },
            {
                name: "XAUUSD",
                digits: 2,
            },
            {
                name: "AUDUSDs",
                digits: 5,
            },
            {
                name: "AUDNZD",
                digits: 5,
            },
            {
                name: "Oracle Corporation",
                digits: 2,
            },
            {
                name: "Mitsui & Co",
                digits: 1,
            },
            {
                name: "USDDKK",
                digits: 5,
            },
            {
                name: "UKOUSD",
                digits: 3,
            },
            {
                name: "FTSE100",
                digits: 2,
            },
            {
                name: "BTCUSD",
                digits: 2,
            },
            {
                name: "100GBPeq",
                digits: 2,
            },
            {
                name: "HSBC Holdings",
                digits: 2,
            },
            {
                name: "EURGBP",
                digits: 5,
            },
            {
                name: "ADAUSD",
                digits: 4,
            },
            {
                name: "AUDDKK",
                digits: 5,
            },
            {
                name: "RVNUSD",
                digits: 5,
            },
            {
                name: "Procter & Gamble Company",
                digits: 2,
            },
            {
                name: "GBPAUDeq",
                digits: 5,
            },
            {
                name: "MANUSD",
                digits: 4,
            },
            {
                name: "Germany 40",
                digits: 2,
            },
            {
                name: "XAGUSD",
                digits: 3,
            },
            {
                name: "Bayerische Motoren Werke",
                digits: 3,
            },
            {
                name: "NZDCHF",
                digits: 5,
            },
            {
                name: "GAS",
                digits: 3,
            },
            {
                name: "AU200",
                digits: 2,
            },
            {
                name: "GBPCHF",
                digits: 5,
            },
            {
                name: "EURUSDs",
                digits: 5,
            },
            {
                name: "DOGUSD",
                digits: 5,
            },
            {
                name: "AUDNZD",
                digits: 5,
            },
            {
                name: "AAVUSD",
                digits: 2,
            },
            {
                name: "APEUSD",
                digits: 3,
            },
            {
                name: "COMUSD",
                digits: 2,
            },
            {
                name: "IMXUSD",
                digits: 4,
            },
            {
                name: "EURTRY",
                digits: 5,
            },
            {
                name: "STOUSD",
                digits: 4,
            },
            {
                name: "creditearn",
                digits: 5,
            },
            {
                name: "FLWUSD",
                digits: 3,
            },
            {
                name: "GBPHKD",
                digits: 5,
            },
            {
                name: "CVX",
                digits: 2,
            },
            {
                name: "EURAUDecn",
                digits: 5,
            },
            {
                name: "Visa",
                digits: 2,
            },
            {
                name: "EURCHF",
                digits: 5,
            },
            {
                name: "EURZAR",
                digits: 4,
            },
            {
                name: "UK 100",
                digits: 2,
            },
            {
                name: "WTI03",
                digits: 3,
            },
            {
                name: "ALV",
                digits: 3,
            },
            {
                name: "CELUSD",
                digits: 5,
            },
            {
                name: "D30EUReq",
                digits: 2,
            },
            {
                name: "GBPUSD",
                digits: 5,
            },
            {
                name: "GBPSEK",
                digits: 5,
            },
            {
                name: "CLA",
                digits: 2,
            },
            {
                name: "Danone",
                digits: 2,
            },
            {
                name: "USDHKD",
                digits: 5,
            },
            {
                name: "EURCADecn",
                digits: 5,
            },
            {
                name: "200AUD",
                digits: 2,
            },
            {
                name: "Chevron Corporation",
                digits: 2,
            },
            {
                name: "EGLUSD",
                digits: 2,
            },
            {
                name: "IBE",
                digits: 3,
            },
            {
                name: "NOKJPY",
                digits: 3,
            },
            {
                name: "CEOUSD",
                digits: 3,
            },
            {
                name: "CLJ",
                digits: 2,
            },
            {
                name: "Verizon Communications",
                digits: 2,
            },
            {
                name: "USDIDRa",
                digits: 0,
            },
            {
                name: "PEPUSD",
                digits: 5,
            },
            {
                name: "Workhorse Group",
                digits: 2,
            },
            {
                name: "VOD",
                digits: 4,
            },
            {
                name: "AIA",
                digits: 2,
            },
            {
                name: "USDZAR",
                digits: 5,
            },
            {
                name: "HKBankChina",
                digits: 2,
            },
            {
                name: "WTI10",
                digits: 3,
            },
            {
                name: "CHZUSD",
                digits: 5,
            },
            {
                name: "GBPCADecn",
                digits: 5,
            },
            {
                name: "EURJPYecn",
                digits: 3,
            },
            {
                name: "INCUSD",
                digits: 4,
            },
            {
                name: "Nissan Motor Co",
                digits: 1,
            },
            {
                name: "Mizuho",
                digits: 1,
            },
            {
                name: "AUDSEK",
                digits: 5,
            },
            {
                name: "Lenovo Group",
                digits: 2,
            },
            {
                name: "AUDJPY",
                digits: 3,
            },
            {
                name: "Softbank",
                digits: 0,
            },
            {
                name: "US 30 (Dow Jones)",
                digits: 2,
            },
            {
                name: "BNP Paribas",
                digits: 3,
            },
            {
                name: "Toyota",
                digits: 0,
            },
            {
                name: "NASUSDeq",
                digits: 2,
            },
            {
                name: "U30USD",
                digits: 2,
            },
            {
                name: "CHFSEK",
                digits: 5,
            },
            {
                name: "US500",
                digits: 2,
            },
            {
                name: "KNCUSD",
                digits: 3,
            },
            {
                name: "GBPDKK",
                digits: 5,
            },
            {
                name: "ZINC",
                digits: 3,
            },
            {
                name: "CLH",
                digits: 2,
            },
            {
                name: "ZECUSD",
                digits: 3,
            },
            {
                name: "CHFHUF",
                digits: 3,
            },
            {
                name: "CNHJPY",
                digits: 3,
            },
            {
                name: "Walt Disney Company",
                digits: 2,
            },
            {
                name: "QTMUSD",
                digits: 3,
            },
            {
                name: "QTMUSD",
                digits: 3,
            },
            {
                name: "USDHUF",
                digits: 3,
            },
            {
                name: "LNKUSD",
                digits: 5,
            },
            {
                name: "SNXUSD",
                digits: 3,
            },
            {
                name: "SEKJPY",
                digits: 3,
            },
            {
                name: "SGDHKD",
                digits: 5,
            },
            {
                name: "Subscription Test",
                digits: 0,
            },
            {
                name: "MXNJPY",
                digits: 3,
            },
            {
                name: "NZDNOK",
                digits: 4,
            },
            {
                name: "USDJPYs",
                digits: 3,
            },
            {
                name: "USDDKK",
                digits: 5,
            },
            {
                name: "AUDNZDecn",
                digits: 5,
            },
            {
                name: "F40EUR",
                digits: 2,
            },
            {
                name: "SPXUSDeq",
                digits: 2,
            },
            {
                name: "Seven & i Holdings Co",
                digits: 0,
            },
            {
                name: "PICC Property and Casualty",
                digits: 2,
            },
            {
                name: "GBPJPYs",
                digits: 3,
            },
            {
                name: "LRCUSD",
                digits: 4,
            },
            {
                name: "USDHKD",
                digits: 5,
            },
            {
                name: "Intel",
                digits: 2,
            },
            {
                name: "##BABA",
                digits: 2,
            },
            {
                name: "SGDJPY",
                digits: 3,
            },
            {
                name: "GBPJPYecn",
                digits: 3,
            },
            {
                name: "AXA",
                digits: 3,
            },
            {
                name: "VECUSD",
                digits: 4,
            },
            {
                name: "NKE",
                digits: 2,
            },
            {
                name: "LPTUSD",
                digits: 3,
            },
            {
                name: "AUDUSD",
                digits: 5,
            },
            {
                name: "SNXUSD",
                digits: 3,
            },
            {
                name: "EURUSD",
                digits: 5,
            },
            {
                name: "AUDCADs",
                digits: 5,
            },
            {
                name: "GBPUSD",
                digits: 5,
            },
            {
                name: "CADJPYecn",
                digits: 3,
            },
            {
                name: "XNGUSDs",
                digits: 4,
            },
            {
                name: "Softbank",
                digits: 0,
            },
            {
                name: "##GOOG",
                digits: 2,
            },
            {
                name: "TETUSD",
                digits: 3,
            },
            {
                name: "EURAUDs",
                digits: 5,
            },
            {
                name: "CLM",
                digits: 2,
            },
            {
                name: "USDCHFs",
                digits: 5,
            },
            {
                name: "H33HKD",
                digits: 2,
            },
            {
                name: "CHRUSD",
                digits: 4,
            },
            {
                name: "Diageo",
                digits: 4,
            },
            {
                name: "CLV",
                digits: 2,
            },
            {
                name: "Meta Platforms",
                digits: 2,
            },
            {
                name: "XPBUSD",
                digits: 2,
            },
            {
                name: "200AUDecn",
                digits: 2,
            },
            {
                name: "USDCADs",
                digits: 5,
            },
            {
                name: "XAUUSDecn",
                digits: 2,
            },
            {
                name: "225JPYecn",
                digits: 2,
            },
            {
                name: "USDHKDecn",
                digits: 5,
            },
            {
                name: "SANUSD",
                digits: 4,
            },
            {
                name: "EURGBPecn",
                digits: 5,
            },
            {
                name: "Lloyds",
                digits: 2,
            },
            {
                name: "CLQ",
                digits: 2,
            },
            {
                name: "CCFemsa",
                digits: 2,
            },
            {
                name: "USJH",
                digits: 0,
            },
            {
                name: "ZRXUSD",
                digits: 4,
            },
            {
                name: "XCUUSD",
                digits: 2,
            },
            {
                name: "Vodafone",
                digits: 2,
            },
            {
                name: "SPXUSD",
                digits: 2,
            },
            {
                name: "LPTUSD",
                digits: 3,
            },
            {
                name: "USDNOKecn",
                digits: 5,
            },
            {
                name: "Sony",
                digits: 0,
            },
            {
                name: "CLU",
                digits: 2,
            },
            {
                name: "Unilever",
                digits: 2,
            },
            {
                name: "AUDCHFecn",
                digits: 5,
            },
            {
                name: "AUDUSDecn",
                digits: 5,
            },
            {
                name: "EURGBP",
                digits: 5,
            },
            {
                name: "EURCHFecn",
                digits: 5,
            },
            {
                name: "Deutsche Telekom",
                digits: 3,
            },
            {
                name: "SHK Ppt",
                digits: 1,
            },
            {
                name: "EURAUD",
                digits: 5,
            },
            {
                name: "SUSUSD",
                digits: 3,
            },
            {
                name: "VW",
                digits: 2,
            },
            {
                name: "UKOUSDecn",
                digits: 3,
            },
            {
                name: "##DIS",
                digits: 2,
            },
            {
                name: "ZENUSD",
                digits: 3,
            },
            {
                name: "CLX",
                digits: 2,
            },
            {
                name: "NZDUSDs",
                digits: 5,
            },
            {
                name: "AUDNZDs",
                digits: 5,
            },
            {
                name: "NZDCHFecn",
                digits: 5,
            },
            {
                name: "Tencent Holdings",
                digits: 1,
            },
            {
                name: "##NKE",
                digits: 2,
            },
            {
                name: "CHFJPY",
                digits: 3,
            },
            {
                name: "A50",
                digits: 1,
            },
            {
                name: "Daimler",
                digits: 2,
            },
            {
                name: "XAUUSD",
                digits: 2,
            },
            {
                name: "USDCADecn",
                digits: 5,
            },
            {
                name: "HSIU",
                digits: 0,
            },
            {
                name: "EURCHFs",
                digits: 5,
            },
            {
                name: "NZDJPYs",
                digits: 3,
            },
            {
                name: "AUDCHFs",
                digits: 5,
            },
            {
                name: "BA",
                digits: 2,
            },
            {
                name: "D30EURecn",
                digits: 2,
            },
            {
                name: "EURCADs",
                digits: 5,
            },
            {
                name: "Xiaomi",
                digits: 2,
            },
            {
                name: "USDSEKs",
                digits: 5,
            },
            {
                name: "EURGBPs",
                digits: 5,
            },
            {
                name: "CHFJPYs",
                digits: 3,
            },
            {
                name: "Alphabet Class A",
                digits: 2,
            },
            {
                name: "CLZ",
                digits: 2,
            },
            {
                name: "USDJPYecn",
                digits: 3,
            },
            {
                name: "GBPAUD",
                digits: 5,
            },
            {
                name: "ONEUSD",
                digits: 5,
            },
            {
                name: "HSIJ",
                digits: 0,
            },
            {
                name: "USDSGD",
                digits: 5,
            },
            {
                name: "GBPNZDecn",
                digits: 5,
            },
            {
                name: "EURAUD",
                digits: 5,
            },
            {
                name: "Toyota Motor Corp",
                digits: 0,
            },
            {
                name: "ALIUSD",
                digits: 3,
            },
            {
                name: "USOUSDecn",
                digits: 3,
            },
            {
                name: "EURCAD",
                digits: 5,
            },
            {
                name: "AUDJPYs",
                digits: 3,
            },
            {
                name: "EURCAD",
                digits: 5,
            },
            {
                name: "HSIG",
                digits: 0,
            },
            {
                name: "Iberdrola",
                digits: 3,
            },
            {
                name: "Hyatt Hotels Corporation",
                digits: 2,
            },
            {
                name: "INCUSD",
                digits: 4,
            },
            {
                name: "AUDJPY",
                digits: 3,
            },
            {
                name: "Lufthanse",
                digits: 3,
            },
            {
                name: "MMM",
                digits: 2,
            },
            {
                name: "AUDCHF",
                digits: 5,
            },
            {
                name: "HSIM",
                digits: 0,
            },
            {
                name: "GBPCAD",
                digits: 5,
            },
            {
                name: "XAGUSDm",
                digits: 3,
            },
            {
                name: "HSIV",
                digits: 0,
            },
            {
                name: "BAYN",
                digits: 3,
            },
            {
                name: "Home Depot",
                digits: 2,
            },
            {
                name: "AUDNZD",
                digits: 5,
            },
            {
                name: "AUDUSD",
                digits: 5,
            },
            {
                name: "GBPCADs",
                digits: 5,
            },
            {
                name: "CHFJPY",
                digits: 3,
            },
            {
                name: "USOUSDs",
                digits: 3,
            },
            {
                name: "NZDJPY",
                digits: 3,
            },
            {
                name: "LVMH Moet Hennessy Louis Vuitton",
                digits: 2,
            },
            {
                name: "U30USDecn",
                digits: 2,
            },
            {
                name: "NZDCHFs",
                digits: 5,
            },
            {
                name: "NASUSDecn",
                digits: 2,
            },
            {
                name: "GBPUSDs",
                digits: 5,
            },
            {
                name: "EURUSD",
                digits: 5,
            },
            {
                name: "USDCHF",
                digits: 5,
            },
            {
                name: "CADCHF",
                digits: 5,
            },
            {
                name: "Shell",
                digits: 2,
            },
            {
                name: "Apple",
                digits: 2,
            },
            {
                name: "International Business Machines",
                digits: 2,
            },
            {
                name: "MANUSD",
                digits: 4,
            },
            {
                name: "USDNOK",
                digits: 4,
            },
            {
                name: "GBPAUDecn",
                digits: 5,
            },
            {
                name: "GBPNZDs",
                digits: 5,
            },
            {
                name: "Allianz",
                digits: 2,
            },
            {
                name: "Nestle",
                digits: 2,
            },
            {
                name: "ING Group",
                digits: 2,
            },
            {
                name: "NZDCADecn",
                digits: 5,
            },
            {
                name: "BT",
                digits: 2,
            },
            {
                name: "HSIN",
                digits: 0,
            },
            {
                name: "EURNZDecn",
                digits: 5,
            },
            {
                name: "TETUSD",
                digits: 3,
            },
            {
                name: "Alibaba",
                digits: 2,
            },
            {
                name: "Intel",
                digits: 2,
            },
            {
                name: "KDDI",
                digits: 1,
            },
            {
                name: "Novartis",
                digits: 2,
            },
            {
                name: "FTMUSD",
                digits: 4,
            },
            {
                name: "Amazon",
                digits: 2,
            },
            {
                name: "HSIX",
                digits: 0,
            },
            {
                name: "XAGUSDfecn",
                digits: 3,
            },
            {
                name: "Boeing",
                digits: 2,
            },
            {
                name: "AUDSGD",
                digits: 5,
            },
            {
                name: "USDMXN",
                digits: 4,
            },
            {
                name: "GMTUSD",
                digits: 4,
            },
            {
                name: "GBPNZD",
                digits: 5,
            },
            {
                name: "NZDUSD",
                digits: 5,
            },
            {
                name: "USDNOK",
                digits: 5,
            },
            {
                name: "EURCAD",
                digits: 5,
            },
            {
                name: "UKO05",
                digits: 3,
            },
            {
                name: "USDCAD",
                digits: 5,
            },
            {
                name: "NZDUSD",
                digits: 5,
            },
            {
                name: "USDJPY",
                digits: 3,
            },
            {
                name: "GBPHKD",
                digits: 5,
            },
            {
                name: "USDCAD",
                digits: 5,
            },
            {
                name: "USDCHF",
                digits: 5,
            },
            {
                name: "Shell PLC",
                digits: 3,
            },
            {
                name: "JPMorgan Chase & Co",
                digits: 2,
            },
            {
                name: "FLWUSD",
                digits: 3,
            },
            {
                name: "EURNZD",
                digits: 5,
            },
            {
                name: "Rio Tinto",
                digits: 4,
            },
            {
                name: "EURRUB",
                digits: 5,
            },
            {
                name: "EON",
                digits: 2,
            },
            {
                name: "AUDCAD",
                digits: 5,
            },
            {
                name: "XAGUSDs",
                digits: 3,
            },
            {
                name: "GRTUSD",
                digits: 5,
            },
            {
                name: "CADCHF",
                digits: 5,
            },
            {
                name: "XAUUSDs",
                digits: 2,
            },
            {
                name: "Roche Holding",
                digits: 1,
            },
            {
                name: "NZDCAD",
                digits: 5,
            },
            {
                name: "EURMXN",
                digits: 5,
            },
            {
                name: "F40EURecn",
                digits: 2,
            },
            {
                name: "GBPZAR",
                digits: 5,
            },
            {
                name: "Mastercard",
                digits: 2,
            },
            {
                name: "EURNZDs",
                digits: 5,
            },
            {
                name: "GBPCHFs",
                digits: 5,
            },
            {
                name: "GBPNZD",
                digits: 5,
            },
            {
                name: "EURZAR",
                digits: 5,
            },
            {
                name: "EURCHF",
                digits: 5,
            },
            {
                name: "NZDJPY",
                digits: 3,
            },
            {
                name: "HSIQ",
                digits: 0,
            },
            {
                name: "GBPDKK",
                digits: 5,
            },
            {
                name: "ZILUSD",
                digits: 5,
            },
            {
                name: "USDSGD",
                digits: 5,
            },
            {
                name: "NZDCADs",
                digits: 5,
            },
            {
                name: "DIS",
                digits: 2,
            },
            {
                name: "USDSEKecn",
                digits: 5,
            },
            {
                name: "SAP",
                digits: 3,
            },
            {
                name: "Marriott International",
                digits: 2,
            },
            {
                name: "GALUSD",
                digits: 5,
            },
            {
                name: "XAUUSDfecn",
                digits: 2,
            },
            {
                name: "SEKJPY",
                digits: 3,
            },
            {
                name: "IOTUSD",
                digits: 4,
            },
            {
                name: "EURGBP",
                digits: 5,
            },
            {
                name: "EURNZD",
                digits: 5,
            },
            {
                name: "TSLA",
                digits: 2,
            },
            {
                name: "Siemens",
                digits: 3,
            },
            {
                name: "ONTUSD",
                digits: 4,
            },
            {
                name: "RENUSD",
                digits: 4,
            },
            {
                name: "GlaxoSmith",
                digits: 2,
            },
            {
                name: "Adidas",
                digits: 2,
            },
            {
                name: "USDSEK",
                digits: 5,
            },
            {
                name: "EURAUD",
                digits: 5,
            },
            {
                name: "GBPNOK",
                digits: 5,
            },
            {
                name: "McDonald's",
                digits: 2,
            },
            {
                name: "CEOUSD",
                digits: 3,
            },
            {
                name: "Unilever",
                digits: 4,
            },
            {
                name: "GBPJPY",
                digits: 3,
            },
            {
                name: "UNH",
                digits: 2,
            },
            {
                name: "DOTUSD",
                digits: 3,
            },
            {
                name: "EURNOK",
                digits: 5,
            },
            {
                name: "3M Company",
                digits: 2,
            },
            {
                name: "NIKU",
                digits: 0,
            },
            {
                name: "CHZUSD",
                digits: 5,
            },
            {
                name: "EURSEK",
                digits: 5,
            },
            {
                name: "EURHUF",
                digits: 3,
            },
            {
                name: "EURCZK",
                digits: 4,
            },
            {
                name: "GBPCZK",
                digits: 5,
            },
            {
                name: "Merck & Company",
                digits: 2,
            },
            {
                name: "ENJUSD",
                digits: 4,
            },
            {
                name: "GBPSGD",
                digits: 5,
            },
            {
                name: "Volkswagen",
                digits: 3,
            },
            {
                name: "CHFZAR",
                digits: 3,
            },
            {
                name: "NIKZ",
                digits: 0,
            },
            {
                name: "##NKE",
                digits: 2,
            },
            {
                name: "GBPAUDs",
                digits: 5,
            },
            {
                name: "EURHUF",
                digits: 3,
            },
            {
                name: "Netflix",
                digits: 2,
            },
            {
                name: "XEMUSD",
                digits: 4,
            },
            {
                name: "CHFHUF",
                digits: 3,
            },
            {
                name: "USDPLN",
                digits: 5,
            },
            {
                name: "UKO01",
                digits: 3,
            },
            {
                name: "UKO02",
                digits: 3,
            },
            {
                name: "UKO03",
                digits: 3,
            },
            {
                name: "Pepsico",
                digits: 2,
            },
            {
                name: "GBPNOK",
                digits: 4,
            },
            {
                name: "EURCNH",
                digits: 5,
            },
            {
                name: "EURNZD",
                digits: 5,
            },
            {
                name: "UKO04",
                digits: 3,
            },
            {
                name: "UKO07",
                digits: 3,
            },
            {
                name: "UnitedHealth Group",
                digits: 2,
            },
            {
                name: "Mapfre",
                digits: 3,
            },
            {
                name: "UKO08",
                digits: 3,
            },
            {
                name: "IMXUSD",
                digits: 4,
            },
            {
                name: "EURPLN",
                digits: 5,
            },
            {
                name: "EURTRY",
                digits: 5,
            },
            {
                name: "UKO09",
                digits: 3,
            },
            {
                name: "NZDJPYecn",
                digits: 3,
            },
            {
                name: "UKO10",
                digits: 3,
            },
            {
                name: "UKO11",
                digits: 3,
            },
            {
                name: "CNEUSD",
                digits: 4,
            },
            {
                name: "USDHKD",
                digits: 5,
            },
            {
                name: "EURTRY",
                digits: 5,
            },
            {
                name: "XAGUSD",
                digits: 4,
            },
            {
                name: "Lufthansa",
                digits: 2,
            },
            {
                name: "CTSUSD",
                digits: 4,
            },
            {
                name: "Dolphin Entertainment Inc",
                digits: 2,
            },
            {
                name: "ZRXUSD",
                digits: 4,
            },
            {
                name: "Bayer",
                digits: 2,
            },
            {
                name: "EURSEK",
                digits: 5,
            },
            {
                name: "EURSGD",
                digits: 5,
            },
            {
                name: "Kering",
                digits: 2,
            },
            {
                name: "EURRUB",
                digits: 5,
            },
            {
                name: "NZDJPY",
                digits: 3,
            },
            {
                name: "GBPSGD",
                digits: 5,
            },
            {
                name: "EURCZK",
                digits: 4,
            },
            {
                name: "USDCZK",
                digits: 4,
            },
            {
                name: "XOM",
                digits: 2,
            },
            {
                name: "AUDZAR",
                digits: 5,
            },
            {
                name: "CADDKK",
                digits: 5,
            },
            {
                name: "CADMXN",
                digits: 5,
            },
            {
                name: "CADNOK",
                digits: 5,
            },
            {
                name: "NZDSEK",
                digits: 5,
            },
            {
                name: "EURCHF",
                digits: 5,
            },
            {
                name: "AUDJPYecn",
                digits: 3,
            },
            {
                name: "FR40",
                digits: 2,
            },
            {
                name: "CHFNOK",
                digits: 5,
            },
            {
                name: "AUDNZD",
                digits: 5,
            },
            {
                name: "ES35",
                digits: 1,
            },
            {
                name: "EURCAD",
                digits: 5,
            },
            {
                name: "Exxon",
                digits: 2,
            },
            {
                name: "CADCHFs",
                digits: 5,
            },
            {
                name: "GBPHUF",
                digits: 3,
            },
            {
                name: "UKO12",
                digits: 3,
            },
            {
                name: "XRPUSD",
                digits: 5,
            },
            {
                name: "DIS",
                digits: 2,
            },
            {
                name: "NOKSEK",
                digits: 5,
            },
            {
                name: "CHFPLN",
                digits: 5,
            },
            {
                name: "CHFSEK",
                digits: 5,
            },
            {
                name: "XAUUSD",
                digits: 2,
            },
            {
                name: "AUDCNH",
                digits: 5,
            },
            {
                name: "WTI01",
                digits: 3,
            },
            {
                name: "GBPPLN",
                digits: 5,
            },
            {
                name: "Loreal",
                digits: 2,
            },
            {
                name: "ETHUSD",
                digits: 2,
            },
            {
                name: "CHFSGD",
                digits: 5,
            },
            {
                name: "SGDJPY",
                digits: 3,
            },
            {
                name: "WTI02",
                digits: 3,
            },
            {
                name: "XTIUSD",
                digits: 3,
            },
            {
                name: "DOTUSD",
                digits: 3,
            },
            {
                name: "BAC",
                digits: 2,
            },
            {
                name: "DTEGY",
                digits: 2,
            },
            {
                name: "WTI03",
                digits: 3,
            },
            {
                name: "UK100",
                digits: 2,
            },
            {
                name: "USDCZK",
                digits: 4,
            },
            {
                name: "USDSEK",
                digits: 5,
            },
            {
                name: "CHFZAR",
                digits: 5,
            },
            {
                name: "EURHKD",
                digits: 5,
            },
            {
                name: "USDDKK",
                digits: 5,
            },
            {
                name: "DKKSEK",
                digits: 5,
            },
            {
                name: "USDHKD",
                digits: 5,
            },
            {
                name: "DKKNOK",
                digits: 5,
            },
            {
                name: "CADJPY",
                digits: 3,
            },
            {
                name: "H33HKDm",
                digits: 2,
            },
            {
                name: "USDHUF",
                digits: 3,
            },
            {
                name: "EURCNH",
                digits: 5,
            },
            {
                name: "EURILS",
                digits: 5,
            },
            {
                name: "US100",
                digits: 2,
            },
            {
                name: "GBPCHF",
                digits: 5,
            },
            {
                name: "USDCNH",
                digits: 5,
            },
            {
                name: "EURJPY",
                digits: 3,
            },
            {
                name: "EURMXN",
                digits: 4,
            },
            {
                name: "EURSGD",
                digits: 5,
            },
            {
                name: "NZDSGD",
                digits: 5,
            },
            {
                name: "WTI04",
                digits: 3,
            },
            {
                name: "BTCUSD",
                digits: 2,
            },
            {
                name: "XAGUSD",
                digits: 3,
            },
            {
                name: "AUDJPY",
                digits: 3,
            },
            {
                name: "GBPDKK",
                digits: 5,
            },
            {
                name: "GBPTRY",
                digits: 5,
            },
            {
                name: "CLA",
                digits: 2,
            },
            {
                name: "PICC",
                digits: 2,
            },
            {
                name: "GRTUSD",
                digits: 5,
            },
            {
                name: "GBPAUD",
                digits: 5,
            },
            {
                name: "IOSUSD",
                digits: 6,
            },
            {
                name: "BMW",
                digits: 2,
            },
            {
                name: "BCHUSD",
                digits: 3,
            },
            {
                name: "JP225",
                digits: 2,
            },
            {
                name: "TRYJPY",
                digits: 3,
            },
            {
                name: "ZARJPY",
                digits: 3,
            },
            {
                name: "WTI05",
                digits: 3,
            },
            {
                name: "F40",
                digits: 2,
            },
            {
                name: "USDMXN",
                digits: 5,
            },
            {
                name: "AUDHUF",
                digits: 3,
            },
            {
                name: "AUDNOK",
                digits: 4,
            },
            {
                name: "NZDUSD",
                digits: 5,
            },
            {
                name: "ENJUSD",
                digits: 4,
            },
            {
                name: "CHFDKK",
                digits: 5,
            },
            {
                name: "JPMorgan",
                digits: 2,
            },
            {
                name: "WTI06",
                digits: 3,
            },
            {
                name: "USDNOK",
                digits: 5,
            },
            {
                name: "EURGBP",
                digits: 5,
            },
            {
                name: "KAVUSD",
                digits: 4,
            },
            {
                name: "##FB",
                digits: 2,
            },
            {
                name: "##PFE",
                digits: 2,
            },
            {
                name: "USDRUB",
                digits: 5,
            },
            {
                name: "WTI08",
                digits: 3,
            },
            {
                name: "CHFHUF",
                digits: 3,
            },
            {
                name: "CNHJPY",
                digits: 3,
            },
            {
                name: "WTI09",
                digits: 3,
            },
            {
                name: "RVNUSD",
                digits: 5,
            },
            {
                name: "GBPCZK",
                digits: 4,
            },
            {
                name: "GBPHUF",
                digits: 5,
            },
            {
                name: "WOOUSD",
                digits: 5,
            },
            {
                name: "GBPHKD",
                digits: 5,
            },
            {
                name: "USDSGD",
                digits: 5,
            },
            {
                name: "USDPLN",
                digits: 5,
            },
            {
                name: "WTI10",
                digits: 3,
            },
            {
                name: "GBPMXN",
                digits: 4,
            },
            {
                name: "WTI11",
                digits: 3,
            },
            {
                name: "MXNJPY",
                digits: 3,
            },
            {
                name: "DYDUSD",
                digits: 3,
            },
            {
                name: "USDTRY",
                digits: 5,
            },
            {
                name: "NZDDKK",
                digits: 5,
            },
            {
                name: "CNEUSD",
                digits: 4,
            },
            {
                name: "USDZAR",
                digits: 5,
            },
            {
                name: "EURAUD",
                digits: 5,
            },
            {
                name: "NZDHUF",
                digits: 3,
            },
            {
                name: "XTIUSD",
                digits: 3,
            },
            {
                name: "USDX06",
                digits: 3,
            },
            {
                name: "USDSEK",
                digits: 5,
            },
            {
                name: "CADCHF",
                digits: 5,
            },
            {
                name: "DKKSEK",
                digits: 5,
            },
            {
                name: "NZDNOK",
                digits: 4,
            },
            {
                name: "SEKJPY",
                digits: 3,
            },
            {
                name: "USDILS",
                digits: 5,
            },
            {
                name: "AIR",
                digits: 3,
            },
            {
                name: "Mitsui",
                digits: 1,
            },
            {
                name: "BATS",
                digits: 4,
            },
            {
                name: "BMW",
                digits: 3,
            },
            {
                name: "Henderson",
                digits: 2,
            },
            {
                name: "USDX09",
                digits: 3,
            },
            {
                name: "ZARJPY",
                digits: 3,
            },
            {
                name: "CLN",
                digits: 2,
            },
            {
                name: "JP225",
                digits: 2,
            },
            {
                name: "XBRUSD",
                digits: 3,
            },
            {
                name: "AUS200",
                digits: 2,
            },
            {
                name: "USDX03",
                digits: 3,
            },
            {
                name: "XPTUSD",
                digits: 3,
            },
            {
                name: "HSIH",
                digits: 0,
            },
            {
                name: "US30",
                digits: 2,
            },
            {
                name: "UK100",
                digits: 2,
            },
            {
                name: "USDSEK",
                digits: 5,
            },
            {
                name: "DE30",
                digits: 2,
            },
            {
                name: "ES35",
                digits: 2,
            },
            {
                name: "US500",
                digits: 2,
            },
            {
                name: "HK50",
                digits: 2,
            },
            {
                name: "##PFE",
                digits: 2,
            },
            {
                name: "STOXX50",
                digits: 2,
            },
            {
                name: "NAS100",
                digits: 2,
            },
            {
                name: "HSIF",
                digits: 0,
            },
            {
                name: "XAGUSDf",
                digits: 3,
            },
            {
                name: "225JPYm",
                digits: 2,
            },
            {
                name: "GBPTRY",
                digits: 5,
            },
            {
                name: "USDMXN",
                digits: 4,
            },
            {
                name: "NDXUSD",
                digits: 2,
            },
            {
                name: "DAXEUR",
                digits: 2,
            },
            {
                name: "EUSTX50",
                digits: 0,
            },
            {
                name: "JPN225",
                digits: 0,
            },
            {
                name: "ADAUSD",
                digits: 5,
            },
            {
                name: "KSMUSD",
                digits: 2,
            },
            {
                name: "ANKUSD",
                digits: 5,
            },
            {
                name: "IMXUSD",
                digits: 4,
            },
            {
                name: "COTUSD",
                digits: 5,
            },
            {
                name: "STOUSD",
                digits: 4,
            },
            {
                name: "RETUSD",
                digits: 5,
            },
            {
                name: "FRA40",
                digits: 0,
            },
            {
                name: "XNGUSD",
                digits: 3,
            },
            {
                name: "SocGen",
                digits: 3,
            },
            {
                name: "H33HKDeq",
                digits: 2,
            },
            {
                name: "NFLX",
                digits: 3,
            },
            {
                name: "TSM",
                digits: 3,
            },
            {
                name: "MMM",
                digits: 2,
            },
            {
                name: "MANU",
                digits: 3,
            },
            {
                name: "EURJPYs",
                digits: 3,
            },
            {
                name: "AUDUSD",
                digits: 5,
            },
            {
                name: "AUDJPY",
                digits: 3,
            },
            {
                name: "EURCHF",
                digits: 5,
            },
            {
                name: "CHFJPY",
                digits: 3,
            },
            {
                name: "EURJPY",
                digits: 3,
            },
            {
                name: "CHFSEK",
                digits: 5,
            },
            {
                name: "CHFSGD",
                digits: 5,
            },
            {
                name: "CHFNOK",
                digits: 4,
            },
            {
                name: "CHFZAR",
                digits: 5,
            },
            {
                name: "EURHUF",
                digits: 3,
            },
            {
                name: "EURCAD",
                digits: 5,
            },
            {
                name: "EURCHF",
                digits: 5,
            },
            {
                name: "CADSGD",
                digits: 5,
            },
            {
                name: "CNHJPY",
                digits: 3,
            },
            {
                name: "USDPLN",
                digits: 5,
            },
            {
                name: "USDRUB",
                digits: 5,
            },
            {
                name: "USDCZK",
                digits: 4,
            },
            {
                name: "USDILS",
                digits: 5,
            },
            {
                name: "CADJPYs",
                digits: 3,
            },
            {
                name: "Adobe",
                digits: 2,
            },
            {
                name: "USDSEK",
                digits: 5,
            },
            {
                name: "USDZAR",
                digits: 5,
            },
            {
                name: "XAUUSD",
                digits: 2,
            },
            {
                name: "NEOUSD",
                digits: 2,
            },
            {
                name: "BP",
                digits: 2,
            },
            {
                name: "Airbus",
                digits: 2,
            },
            {
                name: "100GBP",
                digits: 2,
            },
            {
                name: "WTI12",
                digits: 3,
            },
            {
                name: "IBM",
                digits: 2,
            },
            {
                name: "CADCHFecn",
                digits: 5,
            },
            {
                name: "##AMZN",
                digits: 2,
            },
            {
                name: "SAP",
                digits: 2,
            },
            {
                name: "AAPL",
                digits: 3,
            },
            {
                name: "BA",
                digits: 3,
            },
            {
                name: "Santander",
                digits: 2,
            },
            {
                name: "AUDCAD",
                digits: 5,
            },
            {
                name: "BankAmerica",
                digits: 2,
            },
            {
                name: "100GBPecn",
                digits: 2,
            },
            {
                name: "Facebook",
                digits: 2,
            },
            {
                name: "US30",
                digits: 2,
            },
            {
                name: "DAX30",
                digits: 2,
            },
            {
                name: "XLMUSD",
                digits: 5,
            },
            {
                name: "ETHUSD",
                digits: 2,
            },
            {
                name: "CLH",
                digits: 2,
            },
            {
                name: "CLQ",
                digits: 2,
            },
            {
                name: "GBPAUD",
                digits: 5,
            },
            {
                name: "LVMH",
                digits: 2,
            },
            {
                name: "GBPCHFecn",
                digits: 5,
            },
            {
                name: "ASX200",
                digits: 2,
            },
            {
                name: "ALGUSD",
                digits: 4,
            },
            {
                name: "USDTHB",
                digits: 4,
            },
            {
                name: "GE",
                digits: 2,
            },
            {
                name: "USDTRY",
                digits: 5,
            },
            {
                name: "HSIQ",
                digits: 0,
            },
            {
                name: "JJ",
                digits: 2,
            },
            {
                name: "Xiaomi",
                digits: 2,
            },
            {
                name: "DSHUSD",
                digits: 3,
            },
            {
                name: "HSIF",
                digits: 0,
            },
            {
                name: "Apache",
                digits: 2,
            },
            {
                name: "D30EUR",
                digits: 2,
            },
            {
                name: "##SBUX",
                digits: 2,
            },
            {
                name: "225JPY",
                digits: 2,
            },
            {
                name: "XUL",
                digits: 2,
            },
            {
                name: "##AMZN",
                digits: 2,
            },
            {
                name: "##AAPL",
                digits: 2,
            },
            {
                name: "##BA",
                digits: 2,
            },
            {
                name: "AUS200",
                digits: 2,
            },
            {
                name: "##MSFT",
                digits: 2,
            },
            {
                name: "##NFLX",
                digits: 2,
            },
            {
                name: "##BA",
                digits: 2,
            },
            {
                name: "NIKE",
                digits: 2,
            },
            {
                name: "##GE",
                digits: 2,
            },
            {
                name: "GBPUSDecn",
                digits: 5,
            },
            {
                name: "EUSTX50",
                digits: 2,
            },
            {
                name: "WS30",
                digits: 2,
            },
            {
                name: "SWISS20",
                digits: 2,
            },
            {
                name: "##TSLA",
                digits: 2,
            },
            {
                name: "Visa",
                digits: 2,
            },
            {
                name: "Comcast",
                digits: 2,
            },
            {
                name: "Verizon",
                digits: 2,
            },
            {
                name: "GenElectric",
                digits: 2,
            },
            {
                name: "##NFLX",
                digits: 2,
            },
            {
                name: "Walmart",
                digits: 2,
            },
            {
                name: "Salesforce",
                digits: 2,
            },
            {
                name: "SPXUSDecn",
                digits: 2,
            },
            {
                name: "XAUUSDeq",
                digits: 2,
            },
            {
                name: "Chevron",
                digits: 2,
            },
            {
                name: "Tesla",
                digits: 2,
            },
            {
                name: "HSBC",
                digits: 2,
            },
            {
                name: "USDX",
                digits: 2,
            },
            {
                name: "AstraZeneca",
                digits: 2,
            },
            {
                name: "XAUCNH",
                digits: 2,
            },
            {
                name: "H33HKDecn",
                digits: 2,
            },
            {
                name: "USDCHFeq",
                digits: 5,
            },
            {
                name: "AUDUSD",
                digits: 5,
            },
            {
                name: "GBPCHF",
                digits: 5,
            },
            {
                name: "EURUSD",
                digits: 5,
            },
            {
                name: "GBPJPY",
                digits: 3,
            },
            {
                name: "CNHJPY",
                digits: 3,
            },
            {
                name: "USDSGD",
                digits: 5,
            },
            {
                name: "USDHKD",
                digits: 5,
            },
            {
                name: "XAUUSD",
                digits: 2,
            },
            {
                name: "GBPCNH",
                digits: 5,
            },
            {
                name: "EURCNH",
                digits: 5,
            },
            {
                name: "NZDCNH",
                digits: 5,
            },
            {
                name: "F40",
                digits: 2,
            },
            {
                name: "XAGUSD",
                digits: 3,
            },
            {
                name: "JP225",
                digits: 2,
            },
            {
                name: "AUS200",
                digits: 2,
            },
            {
                name: "DE40",
                digits: 2,
            },
            {
                name: "USTEC",
                digits: 2,
            },
            {
                name: "UK100",
                digits: 2,
            },
            {
                name: "US30",
                digits: 2,
            },
            {
                name: "A50",
                digits: 2,
            },
            {
                name: "USDX",
                digits: 3,
            },
            {
                name: "NZDHKD",
                digits: 5,
            },
            {
                name: "CHFHKD",
                digits: 5,
            },
            {
                name: "SGDHKD",
                digits: 5,
            },
            {
                name: "EOSUSD",
                digits: 4,
            },
            {
                name: "BTCUSD",
                digits: 2,
            },
            {
                name: "LTCUSD",
                digits: 3,
            },
            {
                name: "XRPUSD",
                digits: 5,
            },
            {
                name: "DOTUSD",
                digits: 3,
            },
            {
                name: "LNKUSD",
                digits: 3,
            },
            {
                name: "VIX03",
                digits: 3,
            },
            {
                name: "LTCJPY",
                digits: 1,
            },
            {
                name: "XLMUSD",
                digits: 5,
            },
            {
                name: "VIX02",
                digits: 3,
            },
            {
                name: "VIX04",
                digits: 3,
            },
            {
                name: "VIX11",
                digits: 3,
            },
            {
                name: "VIX08",
                digits: 3,
            },
            {
                name: "VIX12",
                digits: 3,
            },
            {
                name: "VIX07",
                digits: 3,
            },
            {
                name: "UKO07",
                digits: 3,
            },
            {
                name: "UKO11",
                digits: 3,
            },
            {
                name: "WTI08",
                digits: 3,
            },
            {
                name: "WTI06",
                digits: 3,
            },
            {
                name: "WTI07",
                digits: 3,
            },
            {
                name: "WTI11",
                digits: 3,
            },
            {
                name: "USDUSC",
                digits: 2,
            },
            {
                name: "NFLX",
                digits: 3,
            },
            {
                name: "META",
                digits: 3,
            },
            {
                name: "JPM",
                digits: 2,
            },
            {
                name: "AAPL",
                digits: 3,
            },
            {
                name: "BA",
                digits: 3,
            },
            {
                name: "MSFT",
                digits: 3,
            },
            {
                name: "PEP",
                digits: 2,
            },
            {
                name: "GBPJPY",
                digits: 3,
            },
            {
                name: "USDHKD",
                digits: 5,
            },
            {
                name: "RVNUSD",
                digits: 5,
            },
            {
                name: "GBPZAR",
                digits: 5,
            },
            {
                name: "Honda Motor Company",
                digits: 1,
            },
            {
                name: "ALIUSD",
                digits: 3,
            },
            {
                name: "AUDCHF",
                digits: 5,
            },
        ],
    };
    const returnVal = new Promise((resolve, _) => resolve(mockData));
    return returnVal;
}
