export function getDataSameDirection(query) {
    const mockData = {
        status: 200,
        data: {
            data: [
                {
                    server_id: 1,
                    login: "xxxxxx99",
                    name: "Sajid",

                    symbol: "EURUSD",
                    book: "TEST",
                    volume: 3,
                    event: "zzzzz91",
                    order: "yyyyy60",
                    server_name: "MT5-Demo",
                    open_time: "2023-08-24 00:04:01",
                    close_time: "1970-01-01 00:00:00",
                },
                {
                    server_id: 1,
                    login: "xxxxxx86",
                    name: "Silwal",
                    symbol: "EURAUD",
                    book: "B",
                    volume: 1,
                    event: "zzzzz46",
                    order: "yyyyy48",
                    server_name: "MT5-Demo",
                    open_time: "2023-08-24 00:15:22",
                    close_time: "1970-01-01 00:00:00",
                },
                {
                    server_id: 1,
                    login: "xxxxxx89",
                    name: "Evli",
                    symbol: "EURAUD",
                    book: "S",
                    volume: 10,
                    event: "zzzzz46",
                    order: "yyyyy49",
                    server_name: "MT5-Demo",
                    open_time: "2023-08-24 00:15:23",
                    close_time: "1970-01-01 00:00:00",
                },
                {
                    server_id: 1,
                    login: "xxxxxx89",
                    name: "Kalatharan",
                    symbol: "EURAUD",
                    book: "S",
                    volume: 1,
                    event: "zzzzz47",
                    order: "yyyyy50",
                    server_name: "MT5-Demo",
                    open_time: "2023-08-24 00:15:24",
                    close_time: "1970-01-01 00:00:00",
                },
                {
                    server_id: 1,
                    login: "xxxxxx86",
                    name: "Miller",
                    symbol: "EURAUD",
                    book: "B",
                    volume: 1,
                    event: "zzzzz49",
                    order: "yyyyy51",
                    server_name: "MT5-Demo",
                    open_time: "2023-08-24 00:15:26",
                    close_time: "1970-01-01 00:00:00",
                },
                {
                    server_id: 1,
                    login: "xxxxxx99",
                    name: "SAIDI",
                    symbol: "AUDUSD",
                    book: "TEST",
                    volume: 5,
                    event: "zzzzz78",
                    order: "yyyyy82",
                    server_name: "MT5-Demo",
                    open_time: "2023-08-24 00:29:37",
                    close_time: "1970-01-01 00:00:00",
                },
                {
                    server_id: 1,
                    login: "xxxxxx92",
                    name: "Miah",
                    symbol: "NZDUSD",
                    book: "B",
                    volume: 10,
                    event: "zzzzz03",
                    order: "yyyyy16",
                    server_name: "MT5-Demo",
                    open_time: "2023-08-24 00:42:27",
                    close_time: "1970-01-01 00:00:00",
                },
                {
                    server_id: 2,
                    login: "xxxxxx99",
                    name: "Benkaddour",
                    symbol: "USDJPY",
                    book: "TEST",
                    volume: 3,
                    event: "zzzzz58",
                    order: "yyyyy32",
                    server_name: "MT4-Demo",
                    open_time: "2023-08-24 00:45:02",
                    close_time: "1970-01-01 00:00:00",
                },
                {
                    server_id: 2,
                    login: "xxxxxx71",
                    name: "Kone",
                    symbol: "GBPJPY",
                    book: "B",
                    volume: 3,
                    event: "zzzzz49",
                    order: "yyyyy52",
                    server_name: "MT4-Demo",
                    open_time: "2023-08-24 00:45:33",
                    close_time: "1970-01-01 00:00:00",
                },
            ],
            count: 9,
        },
    };
    const returnVal = new Promise((resolve, _) => resolve(mockData));
    return returnVal;
}
