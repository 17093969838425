
export function getDataWeightedVolume(query) {
    const mockData = {
        status: 200,
        data: [
            {
                "login": "xxxxxx22",
                "server_name": "MT5-Demo",
                "books": "[ B]",
                "closed_profit_total": 156.9,
                "closed_profit_total_eod": 351.1,
                "weighted_volume": 3,
                "weighted_volume_threshold": 3,
                "trigger_time": "2023-08-24 07:46:03"
            },
            {
                "login": "xxxxxx00",
                "server_name": "MT5-Demo",
                "books": "[S]",
                "closed_profit_total": 156.14,
                "closed_profit_total_eod": 19.83,
                "weighted_volume": 3,
                "weighted_volume_threshold": 3,
                "trigger_time": "2023-08-24 05:12:30"
            },
            {
                "login": "xxxxxx28",
                "server_name": "MT4-Demo",
                "books": "[B]",
                "closed_profit_total": -286.5,
                "closed_profit_total_eod": -537,
                "weighted_volume": 3,
                "weighted_volume_threshold": 3,
                "trigger_time": "2023-08-24 03:12:44"
            },
            {
                "login": "xxxxxx36",
                "server_name": "MT4-Demo",
                "books": "[B]",
                "closed_profit_total": 375,
                "closed_profit_total_eod": 159,
                "weighted_volume": 3,
                "weighted_volume_threshold": 3,
                "trigger_time": "2023-08-24 03:12:57"
            },
            {
                "login": "xxxxxx52",
                "server_name": "MT4-Demo",
                "books": "[B]",
                "closed_profit_total": 363,
                "closed_profit_total_eod": 57,
                "weighted_volume": 3,
                "weighted_volume_threshold": 3,
                "trigger_time": "2023-08-24 03:13:38"
            },
            {
                "login": "xxxxxx41",
                "server_name": "MT4-Demo",
                "books": "[S]",
                "closed_profit_total": 15.31,
                "closed_profit_total_eod": 40.06,
                "weighted_volume": 3,
                "weighted_volume_threshold": 3,
                "trigger_time": "2023-08-24 01:07:20"
            },
            {
                "login": "xxxxxx01",
                "server_name": "MT4-Demo",
                "books": "[B]",
                "closed_profit_total": 4227.66,
                "closed_profit_total_eod": -64.89,
                "weighted_volume": 3,
                "weighted_volume_threshold": 3,
                "trigger_time": "2023-08-24 01:09:55"
            },
            {
                "login": "xxxxxx88",
                "server_name": "MT4-Demo",
                "books": "[Test]",
                "closed_profit_total": 3300.29,
                "closed_profit_total_eod": 396.44,
                "weighted_volume": 3,
                "weighted_volume_threshold": 3,
                "trigger_time": "2023-08-24 02:35:02"
            },
            {
                "login": "xxxxxx90",
                "server_name": "MT4-Demo",
                "books": "[B]",
                "closed_profit_total": 3301.55,
                "closed_profit_total_eod": 398.85,
                "weighted_volume": 3,
                "weighted_volume_threshold": 3,
                "trigger_time": "2023-08-24 02:35:04"
            },
        ]
    };
    const returnVal = new Promise((resolve, _) => resolve(mockData));
    return returnVal;
}
