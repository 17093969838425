
export function getPumpingStatus() {
    const mockData = {
        status: 200,
        data: [
            {
                "alive": true,
                "broker": "Demo",
                "lastUpdateTime": 1692846887,
                "server": "MT4-Demo",
                "serverId": 2,
                "uniqueKey": "demo-2"
            },
            {
                "alive": true,
                "broker": "Demo",
                "lastUpdateTime": 1692846887,
                "server": "MT5-Demo",
                "serverId": 1,
                "uniqueKey": "demo-1"
            }
          ]
    };
    const returnVal = new Promise((resolve, _) => resolve(mockData));
    return returnVal;
}
