
export function getLoginHistory(query) {
    const mockData = {
        status: 200,
        data: {
            "xdata": [
                "2023-02-24 00:00:00",
                "2023-02-27 00:00:00",
                "2023-02-28 00:00:00",
                "2023-03-01 00:00:00",
                "2023-03-02 00:00:00",
                "2023-03-03 00:00:00",
                "2023-03-04 00:00:00",
                "2023-03-06 00:00:00",
                "2023-03-07 00:00:00",
                "2023-03-08 00:00:00",
                "2023-03-09 00:00:00",
                "2023-03-10 00:00:00",
                "2023-03-13 00:00:00",
                "2023-03-14 00:00:00",
                "2023-03-15 00:00:00",
                "2023-03-16 00:00:00",
                "2023-03-17 00:00:00",
                "2023-03-20 00:00:00",
                "2023-03-21 00:00:00",
                "2023-03-22 00:00:00",
                "2023-03-23 00:00:00",
                "2023-03-24 00:00:00",
                "2023-03-27 00:00:00",
                "2023-03-28 00:00:00",
                "2023-03-29 00:00:00",
                "2023-03-30 00:00:00",
                "2023-03-31 00:00:00",
                "2023-04-03 00:00:00",
                "2023-04-04 00:00:00",
                "2023-04-05 00:00:00",
                "2023-04-06 00:00:00",
                "2023-04-07 00:00:00",
                "2023-04-10 00:00:00",
                "2023-04-11 00:00:00",
                "2023-04-12 00:00:00",
                "2023-04-13 00:00:00",
                "2023-04-14 00:00:00",
                "2023-04-17 00:00:00",
                "2023-04-18 00:00:00",
                "2023-04-19 00:00:00",
                "2023-04-20 00:00:00",
                "2023-04-21 00:00:00",
                "2023-04-23 00:00:00",
                "2023-04-24 00:00:00",
                "2023-04-25 00:00:00",
                "2023-04-26 00:00:00",
                "2023-04-27 00:00:00",
                "2023-04-28 00:00:00",
                "2023-05-01 00:00:00",
                "2023-05-02 00:00:00",
                "2023-05-03 00:00:00",
                "2023-05-04 00:00:00",
                "2023-05-05 00:00:00",
                "2023-05-08 00:00:00",
                "2023-05-09 00:00:00",
                "2023-05-10 00:00:00",
                "2023-05-11 00:00:00",
                "2023-05-12 00:00:00",
                "2023-05-15 00:00:00",
                "2023-05-16 00:00:00",
                "2023-05-17 00:00:00",
                "2023-05-18 00:00:00",
                "2023-05-19 00:00:00",
                "2023-05-22 00:00:00",
                "2023-05-23 00:00:00",
                "2023-05-24 00:00:00",
                "2023-05-25 00:00:00",
                "2023-05-26 00:00:00",
                "2023-05-29 00:00:00",
                "2023-05-30 00:00:00",
                "2023-05-31 00:00:00",
                "2023-06-01 00:00:00",
                "2023-06-02 00:00:00",
                "2023-06-05 00:00:00",
                "2023-06-06 00:00:00",
                "2023-06-07 00:00:00",
                "2023-06-08 00:00:00",
                "2023-06-09 00:00:00",
                "2023-06-12 00:00:00",
                "2023-06-13 00:00:00",
                "2023-06-14 00:00:00",
                "2023-06-15 00:00:00",
                "2023-06-16 00:00:00",
                "2023-06-19 00:00:00",
                "2023-06-20 00:00:00",
                "2023-06-21 00:00:00",
                "2023-06-22 00:00:00",
                "2023-06-23 00:00:00",
                "2023-06-26 00:00:00",
                "2023-06-27 00:00:00",
                "2023-06-28 00:00:00",
                "2023-06-29 00:00:00",
                "2023-06-30 00:00:00",
                "2023-07-04 00:00:00",
                "2023-07-05 00:00:00",
                "2023-07-06 00:00:00",
                "2023-07-07 00:00:00",
                "2023-07-10 00:00:00",
                "2023-07-11 00:00:00",
                "2023-07-12 00:00:00",
                "2023-07-13 00:00:00",
                "2023-07-14 00:00:00",
                "2023-07-17 00:00:00",
                "2023-07-18 00:00:00",
                "2023-07-19 00:00:00",
                "2023-07-20 00:00:00",
                "2023-07-21 00:00:00",
                "2023-07-24 00:00:00",
                "2023-07-25 00:00:00",
                "2023-07-26 00:00:00",
                "2023-07-27 00:00:00",
                "2023-07-28 00:00:00",
                "2023-07-31 00:00:00",
                "2023-08-01 00:00:00",
                "2023-08-02 00:00:00",
                "2023-08-03 00:00:00",
                "2023-08-04 00:00:00",
                "2023-08-07 00:00:00",
                "2023-08-08 00:00:00",
                "2023-08-09 00:00:00",
                "2023-08-10 00:00:00",
                "2023-08-11 00:00:00",
                "2023-08-14 00:00:00",
                "2023-08-15 00:00:00",
                "2023-08-16 00:00:00",
                "2023-08-17 00:00:00",
                "2023-08-18 00:00:00",
                "2023-08-21 00:00:00",
                "2023-08-22 00:00:00",
                "2023-08-23 00:00:00",
                "2023-08-24 00:00:00"
            ],
            "ydata": {
                "daily_pnl": [
                    0,
                    0,
                    0,
                    -12.69,
                    -0.82,
                    -52.42,
                    0,
                    10.07,
                    17.57,
                    0,
                    0,
                    0,
                    -101.13,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    -7.95,
                    1.72,
                    -0.6,
                    0,
                    0,
                    0,
                    0,
                    0,
                    -3.29,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    17.03,
                    38.87,
                    3,
                    96.73,
                    6.42,
                    179.22,
                    -481.54,
                    -91.97,
                    -114.35,
                    -43.87,
                    79.86,
                    90.85,
                    64.95,
                    15.6,
                    115.86,
                    46.79,
                    -347.26,
                    -795.52,
                    -64.66,
                    18.9,
                    416.42,
                    27.86,
                    47.01,
                    178.87,
                    141.46,
                    -26.56,
                    185.73,
                    66.6,
                    -359.73,
                    -142.52,
                    197.72,
                    291.13,
                    -391.58,
                    896.77,
                    14.65,
                    337.02,
                    762.05,
                    -471.29,
                    484.68,
                    -194.58,
                    103.38,
                    314.77,
                    474.54,
                    53.2,
                    55.81,
                    32.17,
                    76.27,
                    78.75,
                    205.64,
                    166.3,
                    -1072.15,
                    606.51,
                    433.85,
                    154.39,
                    -51.37,
                    526.89,
                    319.72,
                    -603.19,
                    823.87,
                    225.22,
                    227.41,
                    -1426.23,
                    116.67,
                    36.48,
                    -712.6,
                    -444.64,
                    -146,
                    -358.35,
                    -1942.14,
                    -2061.75,
                    -907.39,
                    -561.68,
                    2224.25,
                    -858.89,
                    1252.12,
                    298.27,
                    2024.27,
                    1138.68
                ],
                "equity": [
                    0,
                    0,
                    0,
                    137.31,
                    136.49,
                    84.07,
                    84.07,
                    94.14,
                    112.22,
                    112.22,
                    112.22,
                    112.22,
                    11.09,
                    11.09,
                    11.09,
                    11.09,
                    11.09,
                    11.09,
                    11.09,
                    11.09,
                    11.09,
                    11.09,
                    11.09,
                    11.09,
                    11.09,
                    11.09,
                    11.09,
                    11.09,
                    11.09,
                    11.09,
                    11.09,
                    11.09,
                    11.09,
                    11.09,
                    11.09,
                    11.09,
                    11.09,
                    3.14,
                    4.86,
                    4.26,
                    4.26,
                    4.26,
                    4.26,
                    4.26,
                    4.26,
                    0.97,
                    0.97,
                    0.97,
                    0.97,
                    0.97,
                    0.97,
                    0.97,
                    0.97,
                    168,
                    207.32,
                    210.32,
                    307.05,
                    313.47,
                    489.07,
                    7.53,
                    65.24,
                    96.08,
                    49.97,
                    279.83,
                    520.68,
                    585.63,
                    601.23,
                    716.47,
                    762.95,
                    565.69,
                    224.44,
                    465.65,
                    495.67,
                    2501.86,
                    2529.72,
                    2576.73,
                    2755.6,
                    2897.61,
                    2875.82,
                    3061.93,
                    3128.53,
                    2768.8,
                    2627.43,
                    2832.51,
                    3124.9,
                    2733,
                    3639.23,
                    3651.48,
                    3987.54,
                    4762.56,
                    4290.31,
                    4775.03,
                    4555.77,
                    4675.73,
                    5010.15,
                    5509.86,
                    5563.06,
                    5618.87,
                    5651.04,
                    5688.5,
                    5774.01,
                    5984.16,
                    6153.53,
                    5079.39,
                    5676.82,
                    6117.36,
                    6368.72,
                    6307.94,
                    6841.36,
                    7144.99,
                    6557.8,
                    7362.9,
                    7590.65,
                    7590.65,
                    6400.8,
                    6506.47,
                    6463.86,
                    5753.79,
                    5329.18,
                    5184.23,
                    4820.9,
                    4465.68,
                    6095.46,
                    5199.61,
                    4648.43,
                    9197.3,
                    8366.36,
                    9590.12,
                    9893.68,
                    11173.87,
                    11942.46
                ],
                "balance": [
                    0,
                    0,
                    0,
                    137.31,
                    136.49,
                    102.3,
                    102.3,
                    96.61,
                    112.22,
                    112.22,
                    112.22,
                    112.22,
                    11.09,
                    11.09,
                    11.09,
                    11.09,
                    11.09,
                    11.09,
                    11.09,
                    11.09,
                    11.09,
                    11.09,
                    11.09,
                    11.09,
                    11.09,
                    11.09,
                    11.09,
                    11.09,
                    11.09,
                    11.09,
                    11.09,
                    11.09,
                    11.09,
                    11.09,
                    11.09,
                    11.09,
                    11.09,
                    3.14,
                    4.86,
                    4.26,
                    4.26,
                    4.26,
                    4.26,
                    4.26,
                    4.26,
                    0.97,
                    0.97,
                    0.97,
                    0.97,
                    0.97,
                    0.97,
                    0.97,
                    0.97,
                    168.66,
                    207.32,
                    210.32,
                    307.05,
                    378.53,
                    489.07,
                    16.15,
                    105.41,
                    167.54,
                    49.97,
                    279.83,
                    520.68,
                    585.63,
                    617.86,
                    718.26,
                    762.95,
                    970.36,
                    412.51,
                    775.97,
                    713.74,
                    2501.86,
                    2529.72,
                    2576.73,
                    2764.78,
                    2925.09,
                    2953.9,
                    3061.93,
                    3128.53,
                    3135.65,
                    3171.86,
                    3277.58,
                    3518.18,
                    3647.41,
                    4189.05,
                    4331.93,
                    4633.9,
                    5220.61,
                    4829.1,
                    5401.53,
                    5230.17,
                    5634.14,
                    5916.45,
                    5824.73,
                    5805.56,
                    5864.3,
                    5864.3,
                    5780.85,
                    5894.32,
                    6267.78,
                    6565.77,
                    6569.79,
                    6776.08,
                    6947.07,
                    7435.22,
                    7559.74,
                    7773.41,
                    7528.74,
                    7793.42,
                    8164.77,
                    8253.04,
                    8253.04,
                    8823.04,
                    9162.61,
                    9202.15,
                    9224.51,
                    9328.21,
                    10121.91,
                    11357.97,
                    13122.92,
                    17114.63,
                    18351.2,
                    18668.76,
                    22544.67,
                    23402,
                    23895.48,
                    24509.75,
                    23775.59,
                    23368.95
                ],
                "dpm": [
                    0,
                    0,
                    0,
                    -4133.86,
                    -1089.83,
                    -1907.04,
                    -1917.66,
                    -1459.32,
                    -839.18,
                    -839.22,
                    -838.9,
                    -838.61,
                    -2157.62,
                    -2158.15,
                    -2157.74,
                    -2157.89,
                    -2156.91,
                    -2156.65,
                    -2157.26,
                    -2156.7,
                    -2156.37,
                    -2156.34,
                    -2156.77,
                    -2156.4,
                    -2157.45,
                    -2157.35,
                    -2157.43,
                    -2157.22,
                    -2156.85,
                    -2156.65,
                    -2156.88,
                    -2157.09,
                    -2157.83,
                    -2157.86,
                    -2157.6,
                    -2157.3,
                    -2157.91,
                    -2107.54,
                    -1969.62,
                    -1926.88,
                    -1925.68,
                    -1925.44,
                    -1961.49,
                    -1925.69,
                    -1924.45,
                    -1917.06,
                    -1917.78,
                    -1924.37,
                    -1927.66,
                    -1925.09,
                    -1919.93,
                    -1918.75,
                    -1920.37,
                    -793.43,
                    -371.51,
                    -105.97,
                    5.38,
                    9.06,
                    121.33,
                    -153.49,
                    -198.06,
                    -249.76,
                    -253.07,
                    -203.25,
                    -155.99,
                    -124.99,
                    -112.73,
                    -63.9,
                    -44.8,
                    -163.22,
                    -401.59,
                    -409.78,
                    -390.36,
                    -252.54,
                    -239.42,
                    -221.24,
                    -166.05,
                    -127.03,
                    -131.9,
                    -82.96,
                    -66.1,
                    -151.03,
                    -182.47,
                    -130.68,
                    -62.49,
                    -141.2,
                    41.52,
                    43.46,
                    102.84,
                    222.52,
                    130.94,
                    196.75,
                    167.24,
                    173.48,
                    207.36,
                    263.99,
                    265.69,
                    269.53,
                    273.55,
                    280.09,
                    285.42,
                    299.1,
                    303.4,
                    179.38,
                    240.05,
                    276.32,
                    277.96,
                    268.39,
                    317.17,
                    337.29,
                    275.4,
                    340.61,
                    358.84,
                    374.23,
                    237.13,
                    228.19,
                    220.35,
                    167.74,
                    132.21,
                    116.34,
                    86.12,
                    -35.99,
                    -159.39,
                    -204.59,
                    -229.54,
                    -100.17,
                    -142.13,
                    -76.33,
                    -60.22,
                    41.53,
                    95.86
                ]
            }
        }
    };
    const returnVal = new Promise((resolve, _) => resolve(mockData));
    return returnVal;
}
