
export function getProfitLoss (query) {
    const mockData = {
        status: 200,
        data: [ {
            "login": "xxxxx04",
            "closed": -1180.09,
            "deposit": 0,
            "floating": 0,
            "book": "B",
            "group": "manager",
            "net": -1180.09
        },
        {
            "login": "xxxxx04",
            "closed": -3767.44,
            "deposit": 0,
            "floating": 0,
            "book": "Test",
            "group": "demoforex",
            "net": -3767.44
        },
        {
            "login": "xxxxx24",
            "closed": 1.83,
            "deposit": 0,
            "floating": 0,
            "book": "B",
            "group": "manager",
            "net": 1.83
        },
        {
            "login": "xxxxx01",
            "closed": 8.05,
            "deposit": 0,
            "floating": 0,
            "book": "B",
            "group": "demoforex",
            "net": 8.05
        },
        {
            "login": "xxxxx01",
            "closed": 2.82,
            "deposit": 0,
            "floating": 0,
            "book": "Test",
            "group": "demoforex",
            "net": 2.82
        },
        {
            "login": "xxxxx05",
            "closed": 1489.41,
            "deposit": 0,
            "floating": 0,
            "book": "B",
            "group": "demoforex",
            "net": 1489.41
        },
        {
            "login": "xxxxx43",
            "closed": -75.5,
            "deposit": 0,
            "floating": 0,
            "book": "B",
            "group": "system",
            "net": -75.5
        },
        {
            "login": "xxxxx43",
            "closed": -5.45,
            "deposit": 0,
            "floating": 0,
            "book": "S",
            "group": "preliminary",
            "net": -5.45
        },]
    };
    const returnVal = new Promise((resolve, _) => resolve(mockData));
    return returnVal;
}