
export function getCopyTradeConfig(query) {
    const mockData = {
        status: 200,
        data: [
            {
                "id": 3,
                "server_id": 1,
                "SourceLogin": "xxxxx39",
                "TargetLogin": "xxxxx40",
                "VerifyTimeRange": 300,
                "PartialFollowerTimeRange": 300,
                "MobileRatio": 20,
                "ClientRatio": 20,
                "ExpertRatio": 20,
                "TP": 0,
                "SL": 0,
                "IsReverse": 0,
                "IsFollowPendingOrder": 0,
                "PendingRatios": "10;21",
                "CommentPrefix": "AA-",
                "SymbolSuffix": "-R",
                "MinLotsTrigger": 1,
                "StopOrLimit": 0,
                "PendingPoints": "10;20",
                "created_at": "2023-03-14 11:15:26",
                "updated_at": "2023-03-14 11:17:19"
            },
            {
                "id": 4,
                "server_id": 1,
                "SourceLogin": "xxxxx17",
                "TargetLogin": "xxxxx18",
                "VerifyTimeRange": 300,
                "PartialFollowerTimeRange": 300,
                "MobileRatio": 10,
                "ClientRatio": 10,
                "ExpertRatio": 10,
                "TP": 0,
                "SL": 0,
                "IsReverse": 0,
                "IsFollowPendingOrder": 0,
                "PendingRatios": "10;22",
                "CommentPrefix": "AA-",
                "SymbolSuffix": "-R",
                "MinLotsTrigger": 1,
                "StopOrLimit": 0,
                "PendingPoints": "10;20",
                "created_at": "2023-03-14 11:15:27",
                "updated_at": "2023-03-14 11:17:20"
            },
            {
                "id": 5,
                "server_id": 2,
                "SourceLogin": "xxxxx90",
                "TargetLogin": "xxxxx91",
                "VerifyTimeRange": 300,
                "PartialFollowerTimeRange": 300,
                "MobileRatio": 20,
                "ClientRatio": 20,
                "ExpertRatio": 20,
                "TP": 0,
                "SL": 0,
                "IsReverse": 0,
                "IsFollowPendingOrder": 0,
                "PendingRatios": "10;21",
                "CommentPrefix": "AA-",
                "SymbolSuffix": "-R",
                "MinLotsTrigger": 1,
                "StopOrLimit": 0,
                "PendingPoints": "10;20",
                "created_at": "2023-03-14 11:15:26",
                "updated_at": "2023-03-14 11:17:19"
            }
        ]
    };
    const returnVal = new Promise((resolve, _) => resolve(mockData));
    return returnVal;
}

export function updateCopyTradeConfig(data){
    const mockData = {
        status: 200,
        data: { 'msg': 'Create Copy Trade Config successfully' }
    };
    const returnVal = new Promise((resolve, _) => resolve(mockData));
    return returnVal;
}

export function createCopyTradeConfig(data){
    const mockData = {
        status: 200,
        data: { 'msg': 'Update Copy Trade Config successfully' }
    };
    const returnVal = new Promise((resolve, _) => resolve(mockData));
    return returnVal;
}

export function deleteCopyTradeConfig(data){
    const mockData = {
        status: 200,
        data: { 'msg': 'Delete Copy Trade Config successfully' }
    };
    const returnVal = new Promise((resolve, _) => resolve(mockData));
    return returnVal;
}