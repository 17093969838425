
export function getOptions(type) {
    if (type === "group") {
        const mockData = {
            status: 200,
            data: [
                {
                    server_id: 1,
                    name: "manager",
                },
                {
                    server_id: 1,
                    name: "demoforex",
                },
                {
                    server_id: 1,
                    name: "preliminary",
                },
                {
                    server_id: 1,
                    name: "system",
                },
            ],
        };
        const returnVal = new Promise((resolve, _) => resolve(mockData));
        return returnVal;
    }
    if(type === "symbol"){
        const mockData = {
            status: 200,
            data: [
                {
                    "server_id": 1,
                    "name": "CHFJPY"
                },
                {
                    "server_id": 1,
                    "name": "RENUSD"
                },
                {
                    "server_id": 1,
                    "name": "CU11-R"
                },
                {
                    "server_id": 1,
                    "name": "USDHKD.e+"
                },
                {
                    "server_id": 1,
                    "name": "CHFJPY"
                },
                {
                    "server_id": 1,
                    "name": "WTIUSD"
                },
                {
                    "server_id": 2,
                    "name": "XAUUSD-Y"
                },
                {
                    "server_id": 2,
                    "name": "CADCHFs"
                },
                {
                    "server_id": 2,
                    "name": "USDNOK.+"
                },
                {
                    "server_id": 2,
                    "name": "DSHUSD"
                }
              ]
        };
        const returnVal = new Promise((resolve, _) => resolve(mockData));
        return returnVal;
    }
    if(type === "security"){
        const mockData = {
            status: 200,
            data: [
                {
                    "server_id": 1,
                    "name": "Forex Major"
                },
                {
                    "server_id": 1,
                    "name": "Forex Minor"
                },
                {
                    "server_id": 1,
                    "name": "Removed"
                },
                {
                    "server_id": 1,
                    "name": "Spot Metals"
                },
                {
                    "server_id": 1,
                    "name": "Indices"
                },
                {
                    "server_id": 1,
                    "name": "Forex\\STD/L"
                },
                {
                    "server_id": 1,
                    "name": "Metal\\STD/L"
                },
                {
                    "server_id": 2,
                    "name": "Futures\\Oil\\ECN/H"
                },
                {
                    "server_id": 2,
                    "name": "Oil\\ECN/H"
                },
                {
                    "server_id": 2,
                    "name": "Oil\\ECN/L"
                },
                {
                    "server_id": 2,
                    "name": "Oil\\STD/H"
                },
                {
                    "server_id": 2,
                    "name": "Futures\\Oil\\STD/H"
                }
              ]
        };
        const returnVal = new Promise((resolve, _) => resolve(mockData));
        return returnVal;
    }
    if(type === "login"){
        const mockData = {
            status: 200,
            data: [
                {
                    "server_id": 1,
                    "name": "xxxxxx50"
                },
                {
                    "server_id": 1,
                    "name": "xxxxxx07"
                },
                {
                    "server_id": 1,
                    "name": "xxxxxx80"
                },
                {
                    "server_id": 1,
                    "name": "xxxxxx55"
                },
                {
                    "server_id": 1,
                    "name": "xxxxxx92"
                },
                {
                    "server_id": 1,
                    "name": "xxxxxx52"
                },
                {
                    "server_id": 1,
                    "name": "xxxxxx40"
                },
                {
                    "server_id": 1,
                    "name": "xxxxxx46"
                },
                {
                    "server_id": 1,
                    "name": "xxxxxx78"
                },
                {
                    "server_id": 1,
                    "name": "xxxxxx36"
                },
                {
                    "server_id": 1,
                    "name": "xxxxxx81"
                },
                {
                    "server_id": 1,
                    "name": "xxxxxx25"
                },
                {
                    "server_id": 1,
                    "name": "xxxxxx71"
                }
            ]
        };
        const returnVal = new Promise((resolve, _) => resolve(mockData));
        return returnVal;
    }
    if(type === "user"){
        const mockData = {
            status: 200,
            data: [
                {
                    "name": "xxx.yyy"
                },
                {
                    "name": "demo.demo"
                },
                {
                    "name": "123.456"
                },
                {
                    "name": "abc.efg"
                }
            ]
        };
        const returnVal = new Promise((resolve, _) => resolve(mockData));
        return returnVal;
    }
}
