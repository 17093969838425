import { getDailyWinner } from "@services/alert/daily-winner";
import common from "@assets/js/common.js";

const state = {
    autoRefreshTimeOffset: 1,
    loading: false,
    alertData: [
        {
            "books": "Test",
            "broker": "Demo",
            "force": false,
            "messageType": "MSG_TYPE_MONITOR_DAILY_WINNER_LOSER",
            "priority": 1,
            "profitClosed": 129.26,
            "profitClosedMT4": 129.26,
            "recordDate": "2024-08-05",
            "threshold": 100,
            "timestamp": 1722843054,
            "user": "Demo:MT4-Demo:xxxxx60:Tri",
            "server_name": "MT4-Demo",
            "login": "xxxxx60",
            "name": "Tri",
            "closed_profit_eod": 129.26,
            "closed_profit": 129.26
        },
        {
            "books": "Test",
            "broker": "Demo",
            "force": false,
            "messageType": "MSG_TYPE_MONITOR_DAILY_WINNER_LOSER",
            "priority": 1,
            "profitClosed": 5682,
            "profitClosedMT4": 491,
            "recordDate": "2024-08-05",
            "threshold": 100,
            "timestamp": 1722843107,
            "user": "Demo:MT4-Demo:xxxxx87:Tin",
            "server_name": "MT4-Demo",
            "login": "xxxxx87",
            "name": "Tin",
            "closed_profit_eod": 5682,
            "closed_profit": 491
        },
        {
            "books": "Test",
            "broker": "Demo",
            "force": false,
            "messageType": "MSG_TYPE_MONITOR_DAILY_WINNER_LOSER",
            "priority": 1,
            "profitClosed": 271.3,
            "profitClosedMT4": 271.3,
            "recordDate": "2024-08-05",
            "threshold": 100,
            "timestamp": 1722843073,
            "user": "Demo:MT4-Demo:xxxxx13:Dao",
            "server_name": "MT4-Demo",
            "login": "xxxxx13",
            "name": "Dao",
            "closed_profit_eod": 271.3,
            "closed_profit": 271.3
        },
        {
            "books": "B",
            "broker": "Demo",
            "force": false,
            "messageType": "MSG_TYPE_MONITOR_DAILY_WINNER_LOSER",
            "priority": 1,
            "profitClosed": 1208.82,
            "profitClosedMT4": 2972.47,
            "recordDate": "2024-08-05",
            "threshold": 100,
            "timestamp": 1722843086,
            "user": "Demo:MT4-Demo:xxxxx25:0xb",
            "server_name": "MT4-Demo",
            "login": "xxxxx25",
            "name": "0xb",
            "closed_profit_eod": 1208.82,
            "closed_profit": 2972.47
        },
        {
            "books": "Test",
            "broker": "Demo",
            "force": false,
            "messageType": "MSG_TYPE_MONITOR_DAILY_WINNER_LOSER",
            "priority": 1,
            "profitClosed": 131.73,
            "profitClosedMT4": 131.73,
            "recordDate": "2024-08-05",
            "threshold": 100,
            "timestamp": 1722843073,
            "user": "Demo:MT4-Demo:xxxxx98:Le ",
            "server_name": "MT4-Demo",
            "login": "xxxxx98",
            "name": "Le ",
            "closed_profit_eod": 131.73,
            "closed_profit": 131.73
        },
        {
            "books": "B",
            "broker": "Demo",
            "force": false,
            "messageType": "MSG_TYPE_MONITOR_DAILY_WINNER_LOSER",
            "priority": 1,
            "profitClosed": 216.43,
            "profitClosedMT4": 249.43,
            "recordDate": "2024-08-05",
            "threshold": 100,
            "timestamp": 1722843055,
            "user": "Demo:MT4-Demo:xxxxx33:NGU",
            "server_name": "MT4-Demo",
            "login": "xxxxx33",
            "name": "NGU",
            "closed_profit_eod": 216.43,
            "closed_profit": 249.43
        },
        {
            "books": "B",
            "broker": "Demo",
            "force": false,
            "messageType": "MSG_TYPE_MONITOR_DAILY_WINNER_LOSER",
            "priority": 1,
            "profitClosed": 870.45,
            "profitClosedMT4": 821.16,
            "recordDate": "2024-08-05",
            "threshold": 100,
            "timestamp": 1722843057,
            "user": "Demo:MT4-Demo:xxxxx05:0xc",
            "server_name": "MT4-Demo",
            "login": "xxxxx05",
            "name": "0xc",
            "closed_profit_eod": 870.45,
            "closed_profit": 821.16
        },
        {
            "books": "B",
            "broker": "Demo",
            "force": false,
            "messageType": "MSG_TYPE_MONITOR_DAILY_WINNER_LOSER",
            "priority": 1,
            "profitClosed": 320.33,
            "profitClosedMT4": 1495.33,
            "recordDate": "2024-08-05",
            "threshold": 100,
            "timestamp": 1722843054,
            "user": "Demo:MT4-Demo:xxxxx38:Luu",
            "server_name": "MT4-Demo",
            "login": "xxxxx38",
            "name": "Luu",
            "closed_profit_eod": 320.33,
            "closed_profit": 1495.33
        },
        {
            "books": "B",
            "broker": "Demo",
            "force": false,
            "messageType": "MSG_TYPE_MONITOR_DAILY_WINNER_LOSER",
            "priority": 1,
            "profitClosed": 202.08,
            "profitClosedMT4": 202.08,
            "recordDate": "2024-08-05",
            "threshold": 100,
            "timestamp": 1722843054,
            "user": "Demo:MT4-Demo:xxxxx86:BUI",
            "server_name": "MT4-Demo",
            "login": "xxxxx86",
            "name": "BUI",
            "closed_profit_eod": 202.08,
            "closed_profit": 202.08
        },
        {
            "books": "Test",
            "broker": "Demo",
            "force": false,
            "messageType": "MSG_TYPE_MONITOR_DAILY_WINNER_LOSER",
            "priority": 1,
            "profitClosed": 162.93,
            "profitClosedMT4": 162.93,
            "recordDate": "2024-08-05",
            "threshold": 100,
            "timestamp": 1722843069,
            "user": "Demo:MT4-Demo:xxxxx26:Hoa",
            "server_name": "MT4-Demo",
            "login": "xxxxx26",
            "name": "Hoa",
            "closed_profit_eod": 162.93,
            "closed_profit": 162.93
        },
        {
            "books": "Test",
            "broker": "Demo",
            "force": false,
            "messageType": "MSG_TYPE_MONITOR_DAILY_WINNER_LOSER",
            "priority": 1,
            "profitClosed": 6710.7,
            "profitClosedMT4": 1374.94,
            "recordDate": "2024-08-05",
            "threshold": 100,
            "timestamp": 1722843057,
            "user": "Demo:MT4-Demo:xxxxx82:Blu",
            "server_name": "MT4-Demo",
            "login": "xxxxx82",
            "name": "Blu",
            "closed_profit_eod": 6710.7,
            "closed_profit": 1374.94
        },
        {
            "books": "Test",
            "broker": "Demo",
            "force": false,
            "messageType": "MSG_TYPE_MONITOR_DAILY_WINNER_LOSER",
            "priority": 1,
            "profitClosed": 142.85,
            "profitClosedMT4": 100.58,
            "recordDate": "2024-08-05",
            "threshold": 100,
            "timestamp": 1722843072,
            "user": "Demo:MT4-Demo:xxxxx35:Ngu",
            "server_name": "MT4-Demo",
            "login": "xxxxx35",
            "name": "Ngu",
            "closed_profit_eod": 142.85,
            "closed_profit": 100.58
        },
        {
            "books": "B",
            "broker": "Demo",
            "force": false,
            "messageType": "MSG_TYPE_MONITOR_DAILY_WINNER_LOSER",
            "priority": 1,
            "profitClosed": 115.14,
            "profitClosedMT4": 66.9,
            "recordDate": "2024-08-05",
            "threshold": 100,
            "timestamp": 1722843081,
            "user": "Demo:MT4-Demo:xxxxx89:0xc",
            "server_name": "MT4-Demo",
            "login": "xxxxx89",
            "name": "0xc",
            "closed_profit_eod": 115.14,
            "closed_profit": 66.9
        },
        {
            "books": "Test",
            "broker": "Demo",
            "force": false,
            "messageType": "MSG_TYPE_MONITOR_DAILY_WINNER_LOSER",
            "priority": 1,
            "profitClosed": 200,
            "profitClosedMT4": 200,
            "recordDate": "2024-08-05",
            "threshold": 100,
            "timestamp": 1722843069,
            "user": "Demo:MT4-Demo:xxxxx63:Tri",
            "server_name": "MT4-Demo",
            "login": "xxxxx63",
            "name": "Tri",
            "closed_profit_eod": 200,
            "closed_profit": 200
        },
        {
            "books": "Test",
            "broker": "Demo",
            "force": false,
            "messageType": "MSG_TYPE_MONITOR_DAILY_WINNER_LOSER",
            "priority": 1,
            "profitClosed": 512.55,
            "profitClosedMT4": 512.55,
            "recordDate": "2024-08-05",
            "threshold": 100,
            "timestamp": 1722843073,
            "user": "Demo:MT4-Demo:xxxxx77:NGU",
            "server_name": "MT4-Demo",
            "login": "xxxxx77",
            "name": "NGU",
            "closed_profit_eod": 512.55,
            "closed_profit": 512.55
        },
        {
            "books": "Test",
            "broker": "Demo",
            "force": false,
            "messageType": "MSG_TYPE_MONITOR_DAILY_WINNER_LOSER",
            "priority": 1,
            "profitClosed": 116.06,
            "profitClosedMT4": 111.96,
            "recordDate": "2024-08-05",
            "threshold": 100,
            "timestamp": 1722843079,
            "user": "Demo:MT4-Demo:xxxxx61:PHA",
            "server_name": "MT4-Demo",
            "login": "xxxxx61",
            "name": "PHA",
            "closed_profit_eod": 116.06,
            "closed_profit": 111.96
        },
        {
            "books": "Test",
            "broker": "Demo",
            "force": false,
            "messageType": "MSG_TYPE_MONITOR_DAILY_WINNER_LOSER",
            "priority": 1,
            "profitClosed": 2263.8,
            "profitClosedMT4": 3910.2,
            "recordDate": "2024-08-05",
            "threshold": 100,
            "timestamp": 1722843055,
            "user": "Demo:MT4-Demo:xxxxx60:0xc",
            "server_name": "MT4-Demo",
            "login": "xxxxx60",
            "name": "0xc",
            "closed_profit_eod": 2263.8,
            "closed_profit": 3910.2
        },
        {
            "books": "Test",
            "broker": "Demo",
            "force": false,
            "messageType": "MSG_TYPE_MONITOR_DAILY_WINNER_LOSER",
            "priority": 1,
            "profitClosed": 242.21,
            "profitClosedMT4": 242.21,
            "recordDate": "2024-08-05",
            "threshold": 100,
            "timestamp": 1722843081,
            "user": "Demo:MT4-Demo:xxxxx64:Do ",
            "server_name": "MT4-Demo",
            "login": "xxxxx64",
            "name": "Do ",
            "closed_profit_eod": 242.21,
            "closed_profit": 242.21
        },
        {
            "books": "Test",
            "broker": "Demo",
            "force": false,
            "messageType": "MSG_TYPE_MONITOR_DAILY_WINNER_LOSER",
            "priority": 1,
            "profitClosed": 4582.99,
            "profitClosedMT4": 5340.49,
            "recordDate": "2024-08-05",
            "threshold": 100,
            "timestamp": 1722843054,
            "user": "Demo:MT4-Demo:xxxxx75:Xin",
            "server_name": "MT4-Demo",
            "login": "xxxxx75",
            "name": "Xin",
            "closed_profit_eod": 4582.99,
            "closed_profit": 5340.49
        },
        {
            "books": "B",
            "broker": "Demo",
            "force": false,
            "messageType": "MSG_TYPE_MONITOR_DAILY_WINNER_LOSER",
            "priority": 1,
            "profitClosed": 114.6,
            "profitClosedMT4": 114.6,
            "recordDate": "2024-08-05",
            "threshold": 100,
            "timestamp": 1722843084,
            "user": "Demo:MT4-Demo:xxxxx02:0xb",
            "server_name": "MT4-Demo",
            "login": "xxxxx02",
            "name": "0xb",
            "closed_profit_eod": 114.6,
            "closed_profit": 114.6
        },
        {
            "books": "Test",
            "broker": "Demo",
            "force": false,
            "messageType": "MSG_TYPE_MONITOR_DAILY_WINNER_LOSER",
            "priority": 1,
            "profitClosed": 156.58,
            "profitClosedMT4": 142.03,
            "recordDate": "2024-08-05",
            "threshold": 100,
            "timestamp": 1722843072,
            "user": "Demo:MT4-Demo:xxxxx55:Le ",
            "server_name": "MT4-Demo",
            "login": "xxxxx55",
            "name": "Le ",
            "closed_profit_eod": 156.58,
            "closed_profit": 142.03
        },
        {
            "books": "B",
            "broker": "Demo",
            "force": false,
            "messageType": "MSG_TYPE_MONITOR_DAILY_WINNER_LOSER",
            "priority": 1,
            "profitClosed": 392.06,
            "profitClosedMT4": 392.06,
            "recordDate": "2024-08-05",
            "threshold": 100,
            "timestamp": 1722843054,
            "user": "Demo:MT4-Demo:xxxxx37:0xb",
            "server_name": "MT4-Demo",
            "login": "xxxxx37",
            "name": "0xb",
            "closed_profit_eod": 392.06,
            "closed_profit": 392.06
        }
    ],
    alertHeader: [
        { text: "Login", value: "login", align: "left" },
        { text: "Name", value: "name", align: "left" },
        { text: "Books", value: "books", align: "left" },
        {
            text: "Closed Profit EOD",
            value: "closed_profit_eod",
            align: "right",
        },
    ],
    alertDataAmount: 0,
    csvHeader: {
        "Server Name": "server_name",
        Login: "login",
        Books: "books",
        "Closed Profit EOD": "closed_profit_eod",
        "Closed Profit MT": "closed_profit",
    },
    selectedDate: null,
};

const actions = {
    getDailyWinnerAction({ commit, state }, data) {
        if (common.getMT4TimeString().split(" ")[0] !== state.selectedDate) {
            commit("UPDATE_LOADING", true);
            getDailyWinner(data).then((res) => {
                res.data.data.forEach((item) => {
                    item["books"] = item.books.replace(/[[\]]/g, "");
                });
                commit("UPDATE_ALERT_DATA", res.data.data);
                commit("UPDATE_AMOUNT", res.data.count);
                commit("UPDATE_LOADING", false);
            });
        }
    },
    processDailyWinner({ commit, state }, data) {
        if (common.getMT4TimeString().split(" ")[0] === state.selectedDate) {
            commit("UPDATE_LOADING", true);
            let result = [];
            for (let item in data) {
                if (item.includes("--winner")) {
                    let temp = data[item];
                    temp.books = temp.books.join(",");
                    temp.server_name = temp.user.split(":")[1];
                    temp.login = temp.user.split(":")[2];
                    temp.name = item.replaceAll('--winner','').split(":").slice(3).join(" ");
                    temp.closed_profit_eod = temp.profitClosed;
                    temp.closed_profit = temp.profitClosedMT4;
                    result.push(temp);
                }
            }
            commit("UPDATE_ALERT_DATA", result);
            commit("UPDATE_AMOUNT", result.length);
            commit("UPDATE_LOADING", false);
        }
    },
};

const mutations = {
    /**
     * Update loading
     * @param   {[type]}  state  [state description]
     * @param   {[type]}  data   [data description]
     * @return  {[type]}         [return description]
     */
    UPDATE_LOADING(state, data) {
        state.loading = data;
    },
    UPDATE_ALERT_DATA(state, data) {
        state.alertData = data;
    },
    UPDATE_ALERT_HEADER(state, data) {
        state.alertHeader = data;
    },
    UPDATE_SELECTED_DATE(state, data) {
        state.selectedDate = data;
    },
    UPDATE_AMOUNT(state, data) {
        state.alertDataAmount = data;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
