
export function getUsers(query) {
    const mockData = {
        status: 200,
        data: [
            {
                id: 27,
                email: "abc.efg@bfjfunds.com.au",
                password:
                    "$pbkdf2-sha256$29000$a42x9v4fY2wtpdQ6pxRiDA$xZKNygOTPQyyxeBcJKP.u00pEORLHPqJqoxPCd.KJhA",
                last_login: "2023-08-22 12:51:36",
                username: "abc.efg",
                first_name: "abc",
                last_name: "efg",
                is_active: true,
                permission_id: 1,
                access_token: null,
                refresh_token: null,
                role: null,
                jti: null,
                mobile: null,
                broker_id: null,
                home_page_profile_id: 0,
                alert_page_profile_id: 0,
                created_at: "2023-03-14 14:09:26",
                updated_at: "2023-08-22 12:51:37",
            },
            {
                id: 20,
                email: "123@sigmarisk.com.au",
                password:
                    "$pbkdf2-sha256$29000$X2uNMcY4h5DSuteas/aeEw$vc7HDdpWg/nU3rknd91LRY8wQ6FOGIpG8SY1RoptNRk",
                last_login: "2023-08-01 13:22:42",
                username: "123.456",
                first_name: "123",
                last_name: "456",
                is_active: true,
                permission_id: 1,
                access_token: null,
                refresh_token: null,
                role: null,
                jti: null,
                mobile: null,
                broker_id: null,
                home_page_profile_id: null,
                alert_page_profile_id: null,
                created_at: "2022-09-17 20:49:49",
                updated_at: "2023-08-01 13:22:46",
            },
            {
                id: 21,
                email: "demo.demo@sigmatm.com.au",
                password:
                    "$pbkdf2-sha256$29000$a80Z47wXwvh/r/U.57zXmg$.zdYkiFjo97n7uCwLn79SnX/HMQ0s55ftkKwIrgOmQw",
                last_login: "2023-02-22 14:05:06",
                username: "demo.demo",
                first_name: "demo",
                last_name: "demo",
                is_active: true,
                permission_id: 1,
                access_token: null,
                refresh_token: null,
                role: null,
                jti: null,
                mobile: null,
                broker_id: null,
                home_page_profile_id: 23,
                alert_page_profile_id: 22,
                created_at: "2022-08-23 23:42:37",
                updated_at: "2023-02-22 14:55:23",
            },
            {
                id: 9,
                email: "xxx.yyy@sigmatm.com.au",
                password:
                    "$pbkdf2-sha256$29000$q5Wy1jqnFOI8p3SuFYJQ6g$andKITPvGoE9cKceEoW78ejH.R/0rhw832MEWTnfH4c",
                last_login: "2023-08-23 00:10:17",
                username: "xxx.yyy",
                first_name: "xxx",
                last_name: "yyy",
                is_active: true,
                permission_id: 2,
                access_token:
                    "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJmcmVzaCI6ZmFsc2UsImlhdCI6MTY1OTQ0ODcwOCwianRpIjoiNTk4MWNmOWUtZjQ1Mi00ZDQwLWFmMTQtNjE0ODYxOWJmODVjIiwidHlwZSI6ImFjY2VzcyIsInN1YiI6InN1cGVybWFuLml2ZXJzb25AZ21haWwuY29tIiwibmJmIjoxNjU5NDQ4NzA4LCJleHAiOjE2NTk5ODg3MDh9.ZDVYGQmikQiOtRg1s1vvKA-29jgpVew1vOffB409ztA",
                refresh_token:
                    "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJmcmVzaCI6ZmFsc2UsImlhdCI6MTY1OTQ0ODcwOCwianRpIjoiYTBjNmViZGEtNDA5MS00OGM0LTlmNWEtZDU4MzY5NWYwZDFkIiwidHlwZSI6InJlZnJlc2giLCJzdWIiOiJzdXBlcm1hbi5pdmVyc29uQGdtYWlsLmNvbSIsIm5iZiI6MTY1OTQ0ODcwOCwiZXhwIjoxNjYyMDQwNzA4fQ.fCmm7Ns5Zc9IVgdp4gficU0fIh86nPlSvhD-pYoe1xA",
                role: "root",
                jti: null,
                mobile: "6142345678",
                broker_id: null,
                home_page_profile_id: 7,
                alert_page_profile_id: 0,
                created_at: "2022-06-23 20:24:29",
                updated_at: "2023-08-23 00:10:17",
            },
        ],
    };
    const returnVal = new Promise((resolve, _) => resolve(mockData));
    return returnVal;
}

export function postUsers(query) {
    const mockData = {
        status: 200,
        data: { 'msg': 'Create user successfully' }
    };
    const returnVal = new Promise((resolve, _) => resolve(mockData));
    return returnVal;
}

export function putUsers(query) {
    const mockData = {
        status: 200,
        data: { 'msg': 'Update user successfully' }
    };
    const returnVal = new Promise((resolve, _) => resolve(mockData));
    return returnVal;
}

export function deleteUsers(query) {
    const mockData = {
        status: 200,
        data: { 'msg': 'Delete user successfully' }
    };
    const returnVal = new Promise((resolve, _) => resolve(mockData));
    return returnVal;
}
