export function getLoginTrades(query) {
    if (query.liveOrHis === true) {
        const mockData = {
            status: 200,
            data: [
                {
                    "broker": "Demo",
                    "closePrice": 2454.95,
                    "closeTime": 0,
                    "cmd": 0,
                    "comment": "",
                    "commission": 0,
                    "contractSize": 100,
                    "convRates": [
                        1,
                        0
                    ],
                    "currentVolume": 0,
                    "digits": 2,
                    "force": false,
                    "login": "xxxxx84",
                    "magic": 0,
                    "openPrice": 2455.46,
                    "openTime": 1722843726,
                    "order": "yyyy78",
                    "platform": "MT4",
                    "positionID": 0,
                    "priority": 1,
                    "profit": -765,
                    "profitMode": 1,
                    "reason": 5,
                    "server": "MT4-Demo",
                    "sl": 0,
                    "storage": 0,
                    "symbol": "XAUUSD-P",
                    "timestamp": 1722843726,
                    "todayPartialClosedPrice": 0,
                    "todayPartialClosedVolume": 0,
                    "tp": 0,
                    "tradeExtraInfoOZ": {
                        "percentageInA": 100,
                        "percentageInAName": [
                            "TEST"
                        ],
                        "percentageInB": 0,
                        "percentageInBName": [
                            "UNKNOWN_B"
                        ]
                    },
                    "tradePriceInfo": {
                        "currentAsk": 2456.6,
                        "currentBid": 2456.41,
                        "eodAsk": 2442.31,
                        "eodBid": 2442.31,
                        "eodPriceTime": 1722556800,
                        "eodServerId": 1,
                        "exchangeAsk": 1,
                        "exchangeBid": 1,
                        "exchangeSymbol": "USDUSD"
                    },
                    "tradeProfitInfo": {
                        "exposure": 3684615,
                        "exposureBuy": "XAU",
                        "exposureSell": "USD",
                        "exposureVolume": 3683190,
                        "profitBeforeToday": 0,
                        "profitEOD": 1425,
                        "profitEODPartialClosedToday": 0,
                        "profitTotal": 1425,
                        "profitTotalPartialClosed": 0,
                        "profitTotalPartialClosedToday": 0
                    },
                    "typeName": "XAU-P",
                    "version": {
                        "version": 10
                    },
                    "volume": 1500,
                    "volumeWeight": 1,
                    "weightedVolume": 15
                }
            ]
        };
        const returnVal = new Promise((resolve, _) => resolve(mockData));
        return returnVal;
    } else {
        const mockData = {
            status: 200,
            data: [
                {
                    "broker": "Demo",
                    "closePrice": 18230.28,
                    "closeTime": 1722820934,
                    "cmd": 1,
                    "comment": "",
                    "commission": 0,
                    "contractSize": 1,
                    "convRates": [
                        1,
                        1
                    ],
                    "currentVolume": 0,
                    "digits": 2,
                    "force": false,
                    "login": "xxxxx75",
                    "magic": 0,
                    "openPrice": 18481.89,
                    "openTime": 1722626544,
                    "order": "yyyyyy14",
                    "platform": "MT4",
                    "positionID": 21561514,
                    "priority": 1,
                    "profit": 6290.25,
                    "profitMode": 1,
                    "reason": 5,
                    "server": "MT4-Demo",
                    "sl": 0,
                    "storage": 57.38,
                    "symbol": "USTEC",
                    "timestamp": 1722820934,
                    "todayPartialClosedPrice": 0,
                    "todayPartialClosedVolume": 0,
                    "tp": 0,
                    "tradeExtraInfoOZ": {
                        "percentageInA": 100,
                        "percentageInAName": [
                            "TEST"
                        ],
                        "percentageInB": 0,
                        "percentageInBName": [
                            "UNKNOWN_B"
                        ]
                    },
                    "tradePriceInfo": {
                        "currentAsk": 17848.03,
                        "currentBid": 17843.67,
                        "eodAsk": 18392.99,
                        "eodBid": 18392.99,
                        "eodPriceTime": 1722556800,
                        "eodServerId": 1,
                        "exchangeAsk": 1,
                        "exchangeBid": 1,
                        "exchangeSymbol": "USDUSD"
                    },
                    "tradeProfitInfo": {
                        "exposure": -446200.75,
                        "exposureBuy": "USTEC",
                        "exposureSell": "USD",
                        "exposureVolume": 462047.25,
                        "profitBeforeToday": 2222.5,
                        "profitEOD": 4067.75,
                        "profitEODPartialClosedToday": 0,
                        "profitTotal": 6290.25,
                        "profitTotalPartialClosed": 0,
                        "profitTotalPartialClosedToday": 0
                    },
                    "typeName": "Index",
                    "version": {
                        "version": 1
                    },
                    "volume": 2500,
                    "volumeWeight": 1,
                    "weightedVolume": 25
                },
                {
                    "broker": "Demo",
                    "closePrice": 18229.78,
                    "closeTime": 1722820939,
                    "cmd": 1,
                    "comment": "",
                    "commission": 0,
                    "contractSize": 1,
                    "convRates": [
                        1,
                        1
                    ],
                    "currentVolume": 0,
                    "digits": 2,
                    "force": false,
                    "login": "xxxxx75",
                    "magic": 0,
                    "openPrice": 18334.39,
                    "openTime": 1722622975,
                    "order": "yyyyyy82",
                    "platform": "MT4",
                    "positionID": 21553382,
                    "priority": 1,
                    "profit": 2615.25,
                    "profitMode": 1,
                    "reason": 5,
                    "server": "MT4-Demo",
                    "sl": 0,
                    "storage": 57.38,
                    "symbol": "USTEC",
                    "timestamp": 1722820939,
                    "todayPartialClosedPrice": 0,
                    "todayPartialClosedVolume": 0,
                    "tp": 0,
                    "tradeExtraInfoOZ": {
                        "percentageInA": 100,
                        "percentageInAName": [
                            "TEST"
                        ],
                        "percentageInB": 0,
                        "percentageInBName": [
                            "UNKNOWN_B"
                        ]
                    },
                    "tradePriceInfo": {
                        "currentAsk": 17856.03,
                        "currentBid": 17852.67,
                        "eodAsk": 18392.99,
                        "eodBid": 18392.99,
                        "eodPriceTime": 1722556800,
                        "eodServerId": 1,
                        "exchangeAsk": 1,
                        "exchangeBid": 1,
                        "exchangeSymbol": "USDUSD"
                    },
                    "tradeProfitInfo": {
                        "exposure": -446400.75,
                        "exposureBuy": "USTEC",
                        "exposureSell": "USD",
                        "exposureVolume": 458359.75,
                        "profitBeforeToday": -1465,
                        "profitEOD": 4080.25,
                        "profitEODPartialClosedToday": 0,
                        "profitTotal": 2615.25,
                        "profitTotalPartialClosed": 0,
                        "profitTotalPartialClosedToday": 0
                    },
                    "typeName": "Index",
                    "version": {
                        "version": 1
                    },
                    "volume": 2500,
                    "volumeWeight": 1,
                    "weightedVolume": 25
                }
            ]
        };
        const returnVal = new Promise((resolve, _) => resolve(mockData));
        return returnVal;
    }
}
