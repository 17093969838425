import common from "@assets/js/common";
export function getLPAccount(query) {
    const mockData = {
        status: 200,
        data: [
            {
                LP: "lp-1",
                Balance: 556239.41,
                Credit: 0,
                "Unrealized P&L": -28059.87,
                Equity: 528179.54,
                Margin: 300234.52,
                "Free Margin": 227945.03,
                "Margin Utilization %": 56.84,
                updated_at: common.getMT4TimeString(),
            },
            {
                LP: "lp-2",
                Balance: "125007.72",
                Credit: "0",
                "Unrealized P&L": "5981.14",
                Equity: "130988.86",
                Margin: "22594.62",
                "Free Margin": "108394.24",
                "Margin Utilization %": "17.249",
                updated_at: common.getMT4TimeString(),
            },
        ],
    };
    const returnVal = new Promise((resolve, _) => resolve(mockData));
    return returnVal;
}
