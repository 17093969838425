
export function getLoginGroupChange(query) {
    const mockData = {
        status: 200,
        data: [
            {
                "id": 2204,
                "server_id": 1,
                "login": "xxxxxx43",
                "from_group": "system",
                "to_group": "manager",
                "created_at": "2023-02-26 05:09:53",
                "updated_at": "2023-02-26 05:09:53"
            }
        ]
    };
    const returnVal = new Promise((resolve, _) => resolve(mockData));
    return returnVal;
}
