
export function getDataWatchList(query) {
    const mockData = {
        status: 200,
        data: {
            "data": [
                {
                    "login": "xxxxxxx29",
                    "name": "Sutula",
                    "server_id": 26,
                    "symbol": "GBPJPY",
                    "order": 1012961935,
                    "books": "[B]",
                    "direction": "LONG",
                    "volume": 0.2,
                    "open_time": "2024-08-05 01:27:19",
                    "close_time": "1970-01-01 00:00:00",
                    "open_price": 187.222,
                    "close_price": 0,
                    "server_name": "MT4_Demo",
                    "trigger_time": "2024-08-05 01:27:19"
                },
                {
                    "login": "xxxxxxx88",
                    "name": "Yen",
                    "server_id": 26,
                    "symbol": "EURUSD",
                    "order": 1012812044,
                    "books": "[B]",
                    "direction": "SHORT",
                    "volume": 0.03,
                    "open_time": "2024-08-05 01:26:39",
                    "close_time": "1970-01-01 00:00:00",
                    "open_price": 1.09066,
                    "close_price": 0,
                    "server_name": "MT4_Demo",
                    "trigger_time": "2024-08-05 01:26:39"
                },
                {
                    "login": "xxxxxxx67",
                    "name": "Maude",
                    "server_id": 26,
                    "symbol": "XAUUSD",
                    "order": 1012961948,
                    "books": "[B]",
                    "direction": "LONG",
                    "volume": 0.02,
                    "open_time": "2024-08-05 01:27:20",
                    "close_time": "1970-01-01 00:00:00",
                    "open_price": 2439.04,
                    "close_price": 0,
                    "server_name": "MT4_Demo",
                    "trigger_time": "2024-08-05 01:27:20"
                },
                {
                    "login": "xxxxxxx94",
                    "name": "Ritsert",
                    "server_id": 26,
                    "symbol": "USDJPY",
                    "order": 1012961944,
                    "books": "[B]",
                    "direction": "LONG",
                    "volume": 0.01,
                    "open_time": "2024-08-05 01:27:20",
                    "close_time": "1970-01-01 00:00:00",
                    "open_price": 146.357,
                    "close_price": 0,
                    "server_name": "MT4_Demo",
                    "trigger_time": "2024-08-05 01:27:20"
                },
                {
                    "login": "xxxxxxx68",
                    "name": "Nana",
                    "server_id": 26,
                    "symbol": "USTEC",
                    "order": 1012961494,
                    "books": "[B]",
                    "direction": "LONG",
                    "volume": 0.5,
                    "open_time": "2024-08-05 01:26:19",
                    "close_time": "1970-01-01 00:00:00",
                    "open_price": 18210.9,
                    "close_price": 0,
                    "server_name": "MT4_Demo",
                    "trigger_time": "2024-08-05 01:26:19"
                },
                {
                    "login": "xxxxxxx31",
                    "name": "zhao",
                    "server_id": 26,
                    "symbol": "USTEC",
                    "order": 1012961951,
                    "books": "[B]",
                    "direction": "LONG",
                    "volume": 0.5,
                    "open_time": "2024-08-05 01:27:23",
                    "close_time": "1970-01-01 00:00:00",
                    "open_price": 18215.1,
                    "close_price": 0,
                    "server_name": "MT4_Demo",
                    "trigger_time": "2024-08-05 01:27:23"
                },
                {
                    "login": "xxxxxxx89",
                    "name": "Rodriguez",
                    "server_id": 26,
                    "symbol": "USTEC",
                    "order": 1012961916,
                    "books": "[B]",
                    "direction": "LONG",
                    "volume": 0.5,
                    "open_time": "2024-08-05 01:27:15",
                    "close_time": "1970-01-01 00:00:00",
                    "open_price": 18214.8,
                    "close_price": 0,
                    "server_name": "MT4_Demo",
                    "trigger_time": "2024-08-05 01:27:15"
                }
            ],
            "count": 7
        }
    };
    const returnVal = new Promise((resolve, _) => resolve(mockData));
    return returnVal;
}
