
export function getTradeOnCredit(query) {
    const mockData = {
        status: 200,
        data: {
            "data": [
                {
                    "server_id": 1,
                    "server_name": "MT4-Demo",
                    "login": "xxxxxx2",
                    "equity_credit_ratio_threshold": 1,
                    "books": "[B]",
                    "credit": 220.24,
                    "equity": -118.45,
                    "balance": 220.24,
                    "trigger_time": "2024-08-05 00:49:59",
                    "group_name": "system",
                    "name": "CHANG"
                },
                {
                    "server_id": 2,
                    "server_name": "MT5-Demo",
                    "login": "xxxxxx58",
                    "equity_credit_ratio_threshold": 1,
                    "books": "[B]",
                    "credit": 208.16,
                    "equity": 192.28,
                    "balance": 208.16,
                    "trigger_time": "2024-08-05 00:49:59",
                    "group_name": "system",
                    "name": "Machin"
                }
            ],
            "count": 2
        }
    };
    const returnVal = new Promise((resolve, _) => resolve(mockData));
    return returnVal;
}

