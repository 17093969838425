
export function getDataProfitTaker(query) {
    const mockData = {
        status: 200,
        data: 
        {
            "data": [
                {
                    "server_id": 1,
                    "name": "Pty",
                    "login": "xxxxxx58",
                    "order": "yyyyy84",
                    "symbol": "BTCUSD",
                    "books": "[B]",
                    "volume": 10,
                    "direction": "SHORT",
                    "profit_closed_eod": 199.759999999987,
                    "profit_closed_mt4": 199.759999999987,
                    "close_time": "2023-08-24 00:35:54",
                    "profit_threshold": 100,
                    "trigger_time": "2023-08-24 00:40:24",
                    "server_name": "MT5-Demo"
                },
                {
                    "server_id": 1,
                    "name": "Xia",
                    "login": "xxxxxx58",
                    "order": "yyyyy50",
                    "symbol": "BTCUSD",
                    "books": "[B]",
                    "volume": 0.8,
                    "direction": "SHORT",
                    "profit_closed_eod": 172.730399999997,
                    "profit_closed_mt4": 107.7704,
                    "close_time": "2023-08-24 00:47:46",
                    "profit_threshold": 100,
                    "trigger_time": "2023-08-24 00:47:46",
                    "server_name": "MT5-Demo"
                },
                {
                    "server_id": 1,
                    "name": "Nadimuthu",
                    "login": "xxxxxx58",
                    "order": "yyyyy99",
                    "symbol": "BTCUSD",
                    "books": "[B]",
                    "volume": 10,
                    "direction": "SHORT",
                    "profit_closed_eod": 1275.76000000001,
                    "profit_closed_mt4": 1275.76000000001,
                    "close_time": "2023-08-24 00:48:07",
                    "profit_threshold": 100,
                    "trigger_time": "2023-08-24 00:48:07",
                    "server_name": "MT5-Demo"
                },
                {
                    "server_id": 1,
                    "name": "Nadimuthu",
                    "login": "xxxxxx58",
                    "order": "yyyyy15",
                    "symbol": "BTCUSD",
                    "books": "[B]",
                    "volume": 10,
                    "direction": "SHORT",
                    "profit_closed_eod": 466.470000000008,
                    "profit_closed_mt4": 466.470000000008,
                    "close_time": "2023-08-24 00:54:59",
                    "profit_threshold": 100,
                    "trigger_time": "2023-08-24 00:54:59",
                    "server_name": "MT5-Demo"
                },
                {
                    "server_id": 1,
                    "name": "SALIMI",
                    "login": "xxxxxx58",
                    "order": "yyyyy11",
                    "symbol": "BTCUSD",
                    "books": "[B]",
                    "volume": 10,
                    "direction": "SHORT",
                    "profit_closed_eod": 626.759999999995,
                    "profit_closed_mt4": 626.759999999995,
                    "close_time": "2023-08-24 00:51:40",
                    "profit_threshold": 100,
                    "trigger_time": "2023-08-24 00:55:28",
                    "server_name": "MT5-Demo"
                },
                {
                    "server_id": 1,
                    "name": "Balci",
                    "login": "xxxxxx55",
                    "order": "yyyyy38",
                    "symbol": "UNJU",
                    "books": "[S]",
                    "volume": 5,
                    "direction": "LONG",
                    "profit_closed_eod": 12325,
                    "profit_closed_mt4": 50650,
                    "close_time": "2023-08-24 01:05:00",
                    "profit_threshold": 100,
                    "trigger_time": "2023-08-24 01:05:00",
                    "server_name": "MT5-Demo"
                },
                {
                    "server_id": 1,
                    "name": "Piotrowski",
                    "login": "xxxxxx51",
                    "order": "yyyyy91",
                    "symbol": "XAUUSD",
                    "books": "[Test]",
                    "volume": 1,
                    "direction": "LONG",
                    "profit_closed_eod": 123.000000000002,
                    "profit_closed_mt4": 2274,
                    "close_time": "2023-08-24 01:05:05",
                    "profit_threshold": 100,
                    "trigger_time": "2023-08-24 01:05:05",
                    "server_name": "MT5-Demo"
                },
                {
                    "server_id": 2,
                    "name": "Piotrowski",
                    "login": "xxxxxx83",
                    "order": "yyyyy35",
                    "symbol": "XAUUSD",
                    "books": "[B]",
                    "volume": 1,
                    "direction": "LONG",
                    "profit_closed_eod": 130.999999999995,
                    "profit_closed_mt4": 130.999999999995,
                    "close_time": "2023-08-24 01:06:33",
                    "profit_threshold": 100,
                    "trigger_time": "2023-08-24 01:06:33",
                    "server_name": "MT4-Demo"
                },
            ],
            "count": 21
        }
    };
    const returnVal = new Promise((resolve, _) => resolve(mockData));
    return returnVal;
}
