import common from "@assets/js/common";

const state = {
    loading: false,
    default: [
        {
            books: ["B"],
            broker: "Demo",
            exposure: 57412.61747827125,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "CAD",
            timestamp: 1722840378,
            user: "Demo:MT4-Demo:xxxxx37:Vu",
            trigger_time: "2024-08-05 16:46:18",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: -78301.12,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "XAU",
            timestamp: 1722840399,
            user: "Demo:MT4-Demo:xxxxx13:Ad",
            trigger_time: "2024-08-05 16:46:39",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: 135019.79507560478,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "CAD",
            timestamp: 1722840372,
            user: "Demo:MT4-Demo:xxxxx52:NG",
            trigger_time: "2024-08-05 16:46:12",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: 402401.4695441229,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "USD",
            timestamp: 1722840414,
            user: "Demo:MT4-Demo:xxxxx81:Su",
            trigger_time: "2024-08-05 16:46:54",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: 25601.039999999997,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "GBP",
            timestamp: 1722840376,
            user: "Demo:MT4-Demo:xxxxx39:Ng",
            trigger_time: "2024-08-05 16:46:16",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: 31202.599999999995,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "AUD",
            timestamp: 1722840391,
            user: "Demo:MT4-Demo:xxxxx51:Le",
            trigger_time: "2024-08-05 16:46:31",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: -73007.42716006811,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "USD",
            timestamp: 1722840418,
            user: "Demo:MT4-Demo:xxxxx56:TH",
            trigger_time: "2024-08-05 16:46:58",
        },

        {
            books: ["B"],
            broker: "Demo",
            exposure: -66560.6634165,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "GBP",
            timestamp: 1722840422,
            user: "Demo:MT4-Demo:xxxxx21:Ng",
            trigger_time: "2024-08-05 16:47:02",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: -65531.69563260002,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "EUR",
            timestamp: 1722840407,
            user: "Demo:MT4-Demo:xxxxx34:Ph",
            trigger_time: "2024-08-05 16:46:47",
        },

        {
            books: ["B"],
            broker: "Demo",
            exposure: -55050.419999999925,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "GBP",
            timestamp: 1722840372,
            user: "Demo:MT4-Demo:xxxxx55:Ru",
            trigger_time: "2024-08-05 16:46:12",
        },

        {
            books: ["B"],
            broker: "Demo",
            exposure: -58887.32135206175,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "GBP",
            timestamp: 1722840378,
            user: "Demo:MT4-Demo:xxxxx34:Le",
            trigger_time: "2024-08-05 16:46:18",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: -92258.67209400398,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "USD",
            timestamp: 1722840418,
            user: "Demo:MT4-Demo:xxxxx18:DA",
            trigger_time: "2024-08-05 16:46:58",
        },

        {
            books: ["B"],
            broker: "Demo",
            exposure: -20491.87140998943,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "GBP",
            timestamp: 1722840376,
            user: "Demo:MT4-Demo:xxxxx64:Ng",
            trigger_time: "2024-08-05 16:46:16",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: -102672.52377410002,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "EUR",
            timestamp: 1722840418,
            user: "Demo:MT4-Demo:xxxxx92:LE",
            trigger_time: "2024-08-05 16:46:58",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: -40033.13755559526,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "USD",
            timestamp: 1722840399,
            user: "Demo:MT4-Demo:xxxxx44:NG",
            trigger_time: "2024-08-05 16:46:39",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: -49560.95596650106,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "USD",
            timestamp: 1722840372,
            user: "Demo:MT4-Demo:xxxxx94:LE",
            trigger_time: "2024-08-05 16:46:12",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: 135019.68689186717,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "CAD",
            timestamp: 1722840372,
            user: "Demo:MT4-Demo:xxxxx79:NG",
            trigger_time: "2024-08-05 16:46:12",
        },

        {
            books: ["B"],
            broker: "Demo",
            exposure: 49958.701881084955,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "AUD",
            timestamp: 1722840372,
            user: "Demo:MT4-Demo:xxxxx45:Tr",
            trigger_time: "2024-08-05 16:46:12",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: 20030.149850288017,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "JPY",
            timestamp: 1722840405,
            user: "Demo:MT4-Demo:xxxxx91:Sy",
            trigger_time: "2024-08-05 16:46:45",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: 80108.04671175189,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "JPY",
            timestamp: 1722840418,
            user: "Demo:MT4-Demo:xxxxx38:Lu",
            trigger_time: "2024-08-05 16:46:58",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: -44041.049999999996,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "XAU",
            timestamp: 1722840418,
            user: "Demo:MT4-Demo:xxxxx31:Lu",
            trigger_time: "2024-08-05 16:46:58",
        },

        {
            books: ["B"],
            broker: "Demo",
            exposure: -135013.91695602945,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "USD",
            timestamp: 1722840418,
            user: "Demo:MT4-Demo:xxxxx98:NG",
            trigger_time: "2024-08-05 16:46:58",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: -41515.8993806,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "EUR",
            timestamp: 1722840399,
            user: "Demo:MT4-Demo:xxxxx00:Tr",
            trigger_time: "2024-08-05 16:46:39",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: 45370.76619879999,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "NZD",
            timestamp: 1722840372,
            user: "Demo:MT4-Demo:xxxxx94:LE",
            trigger_time: "2024-08-05 16:46:12",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: 84684.94378224014,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "CAD",
            timestamp: 1722840401,
            user: "Demo:MT4-Demo:xxxxx35:Vu",
            trigger_time: "2024-08-05 16:46:41",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: -57903.09790589999,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "EUR",
            timestamp: 1722840372,
            user: "Demo:MT4-Demo:xxxxx80:Ng",
            trigger_time: "2024-08-05 16:46:12",
        },

        {
            books: ["B"],
            broker: "Demo",
            exposure: 63359.588035500004,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "AUD",
            timestamp: 1722840399,
            user: "Demo:MT4-Demo:xxxxx44:NG",
            trigger_time: "2024-08-05 16:46:39",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: -36701,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "XAU",
            timestamp: 1722840377,
            user: "Demo:MT4-Demo:xxxxx75:NG",
            trigger_time: "2024-08-05 16:46:17",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: 135019.6147662549,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "CAD",
            timestamp: 1722840376,
            user: "Demo:MT4-Demo:xxxxx86:NG",
            trigger_time: "2024-08-05 16:46:16",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: -150020.33584660888,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "USD",
            timestamp: 1722840376,
            user: "Demo:MT4-Demo:xxxxx90:NG",
            trigger_time: "2024-08-05 16:46:16",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: -122345.5,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "XAU",
            timestamp: 1722840420,
            user: "Demo:MT4-Demo:xxxxx29:Ng",
            trigger_time: "2024-08-05 16:47:00",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: 25832.1308,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "USD",
            timestamp: 1722840399,
            user: "Demo:MT4-Demo:xxxxx88:LE",
            trigger_time: "2024-08-05 16:46:39",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: -489223,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "USD",
            timestamp: 1722840420,
            user: "Demo:MT4-Demo:xxxxx11:Ng",
            trigger_time: "2024-08-05 16:47:00",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: -44543.510265570716,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "USD",
            timestamp: 1722840418,
            user: "Demo:MT4-Demo:xxxxx64:Ng",
            trigger_time: "2024-08-05 16:46:58",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: 150117.5418076435,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "JPY",
            timestamp: 1722840399,
            user: "Demo:MT4-Demo:xxxxx02:Ch",
            trigger_time: "2024-08-05 16:46:39",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: 76814.84999999999,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "USD",
            timestamp: 1722840405,
            user: "Demo:MT4-Demo:xxxxx57:Gr",
            trigger_time: "2024-08-05 16:46:45",
        },

        {
            books: ["B"],
            broker: "Demo",
            exposure: 31280.393999999997,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "USD",
            timestamp: 1722840399,
            user: "Demo:MT4-Demo:xxxxx75:NG",
            trigger_time: "2024-08-05 16:46:39",
        },

        {
            books: ["B"],
            broker: "Demo",
            exposure: 120014.06236395215,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "CAD",
            timestamp: 1722840416,
            user: "Demo:MT4-Demo:xxxxx00:Tr",
            trigger_time: "2024-08-05 16:46:56",
        },

        {
            books: ["B"],
            broker: "Demo",
            exposure: 56988.57228210001,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "NZD",
            timestamp: 1722840407,
            user: "Demo:MT4-Demo:xxxxx34:Ph",
            trigger_time: "2024-08-05 16:46:47",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: 66765.4711747,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "AUD",
            timestamp: 1722840399,
            user: "Demo:MT4-Demo:xxxxx94:LE",
            trigger_time: "2024-08-05 16:46:39",
        },

        {
            books: ["B"],
            broker: "Demo",
            exposure: -135014.49379218,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "USD",
            timestamp: 1722840422,
            user: "Demo:MT4-Demo:xxxxx27:NG",
            trigger_time: "2024-08-05 16:47:02",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: 48921.8,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "USD",
            timestamp: 1722840376,
            user: "Demo:MT4-Demo:xxxxx97:Ph",
            trigger_time: "2024-08-05 16:46:16",
        },

        {
            books: ["B"],
            broker: "Demo",
            exposure: -135019.79507560478,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "USD",
            timestamp: 1722840372,
            user: "Demo:MT4-Demo:xxxxx52:NG",
            trigger_time: "2024-08-05 16:46:12",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: -26911.07,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "XAU",
            timestamp: 1722840372,
            user: "Demo:MT4-Demo:xxxxx88:LE",
            trigger_time: "2024-08-05 16:46:12",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: 105723.46157609997,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "AUD",
            timestamp: 1722840401,
            user: "Demo:MT4-Demo:xxxxx35:Vu",
            trigger_time: "2024-08-05 16:46:41",
        },

        {
            books: ["B"],
            broker: "Demo",
            exposure: -34262.67999999999,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "XAU",
            timestamp: 1722840418,
            user: "Demo:MT4-Demo:xxxxx70:NG",
            trigger_time: "2024-08-05 16:46:58",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: -260032.81024226124,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "USD",
            timestamp: 1722840372,
            user: "Demo:MT4-Demo:xxxxx32:NG",
            trigger_time: "2024-08-05 16:46:12",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: -87046.92465285602,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "GBP",
            timestamp: 1722840399,
            user: "Demo:MT4-Demo:xxxxx80:NG",
            trigger_time: "2024-08-05 16:46:39",
        },

        {
            books: ["B"],
            broker: "Demo",
            exposure: -40776.791390012455,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "USD",
            timestamp: 1722840372,
            user: "Demo:MT4-Demo:xxxxx18:Ph",
            trigger_time: "2024-08-05 16:46:12",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: -73007.21802496978,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "USD",
            timestamp: 1722840416,
            user: "Demo:MT4-Demo:xxxxx27:NG",
            trigger_time: "2024-08-05 16:46:56",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: -49366.468129299465,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "USD",
            timestamp: 1722840420,
            user: "Demo:MT4-Demo:xxxxx52:Ng",
            trigger_time: "2024-08-05 16:47:00",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: 784359.8,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "UKO",
            timestamp: 1722840388,
            user: "Demo:MT4-Demo:xxxxx02:NG",
            trigger_time: "2024-08-05 16:46:28",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: -141520.98689186716,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "USD",
            timestamp: 1722840372,
            user: "Demo:MT4-Demo:xxxxx79:NG",
            trigger_time: "2024-08-05 16:46:12",
        },

        {
            books: ["B"],
            broker: "Demo",
            exposure: -201028.07,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "EUR",
            timestamp: 1722840422,
            user: "Demo:MT4-Demo:xxxxx16:Wa",
            trigger_time: "2024-08-05 16:47:02",
        },

        {
            books: ["B"],
            broker: "Demo",
            exposure: -76814.84999999999,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "GBP",
            timestamp: 1722840405,
            user: "Demo:MT4-Demo:xxxxx57:Gr",
            trigger_time: "2024-08-05 16:46:45",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: -107526.31511428447,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "GBP",
            timestamp: 1722840372,
            user: "Demo:MT4-Demo:xxxxx18:Ph",
            trigger_time: "2024-08-05 16:46:12",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: 74006.80699759886,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "CAD",
            timestamp: 1722840399,
            user: "Demo:MT4-Demo:xxxxx96:Di",
            trigger_time: "2024-08-05 16:46:39",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: -57638.86000000003,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "GBP",
            timestamp: 1722840376,
            user: "Demo:MT4-Demo:xxxxx81:Su",
            trigger_time: "2024-08-05 16:46:16",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: -83206.49347807918,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "GBP",
            timestamp: 1722840407,
            user: "Demo:MT4-Demo:xxxxx52:Ng",
            trigger_time: "2024-08-05 16:46:47",
        },

        {
            books: ["B"],
            broker: "Demo",
            exposure: 41515.8993806,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "GBP",
            timestamp: 1722840399,
            user: "Demo:MT4-Demo:xxxxx00:Tr",
            trigger_time: "2024-08-05 16:46:39",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: -73390.8,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "USD",
            timestamp: 1722840422,
            user: "Demo:MT4-Demo:xxxxx39:Vu",
            trigger_time: "2024-08-05 16:47:02",
        },

        {
            books: ["B"],
            broker: "Demo",
            exposure: 130387.41259640001,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "AUD",
            timestamp: 1722840372,
            user: "Demo:MT4-Demo:xxxxx10:Du",
            trigger_time: "2024-08-05 16:46:12",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: 135018.92951416277,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "CAD",
            timestamp: 1722840378,
            user: "Demo:MT4-Demo:xxxxx29:NG",
            trigger_time: "2024-08-05 16:46:18",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: -135013.19581692488,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "USD",
            timestamp: 1722840422,
            user: "Demo:MT4-Demo:xxxxx84:NG",
            trigger_time: "2024-08-05 16:47:02",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: -366956,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "XAU",
            timestamp: 1722840418,
            user: "Demo:MT4-Demo:xxxxx88:TH",
            trigger_time: "2024-08-05 16:46:58",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: -34052,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "USD",
            timestamp: 1722840418,
            user: "Demo:MT4-Demo:xxxxx36:NG",
            trigger_time: "2024-08-05 16:46:58",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: 21853.26742969311,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "CAD",
            timestamp: 1722840372,
            user: "Demo:MT4-Demo:xxxxx10:Du",
            trigger_time: "2024-08-05 16:46:12",
        },

        {
            books: ["B"],
            broker: "Demo",
            exposure: 54412.36918198723,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "CAD",
            timestamp: 1722840377,
            user: "Demo:MT4-Demo:xxxxx46:Tr",
            trigger_time: "2024-08-05 16:46:17",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: -26881.69000000004,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "GBP",
            timestamp: 1722840399,
            user: "Demo:MT4-Demo:xxxxx69:Na",
            trigger_time: "2024-08-05 16:46:39",
        },

        {
            books: ["B"],
            broker: "Demo",
            exposure: -115014.45,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "XAU",
            timestamp: 1722840399,
            user: "Demo:MT4-Demo:xxxxx18:Di",
            trigger_time: "2024-08-05 16:46:39",
        },

        {
            books: ["B"],
            broker: "Demo",
            exposure: -93941.7094443,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "EUR",
            timestamp: 1722840372,
            user: "Demo:MT4-Demo:xxxxx94:LE",
            trigger_time: "2024-08-05 16:46:12",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: 78301.12,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "USD",
            timestamp: 1722840399,
            user: "Demo:MT4-Demo:xxxxx13:Ad",
            trigger_time: "2024-08-05 16:46:39",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: -30881.880000000005,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "USD",
            timestamp: 1722840422,
            user: "Demo:MT4-Demo:xxxxx72:PH",
            trigger_time: "2024-08-05 16:47:02",
        },

        {
            books: ["B"],
            broker: "Demo",
            exposure: 60067.309944299996,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "NZD",
            timestamp: 1722840416,
            user: "Demo:MT4-Demo:xxxxx99:Tr",
            trigger_time: "2024-08-05 16:46:56",
        },

        {
            books: ["B"],
            broker: "Demo",
            exposure: -29338.56,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "XAU",
            timestamp: 1722840422,
            user: "Demo:MT4-Demo:xxxxx33:WO",
            trigger_time: "2024-08-05 16:47:02",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: -34561.737484799996,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "GBP",
            timestamp: 1722840405,
            user: "Demo:MT4-Demo:xxxxx54:NG",
            trigger_time: "2024-08-05 16:46:45",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: 122345.5,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "USD",
            timestamp: 1722840420,
            user: "Demo:MT4-Demo:xxxxx29:Ng",
            trigger_time: "2024-08-05 16:47:00",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: 63487.489576,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "AUD",
            timestamp: 1722840401,
            user: "Demo:MT4-Demo:xxxxx39:Ng",
            trigger_time: "2024-08-05 16:46:41",
        },

        {
            books: ["B"],
            broker: "Demo",
            exposure: 59357.9493273,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "AUD",
            timestamp: 1722840372,
            user: "Demo:MT4-Demo:xxxxx92:LE",
            trigger_time: "2024-08-05 16:46:12",
        },

        {
            books: ["B"],
            broker: "Demo",
            exposure: -24469.1,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "XAU",
            timestamp: 1722840384,
            user: "Demo:MT4-Demo:xxxxx30:Ca",
            trigger_time: "2024-08-05 16:46:24",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: 51363.33,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "USD",
            timestamp: 1722840368,
            user: "Demo:MT4-Demo:xxxxx32:IW",
            trigger_time: "2024-08-05 16:46:08",
        },

        {
            books: ["B"],
            broker: "Demo",
            exposure: 92841.26135635233,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "AUD",
            timestamp: 1722840372,
            user: "Demo:MT4-Demo:xxxxx89:Ph",
            trigger_time: "2024-08-05 16:46:12",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: 20152.938792932466,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "AUD",
            timestamp: 1722840399,
            user: "Demo:MT4-Demo:xxxxx00:NG",
            trigger_time: "2024-08-05 16:46:39",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: -53675.342611974724,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "USD",
            timestamp: 1722840418,
            user: "Demo:MT4-Demo:xxxxx39:Ng",
            trigger_time: "2024-08-05 16:46:58",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: 73912.19816675535,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "CAD",
            timestamp: 1722840422,
            user: "Demo:MT4-Demo:xxxxx64:Ng",
            trigger_time: "2024-08-05 16:47:02",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: 135019.75900604026,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "CAD",
            timestamp: 1722840372,
            user: "Demo:MT4-Demo:xxxxx19:NG",
            trigger_time: "2024-08-05 16:46:12",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: -88472.3198619,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "EUR",
            timestamp: 1722840422,
            user: "Demo:MT4-Demo:xxxxx70:PH",
            trigger_time: "2024-08-05 16:47:02",
        },

        {
            books: ["B"],
            broker: "Demo",
            exposure: -34249.36999999999,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "XAU",
            timestamp: 1722840376,
            user: "Demo:MT4-Demo:xxxxx55:TR",
            trigger_time: "2024-08-05 16:46:16",
        },

        {
            books: ["B"],
            broker: "Demo",
            exposure: 26881.69000000004,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "USD",
            timestamp: 1722840399,
            user: "Demo:MT4-Demo:xxxxx69:Na",
            trigger_time: "2024-08-05 16:46:39",
        },

        {
            books: ["B"],
            broker: "Demo",
            exposure: 99961.80131475984,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "CAD",
            timestamp: 1722840418,
            user: "Demo:MT4-Demo:xxxxx32:NG",
            trigger_time: "2024-08-05 16:46:58",
        },

        {
            books: ["B"],
            broker: "Demo",
            exposure: 51955.239319619635,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "CAD",
            timestamp: 1722840418,
            user: "Demo:MT4-Demo:xxxxx18:DA",
            trigger_time: "2024-08-05 16:46:58",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: -98321.76773923766,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "EUR",
            timestamp: 1722840372,
            user: "Demo:MT4-Demo:xxxxx18:Ph",
            trigger_time: "2024-08-05 16:46:12",
        },

        {
            books: ["B"],
            broker: "Demo",
            exposure: 135014.49379218,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "CAD",
            timestamp: 1722840422,
            user: "Demo:MT4-Demo:xxxxx27:NG",
            trigger_time: "2024-08-05 16:47:02",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: -54538.14675178264,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "USD",
            timestamp: 1722840378,
            user: "Demo:MT4-Demo:xxxxx37:Vu",
            trigger_time: "2024-08-05 16:46:18",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: 21841.70887204497,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "EUR",
            timestamp: 1722840418,
            user: "Demo:MT4-Demo:xxxxx89:Ph",
            trigger_time: "2024-08-05 16:46:58",
        },

        {
            books: ["B"],
            broker: "Demo",
            exposure: 47369.35901386577,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "CAD",
            timestamp: 1722840422,
            user: "Demo:MT4-Demo:xxxxx24:Ng",
            trigger_time: "2024-08-05 16:47:02",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: -50260.41489209311,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "EUR",
            timestamp: 1722840372,
            user: "Demo:MT4-Demo:xxxxx10:Du",
            trigger_time: "2024-08-05 16:46:12",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: -114685.45122420002,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "EUR",
            timestamp: 1722840418,
            user: "Demo:MT4-Demo:xxxxx64:Ng",
            trigger_time: "2024-08-05 16:46:58",
        },

        {
            books: ["B"],
            broker: "Demo",
            exposure: -54005.213416477054,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "USD",
            timestamp: 1722840418,
            user: "Demo:MT4-Demo:xxxxx25:TH",
            trigger_time: "2024-08-05 16:46:58",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: 52797.43938429999,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "AUD",
            timestamp: 1722840399,
            user: "Demo:MT4-Demo:xxxxx70:PH",
            trigger_time: "2024-08-05 16:46:39",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: 67807.4551984,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "NZD",
            timestamp: 1722840418,
            user: "Demo:MT4-Demo:xxxxx64:Ng",
            trigger_time: "2024-08-05 16:46:58",
        },

        {
            books: ["B"],
            broker: "Demo",
            exposure: 55050.419999999925,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "USD",
            timestamp: 1722840372,
            user: "Demo:MT4-Demo:xxxxx55:Ru",
            trigger_time: "2024-08-05 16:46:12",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: 46732.63887839538,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "CAD",
            timestamp: 1722840372,
            user: "Demo:MT4-Demo:xxxxx94:LE",
            trigger_time: "2024-08-05 16:46:12",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: 194295.3936515999,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "AUD",
            timestamp: 1722840399,
            user: "Demo:MT4-Demo:xxxxx70:Du",
            trigger_time: "2024-08-05 16:46:39",
        },

        {
            books: ["B"],
            broker: "Demo",
            exposure: -112558.19999999995,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "XAU",
            timestamp: 1722840416,
            user: "Demo:MT4-Demo:xxxxx12:Di",
            trigger_time: "2024-08-05 16:46:56",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: -129978.48448925346,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "EUR",
            timestamp: 1722840372,
            user: "Demo:MT4-Demo:xxxxx80:NG",
            trigger_time: "2024-08-05 16:46:12",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: -43507.30575966555,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "USD",
            timestamp: 1722840372,
            user: "Demo:MT4-Demo:xxxxx92:LE",
            trigger_time: "2024-08-05 16:46:12",
        },

        {
            books: ["B"],
            broker: "Demo",
            exposure: -150117.5418076435,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "USD",
            timestamp: 1722840399,
            user: "Demo:MT4-Demo:xxxxx02:Ch",
            trigger_time: "2024-08-05 16:46:39",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: 31677.7122942,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "GBP",
            timestamp: 1722840380,
            user: "Demo:MT4-Demo:xxxxx18:DA",
            trigger_time: "2024-08-05 16:46:20",
        },

        {
            books: ["B"],
            broker: "Demo",
            exposure: -22942.071451599997,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "EUR",
            timestamp: 1722840368,
            user: "Demo:MT4-Demo:xxxxx91:NG",
            trigger_time: "2024-08-05 16:46:08",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: 32006.39593423992,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "CAD",
            timestamp: 1722840407,
            user: "Demo:MT4-Demo:xxxxx34:Ph",
            trigger_time: "2024-08-05 16:46:47",
        },

        {
            books: ["B"],
            broker: "Demo",
            exposure: 58705.41869569066,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "CAD",
            timestamp: 1722840416,
            user: "Demo:MT4-Demo:xxxxx27:NG",
            trigger_time: "2024-08-05 16:46:56",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: 65180.37066630721,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "USD",
            timestamp: 1722840403,
            user: "Demo:MT4-Demo:xxxxx28:TH",
            trigger_time: "2024-08-05 16:46:43",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: -22014.54,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "XAU",
            timestamp: 1722840420,
            user: "Demo:MT4-Demo:xxxxx95:CH",
            trigger_time: "2024-08-05 16:47:00",
        },

        {
            books: ["B"],
            broker: "Demo",
            exposure: 48924.5,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "USD",
            timestamp: 1722840368,
            user: "Demo:MT4-Demo:xxxxx36:Le",
            trigger_time: "2024-08-05 16:46:08",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: -31678.117690399995,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "EUR",
            timestamp: 1722840372,
            user: "Demo:MT4-Demo:xxxxx00:Ng",
            trigger_time: "2024-08-05 16:46:12",
        },

        {
            books: ["B"],
            broker: "Demo",
            exposure: 258636.16999999995,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "USD",
            timestamp: 1722840422,
            user: "Demo:MT4-Demo:xxxxx16:Wa",
            trigger_time: "2024-08-05 16:47:02",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: -50601.77087286344,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "USD",
            timestamp: 1722840418,
            user: "Demo:MT4-Demo:xxxxx70:Du",
            trigger_time: "2024-08-05 16:46:58",
        },

        {
            books: ["B"],
            broker: "Demo",
            exposure: -44025.79,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "XAU",
            timestamp: 1722840372,
            user: "Demo:MT4-Demo:xxxxx37:IW",
            trigger_time: "2024-08-05 16:46:12",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: -74006.80699759886,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "USD",
            timestamp: 1722840399,
            user: "Demo:MT4-Demo:xxxxx96:Di",
            trigger_time: "2024-08-05 16:46:39",
        },

        {
            books: ["B"],
            broker: "Demo",
            exposure: 38409.269683308536,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "CAD",
            timestamp: 1722840418,
            user: "Demo:MT4-Demo:xxxxx36:NG",
            trigger_time: "2024-08-05 16:46:58",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: -31799.149999999998,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "XAU",
            timestamp: 1722840388,
            user: "Demo:MT4-Demo:xxxxx33:NG",
            trigger_time: "2024-08-05 16:46:28",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: -105972.98642295123,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "EUR",
            timestamp: 1722840388,
            user: "Demo:MT4-Demo:xxxxx52:Ng",
            trigger_time: "2024-08-05 16:46:28",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: 366956,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "USD",
            timestamp: 1722840418,
            user: "Demo:MT4-Demo:xxxxx88:TH",
            trigger_time: "2024-08-05 16:46:58",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: -48938.2,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "XAU",
            timestamp: 1722840380,
            user: "Demo:MT4-Demo:xxxxx58:Ng",
            trigger_time: "2024-08-05 16:46:20",
        },

        {
            books: ["B"],
            broker: "Demo",
            exposure: 166251.84000000003,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "USD",
            timestamp: 1722840418,
            user: "Demo:MT4-Demo:xxxxx95:Jo",
            trigger_time: "2024-08-05 16:46:58",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: -58888.141590120664,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "GBP",
            timestamp: 1722840401,
            user: "Demo:MT4-Demo:xxxxx35:Vu",
            trigger_time: "2024-08-05 16:46:41",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: -22016.969999999998,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "XAU",
            timestamp: 1722840407,
            user: "Demo:MT4-Demo:xxxxx15:TA",
            trigger_time: "2024-08-05 16:46:47",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: -76007.47042170876,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "USD",
            timestamp: 1722840418,
            user: "Demo:MT4-Demo:xxxxx39:NG",
            trigger_time: "2024-08-05 16:46:58",
        },

        {
            books: ["B"],
            broker: "Demo",
            exposure: 135020.0114014756,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "CAD",
            timestamp: 1722840377,
            user: "Demo:MT4-Demo:xxxxx71:Ng",
            trigger_time: "2024-08-05 16:46:17",
        },

        {
            books: ["B"],
            broker: "Demo",
            exposure: -244745.53,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "EUR",
            timestamp: 1722840376,
            user: "Demo:MT4-Demo:xxxxx81:Su",
            trigger_time: "2024-08-05 16:46:16",
        },

        {
            books: ["B"],
            broker: "Demo",
            exposure: -24469.1,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "XAU",
            timestamp: 1722840399,
            user: "Demo:MT4-Demo:xxxxx61:Ng",
            trigger_time: "2024-08-05 16:46:39",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: 48919.5,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "USD",
            timestamp: 1722840368,
            user: "Demo:MT4-Demo:xxxxx94:Ng",
            trigger_time: "2024-08-05 16:46:08",
        },

        {
            books: ["B"],
            broker: "Demo",
            exposure: -31677.7122942,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "EUR",
            timestamp: 1722840380,
            user: "Demo:MT4-Demo:xxxxx18:DA",
            trigger_time: "2024-08-05 16:46:20",
        },

        {
            books: ["B"],
            broker: "Demo",
            exposure: 55955.87500209796,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "CAD",
            timestamp: 1722840418,
            user: "Demo:MT4-Demo:xxxxx25:TH",
            trigger_time: "2024-08-05 16:46:58",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: 135019.6508238553,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "CAD",
            timestamp: 1722840368,
            user: "Demo:MT4-Demo:xxxxx33:NG",
            trigger_time: "2024-08-05 16:46:08",
        },

        {
            books: ["B"],
            broker: "Demo",
            exposure: -21998.246638201133,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "JPY",
            timestamp: 1722840368,
            user: "Demo:MT4-Demo:xxxxx21:Ng",
            trigger_time: "2024-08-05 16:46:08",
        },

        {
            books: ["B"],
            broker: "Demo",
            exposure: 159056.95,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "USD",
            timestamp: 1722840377,
            user: "Demo:MT4-Demo:xxxxx60:SU",
            trigger_time: "2024-08-05 16:46:17",
        },

        {
            books: ["B"],
            broker: "Demo",
            exposure: 150020.33584660888,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "CAD",
            timestamp: 1722840376,
            user: "Demo:MT4-Demo:xxxxx90:NG",
            trigger_time: "2024-08-05 16:46:16",
        },

        {
            books: ["B"],
            broker: "Demo",
            exposure: 20001.492611974714,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "CAD",
            timestamp: 1722840420,
            user: "Demo:MT4-Demo:xxxxx39:Ng",
            trigger_time: "2024-08-05 16:47:00",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: -154895.53550104346,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "GBP",
            timestamp: 1722840399,
            user: "Demo:MT4-Demo:xxxxx70:Du",
            trigger_time: "2024-08-05 16:46:39",
        },

        {
            books: ["B"],
            broker: "Demo",
            exposure: 78690.85069779753,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "CAD",
            timestamp: 1722840420,
            user: "Demo:MT4-Demo:xxxxx52:Ng",
            trigger_time: "2024-08-05 16:47:00",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: -135019.6508238553,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "USD",
            timestamp: 1722840368,
            user: "Demo:MT4-Demo:xxxxx33:NG",
            trigger_time: "2024-08-05 16:46:08",
        },

        {
            books: ["B"],
            broker: "Demo",
            exposure: 25126.199623599998,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "AUD",
            timestamp: 1722840399,
            user: "Demo:MT4-Demo:xxxxx87:Tr",
            trigger_time: "2024-08-05 16:46:39",
        },

        {
            books: ["B"],
            broker: "Demo",
            exposure: -51837.955210233464,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "CHF",
            timestamp: 1722840418,
            user: "Demo:MT4-Demo:xxxxx45:Tr",
            trigger_time: "2024-08-05 16:46:58",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: 146803.8,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "XAU",
            timestamp: 1722840418,
            user: "Demo:MT4-Demo:xxxxx38:Lu",
            trigger_time: "2024-08-05 16:46:58",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: 49540.2526618,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "NZD",
            timestamp: 1722840422,
            user: "Demo:MT4-Demo:xxxxx70:PH",
            trigger_time: "2024-08-05 16:47:02",
        },

        {
            books: ["B"],
            broker: "Demo",
            exposure: -58411.89139515073,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "USD",
            timestamp: 1722840418,
            user: "Demo:MT4-Demo:xxxxx64:Ng",
            trigger_time: "2024-08-05 16:46:58",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: 77531.6209429,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "NZD",
            timestamp: 1722840372,
            user: "Demo:MT4-Demo:xxxxx80:NG",
            trigger_time: "2024-08-05 16:46:12",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: 489223,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "XAU",
            timestamp: 1722840420,
            user: "Demo:MT4-Demo:xxxxx11:Ng",
            trigger_time: "2024-08-05 16:47:00",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: -31093.70488924532,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "JPY",
            timestamp: 1722840418,
            user: "Demo:MT4-Demo:xxxxx89:Ph",
            trigger_time: "2024-08-05 16:46:58",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: -135018.92951416277,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "USD",
            timestamp: 1722840378,
            user: "Demo:MT4-Demo:xxxxx29:NG",
            trigger_time: "2024-08-05 16:46:18",
        },

        {
            books: ["B"],
            broker: "Demo",
            exposure: -102697.70324526551,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "EUR",
            timestamp: 1722840384,
            user: "Demo:MT4-Demo:xxxxx64:Ng",
            trigger_time: "2024-08-05 16:46:24",
        },

        {
            books: ["B"],
            broker: "Demo",
            exposure: -27473.300000000003,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "USD",
            timestamp: 1722840407,
            user: "Demo:MT4-Demo:xxxxx34:Ph",
            trigger_time: "2024-08-05 16:46:47",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: 68949.02493140001,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "AUD",
            timestamp: 1722840399,
            user: "Demo:MT4-Demo:xxxxx80:Ng",
            trigger_time: "2024-08-05 16:46:39",
        },

        {
            books: ["B"],
            broker: "Demo",
            exposure: -44789.85961199999,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "EUR",
            timestamp: 1722840399,
            user: "Demo:MT4-Demo:xxxxx37:Vu",
            trigger_time: "2024-08-05 16:46:39",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: 66560.6634165,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "AUD",
            timestamp: 1722840422,
            user: "Demo:MT4-Demo:xxxxx21:Ng",
            trigger_time: "2024-08-05 16:47:02",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: 64358.72178043049,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "AUD",
            timestamp: 1722840399,
            user: "Demo:MT4-Demo:xxxxx91:NG",
            trigger_time: "2024-08-05 16:46:39",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: -135019.75900604026,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "USD",
            timestamp: 1722840372,
            user: "Demo:MT4-Demo:xxxxx19:NG",
            trigger_time: "2024-08-05 16:46:12",
        },

        {
            books: ["B"],
            broker: "Demo",
            exposure: 22016.969999999998,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "USD",
            timestamp: 1722840407,
            user: "Demo:MT4-Demo:xxxxx15:TA",
            trigger_time: "2024-08-05 16:46:47",
        },

        {
            books: ["B"],
            broker: "Demo",
            exposure: -20030.149850288017,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "USD",
            timestamp: 1722840405,
            user: "Demo:MT4-Demo:xxxxx91:Sy",
            trigger_time: "2024-08-05 16:46:45",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: 138446.83314729997,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "AUD",
            timestamp: 1722840399,
            user: "Demo:MT4-Demo:xxxxx80:NG",
            trigger_time: "2024-08-05 16:46:39",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: 86252.96475623459,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "CAD",
            timestamp: 1722840372,
            user: "Demo:MT4-Demo:xxxxx18:Ph",
            trigger_time: "2024-08-05 16:46:12",
        },

        {
            books: ["B"],
            broker: "Demo",
            exposure: -201028.07,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "EUR",
            timestamp: 1722840422,
            user: "Demo:MT4-Demo:xxxxx37:HA",
            trigger_time: "2024-08-05 16:47:02",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: 66806.40682668414,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "CAD",
            timestamp: 1722840418,
            user: "Demo:MT4-Demo:xxxxx91:NG",
            trigger_time: "2024-08-05 16:46:58",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: 115015.35256286398,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "CAD",
            timestamp: 1722840386,
            user: "Demo:MT4-Demo:xxxxx87:NG",
            trigger_time: "2024-08-05 16:46:26",
        },

        {
            books: ["B"],
            broker: "Demo",
            exposure: -91808.04939422624,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "USD",
            timestamp: 1722840418,
            user: "Demo:MT4-Demo:xxxxx51:Le",
            trigger_time: "2024-08-05 16:46:58",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: 31799.149999999998,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "USD",
            timestamp: 1722840388,
            user: "Demo:MT4-Demo:xxxxx33:NG",
            trigger_time: "2024-08-05 16:46:28",
        },

        {
            books: ["B"],
            broker: "Demo",
            exposure: -24118.440000000002,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "USD",
            timestamp: 1722840422,
            user: "Demo:MT4-Demo:xxxxx73:Hu",
            trigger_time: "2024-08-05 16:47:02",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: -47888.690062599395,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "USD",
            timestamp: 1722840401,
            user: "Demo:MT4-Demo:xxxxx35:Vu",
            trigger_time: "2024-08-05 16:46:41",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: -52438.7928066,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "EUR",
            timestamp: 1722840376,
            user: "Demo:MT4-Demo:xxxxx39:Ng",
            trigger_time: "2024-08-05 16:46:16",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: -63359.588035500004,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "EUR",
            timestamp: 1722840399,
            user: "Demo:MT4-Demo:xxxxx44:NG",
            trigger_time: "2024-08-05 16:46:39",
        },

        {
            books: ["B"],
            broker: "Demo",
            exposure: 97836.4,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "XAU",
            timestamp: 1722840372,
            user: "Demo:MT4-Demo:xxxxx12:NG",
            trigger_time: "2024-08-05 16:46:12",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: 44041.049999999996,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "USD",
            timestamp: 1722840418,
            user: "Demo:MT4-Demo:xxxxx31:Lu",
            trigger_time: "2024-08-05 16:46:58",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: -98326.89223525391,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "EUR",
            timestamp: 1722840399,
            user: "Demo:MT4-Demo:xxxxx70:Du",
            trigger_time: "2024-08-05 16:46:39",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: -48928.799999999996,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "XAU",
            timestamp: 1722840403,
            user: "Demo:MT4-Demo:xxxxx28:TH",
            trigger_time: "2024-08-05 16:46:43",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: 48938.2,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "USD",
            timestamp: 1722840380,
            user: "Demo:MT4-Demo:xxxxx58:Ng",
            trigger_time: "2024-08-05 16:46:20",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: 146356.51862429996,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "AUD",
            timestamp: 1722840388,
            user: "Demo:MT4-Demo:xxxxx52:Ng",
            trigger_time: "2024-08-05 16:46:28",
        },

        {
            books: ["B"],
            broker: "Demo",
            exposure: 43673.45,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "USD",
            timestamp: 1722840384,
            user: "Demo:MT4-Demo:xxxxx30:Ca",
            trigger_time: "2024-08-05 16:46:24",
        },

        {
            books: ["B"],
            broker: "Demo",
            exposure: 51408.9012151949,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "CAD",
            timestamp: 1722840399,
            user: "Demo:MT4-Demo:xxxxx44:NG",
            trigger_time: "2024-08-05 16:46:39",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: 22935.7705204,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "NZD",
            timestamp: 1722840376,
            user: "Demo:MT4-Demo:xxxxx54:NG",
            trigger_time: "2024-08-05 16:46:16",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: -97836.4,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "USD",
            timestamp: 1722840372,
            user: "Demo:MT4-Demo:xxxxx12:NG",
            trigger_time: "2024-08-05 16:46:12",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: -48919.5,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "XAU",
            timestamp: 1722840368,
            user: "Demo:MT4-Demo:xxxxx94:Ng",
            trigger_time: "2024-08-05 16:46:08",
        },

        {
            books: ["B"],
            broker: "Demo",
            exposure: 48648.29128832735,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "CAD",
            timestamp: 1722840422,
            user: "Demo:MT4-Demo:xxxxx21:Le",
            trigger_time: "2024-08-05 16:47:02",
        },

        {
            books: ["B"],
            broker: "Demo",
            exposure: 45009.50416141986,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "CAD",
            timestamp: 1722840388,
            user: "Demo:MT4-Demo:xxxxx92:LE",
            trigger_time: "2024-08-05 16:46:28",
        },

        {
            books: ["B"],
            broker: "Demo",
            exposure: 77846.79229719997,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "AUD",
            timestamp: 1722840384,
            user: "Demo:MT4-Demo:xxxxx64:Ng",
            trigger_time: "2024-08-05 16:46:24",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: 22942.071451599997,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "GBP",
            timestamp: 1722840368,
            user: "Demo:MT4-Demo:xxxxx91:NG",
            trigger_time: "2024-08-05 16:46:08",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: 115016.49219725204,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "CAD",
            timestamp: 1722840378,
            user: "Demo:MT4-Demo:xxxxx67:VO",
            trigger_time: "2024-08-05 16:46:18",
        },

        {
            books: ["B"],
            broker: "Demo",
            exposure: -45986.28059589239,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "USD",
            timestamp: 1722840372,
            user: "Demo:MT4-Demo:xxxxx89:Ph",
            trigger_time: "2024-08-05 16:46:12",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: 52420.3333789,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "NZD",
            timestamp: 1722840418,
            user: "Demo:MT4-Demo:xxxxx46:Tr",
            trigger_time: "2024-08-05 16:46:58",
        },

        {
            books: ["B"],
            broker: "Demo",
            exposure: 20480.970000000005,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "GBP",
            timestamp: 1722840422,
            user: "Demo:MT4-Demo:xxxxx72:PH",
            trigger_time: "2024-08-05 16:47:02",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: -57398.767601865795,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "USD",
            timestamp: 1722840422,
            user: "Demo:MT4-Demo:xxxxx80:Ng",
            trigger_time: "2024-08-05 16:47:02",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: -30583.8305204,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "EUR",
            timestamp: 1722840376,
            user: "Demo:MT4-Demo:xxxxx54:NG",
            trigger_time: "2024-08-05 16:46:16",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: -50704.419171074194,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "USD",
            timestamp: 1722840377,
            user: "Demo:MT4-Demo:xxxxx46:Tr",
            trigger_time: "2024-08-05 16:46:17",
        },

        {
            books: ["B"],
            broker: "Demo",
            exposure: 115014.45,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "USD",
            timestamp: 1722840399,
            user: "Demo:MT4-Demo:xxxxx18:Di",
            trigger_time: "2024-08-05 16:46:39",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: -342567.39999999997,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "XAU",
            timestamp: 1722840399,
            user: "Demo:MT4-Demo:xxxxx92:Du",
            trigger_time: "2024-08-05 16:46:39",
        },

        {
            books: ["B"],
            broker: "Demo",
            exposure: -25126.199623599998,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "EUR",
            timestamp: 1722840399,
            user: "Demo:MT4-Demo:xxxxx87:Tr",
            trigger_time: "2024-08-05 16:46:39",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: -96116.2060459,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "EUR",
            timestamp: 1722840418,
            user: "Demo:MT4-Demo:xxxxx46:Tr",
            trigger_time: "2024-08-05 16:46:58",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: 22652.5329888,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "AUD",
            timestamp: 1722840418,
            user: "Demo:MT4-Demo:xxxxx36:NG",
            trigger_time: "2024-08-05 16:46:58",
        },

        {
            books: ["B"],
            broker: "Demo",
            exposure: -57607.93000000001,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "GBP",
            timestamp: 1722840422,
            user: "Demo:MT4-Demo:xxxxx37:HA",
            trigger_time: "2024-08-05 16:47:02",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: 34561.737484799996,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "AUD",
            timestamp: 1722840405,
            user: "Demo:MT4-Demo:xxxxx54:NG",
            trigger_time: "2024-08-05 16:46:45",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: 342567.39999999997,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "USD",
            timestamp: 1722840399,
            user: "Demo:MT4-Demo:xxxxx92:Du",
            trigger_time: "2024-08-05 16:46:39",
        },

        {
            books: ["B"],
            broker: "Demo",
            exposure: -135020.0114014756,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "USD",
            timestamp: 1722840377,
            user: "Demo:MT4-Demo:xxxxx71:Ng",
            trigger_time: "2024-08-05 16:46:17",
        },

        {
            books: ["B"],
            broker: "Demo",
            exposure: 53890.148367099995,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "AUD",
            timestamp: 1722840405,
            user: "Demo:MT4-Demo:xxxxx37:Vu",
            trigger_time: "2024-08-05 16:46:45",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: 29117.376291839675,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "GBP",
            timestamp: 1722840372,
            user: "Demo:MT4-Demo:xxxxx00:Ng",
            trigger_time: "2024-08-05 16:46:12",
        },

        {
            books: ["B"],
            broker: "Demo",
            exposure: 63909.7416310111,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "CAD",
            timestamp: 1722840418,
            user: "Demo:MT4-Demo:xxxxx56:TH",
            trigger_time: "2024-08-05 16:46:58",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: 34249.36999999999,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "USD",
            timestamp: 1722840376,
            user: "Demo:MT4-Demo:xxxxx55:TR",
            trigger_time: "2024-08-05 16:46:16",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: 73390.8,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "XAU",
            timestamp: 1722840422,
            user: "Demo:MT4-Demo:xxxxx39:Vu",
            trigger_time: "2024-08-05 16:47:02",
        },

        {
            books: ["B"],
            broker: "Demo",
            exposure: 105359.26921255284,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "CAD",
            timestamp: 1722840418,
            user: "Demo:MT4-Demo:xxxxx70:Du",
            trigger_time: "2024-08-05 16:46:58",
        },

        {
            books: ["B"],
            broker: "Demo",
            exposure: 58374.689960100004,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "NZD",
            timestamp: 1722840401,
            user: "Demo:MT4-Demo:xxxxx35:Vu",
            trigger_time: "2024-08-05 16:46:41",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: 65021.4263118,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "AUD",
            timestamp: 1722840399,
            user: "Demo:MT4-Demo:xxxxx64:Ng",
            trigger_time: "2024-08-05 16:46:39",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: 46892.34923511281,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "CAD",
            timestamp: 1722840418,
            user: "Demo:MT4-Demo:xxxxx64:Ng",
            trigger_time: "2024-08-05 16:46:58",
        },

        {
            books: ["B"],
            broker: "Demo",
            exposure: 135013.19581692488,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "CAD",
            timestamp: 1722840422,
            user: "Demo:MT4-Demo:xxxxx84:NG",
            trigger_time: "2024-08-05 16:47:02",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: -153166.7293745052,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "USD",
            timestamp: 1722840416,
            user: "Demo:MT4-Demo:xxxxx00:Tr",
            trigger_time: "2024-08-05 16:46:56",
        },

        {
            books: ["B"],
            broker: "Demo",
            exposure: -75531.18534272735,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "GBP",
            timestamp: 1722840422,
            user: "Demo:MT4-Demo:xxxxx21:Le",
            trigger_time: "2024-08-05 16:47:02",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: 34262.67999999999,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "USD",
            timestamp: 1722840418,
            user: "Demo:MT4-Demo:xxxxx70:NG",
            trigger_time: "2024-08-05 16:46:58",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: -151048.5253189,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "GBP",
            timestamp: 1722840372,
            user: "Demo:MT4-Demo:xxxxx10:Du",
            trigger_time: "2024-08-05 16:46:12",
        },

        {
            books: ["B"],
            broker: "Demo",
            exposure: 55059.40067058551,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "GBP",
            timestamp: 1722840418,
            user: "Demo:MT4-Demo:xxxxx89:Ph",
            trigger_time: "2024-08-05 16:46:58",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: 135013.91695602945,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "CAD",
            timestamp: 1722840418,
            user: "Demo:MT4-Demo:xxxxx98:NG",
            trigger_time: "2024-08-05 16:46:58",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: 40302.947726321785,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "AUD",
            timestamp: 1722840399,
            user: "Demo:MT4-Demo:xxxxx18:DA",
            trigger_time: "2024-08-05 16:46:39",
        },

        {
            books: ["B"],
            broker: "Demo",
            exposure: -98011.19129952138,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "USD",
            timestamp: 1722840418,
            user: "Demo:MT4-Demo:xxxxx32:NG",
            trigger_time: "2024-08-05 16:46:58",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: 57185.0289244,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "NZD",
            timestamp: 1722840418,
            user: "Demo:MT4-Demo:xxxxx92:LE",
            trigger_time: "2024-08-05 16:46:58",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: 102754.89,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "USD",
            timestamp: 1722840407,
            user: "Demo:MT4-Demo:xxxxx25:Ng",
            trigger_time: "2024-08-05 16:46:47",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: -115016.49219725204,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "USD",
            timestamp: 1722840378,
            user: "Demo:MT4-Demo:xxxxx67:VO",
            trigger_time: "2024-08-05 16:46:18",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: 20002.213740837346,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "CAD",
            timestamp: 1722840399,
            user: "Demo:MT4-Demo:xxxxx80:Ng",
            trigger_time: "2024-08-05 16:46:39",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: 76007.56415661202,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "CAD",
            timestamp: 1722840418,
            user: "Demo:MT4-Demo:xxxxx39:NG",
            trigger_time: "2024-08-05 16:46:58",
        },

        {
            books: ["B"],
            broker: "Demo",
            exposure: -45303.11172162309,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "USD",
            timestamp: 1722840372,
            user: "Demo:MT4-Demo:xxxxx99:Tr",
            trigger_time: "2024-08-05 16:46:12",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: 47288.03517509887,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "CAD",
            timestamp: 1722840372,
            user: "Demo:MT4-Demo:xxxxx80:NG",
            trigger_time: "2024-08-05 16:46:12",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: -166251.84000000003,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "XAU",
            timestamp: 1722840418,
            user: "Demo:MT4-Demo:xxxxx95:Jo",
            trigger_time: "2024-08-05 16:46:58",
        },

        {
            books: ["B"],
            broker: "Demo",
            exposure: -48921.8,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "XAU",
            timestamp: 1722840376,
            user: "Demo:MT4-Demo:xxxxx97:Ph",
            trigger_time: "2024-08-05 16:46:16",
        },

        {
            books: ["B"],
            broker: "Demo",
            exposure: -135019.6147662549,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "USD",
            timestamp: 1722840376,
            user: "Demo:MT4-Demo:xxxxx86:NG",
            trigger_time: "2024-08-05 16:46:16",
        },

        {
            books: ["B"],
            broker: "Demo",
            exposure: 75388.07430588716,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "CAD",
            timestamp: 1722840372,
            user: "Demo:MT4-Demo:xxxxx99:Tr",
            trigger_time: "2024-08-05 16:46:12",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: -226911.84671175186,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "USD",
            timestamp: 1722840418,
            user: "Demo:MT4-Demo:xxxxx38:Lu",
            trigger_time: "2024-08-05 16:46:58",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: -784359.8,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "USD",
            timestamp: 1722840388,
            user: "Demo:MT4-Demo:xxxxx02:NG",
            trigger_time: "2024-08-05 16:46:28",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: -159056.95,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "XAU",
            timestamp: 1722840377,
            user: "Demo:MT4-Demo:xxxxx60:SU",
            trigger_time: "2024-08-05 16:46:17",
        },

        {
            books: ["B"],
            broker: "Demo",
            exposure: 135406.0286045,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "AUD",
            timestamp: 1722840399,
            user: "Demo:MT4-Demo:xxxxx99:Tr",
            trigger_time: "2024-08-05 16:46:39",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: -20755.22573232194,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "EUR",
            timestamp: 1722840376,
            user: "Demo:MT4-Demo:xxxxx22:Ng",
            trigger_time: "2024-08-05 16:46:16",
        },

        {
            books: ["B"],
            broker: "Demo",
            exposure: -115015.35256286398,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "USD",
            timestamp: 1722840386,
            user: "Demo:MT4-Demo:xxxxx87:NG",
            trigger_time: "2024-08-05 16:46:26",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: 258636.00000000003,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "USD",
            timestamp: 1722840422,
            user: "Demo:MT4-Demo:xxxxx37:HA",
            trigger_time: "2024-08-05 16:47:02",
        },

        {
            books: ["B"],
            broker: "Demo",
            exposure: 60769.180184900004,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "NZD",
            timestamp: 1722840372,
            user: "Demo:MT4-Demo:xxxxx10:Du",
            trigger_time: "2024-08-05 16:46:12",
        },

        {
            books: ["B"],
            broker: "Demo",
            exposure: -102754.89,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "XAU",
            timestamp: 1722840407,
            user: "Demo:MT4-Demo:xxxxx25:Ng",
            trigger_time: "2024-08-05 16:46:47",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: 112558.19999999995,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "USD",
            timestamp: 1722840416,
            user: "Demo:MT4-Demo:xxxxx12:Di",
            trigger_time: "2024-08-05 16:46:56",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: 34566.70270726176,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "CAD",
            timestamp: 1722840378,
            user: "Demo:MT4-Demo:xxxxx34:Le",
            trigger_time: "2024-08-05 16:46:18",
        },

        {
            books: ["B"],
            broker: "Demo",
            exposure: 54410.90793519932,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "CAD",
            timestamp: 1722840422,
            user: "Demo:MT4-Demo:xxxxx70:PH",
            trigger_time: "2024-08-05 16:47:02",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: -142006.2636657201,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "EUR",
            timestamp: 1722840401,
            user: "Demo:MT4-Demo:xxxxx35:Vu",
            trigger_time: "2024-08-05 16:46:41",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: 73407.16264279993,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "CAD",
            timestamp: 1722840418,
            user: "Demo:MT4-Demo:xxxxx51:Le",
            trigger_time: "2024-08-05 16:46:58",
        },

        {
            books: ["B"],
            broker: "Demo",
            exposure: -65006.4897465641,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "USD",
            timestamp: 1722840418,
            user: "Demo:MT4-Demo:xxxxx18:NG",
            trigger_time: "2024-08-05 16:46:58",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: -145281.50687519528,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "EUR",
            timestamp: 1722840416,
            user: "Demo:MT4-Demo:xxxxx99:Tr",
            trigger_time: "2024-08-05 16:46:56",
        },

        {
            books: ["B"],
            broker: "Demo",
            exposure: -51363.33,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "XAU",
            timestamp: 1722840372,
            user: "Demo:MT4-Demo:xxxxx32:IW",
            trigger_time: "2024-08-05 16:46:12",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: 44025.79,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "USD",
            timestamp: 1722840372,
            user: "Demo:MT4-Demo:xxxxx37:IW",
            trigger_time: "2024-08-05 16:46:12",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: 24444.566638201133,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "USD",
            timestamp: 1722840368,
            user: "Demo:MT4-Demo:xxxxx21:Ng",
            trigger_time: "2024-08-05 16:46:08",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: 90848.02042959997,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "AUD",
            timestamp: 1722840399,
            user: "Demo:MT4-Demo:xxxxx18:Ph",
            trigger_time: "2024-08-05 16:46:39",
        },

        {
            books: ["B"],
            broker: "Demo",
            exposure: -50347.08876675165,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "USD",
            timestamp: 1722840422,
            user: "Demo:MT4-Demo:xxxxx70:PH",
            trigger_time: "2024-08-05 16:47:02",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: -48924.5,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "XAU",
            timestamp: 1722840368,
            user: "Demo:MT4-Demo:xxxxx36:Le",
            trigger_time: "2024-08-05 16:46:08",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: -131163.39564624047,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "USD",
            timestamp: 1722840418,
            user: "Demo:MT4-Demo:xxxxx91:NG",
            trigger_time: "2024-08-05 16:46:58",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: 69523.2560046,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "NZD",
            timestamp: 1722840372,
            user: "Demo:MT4-Demo:xxxxx18:Ph",
            trigger_time: "2024-08-05 16:46:12",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: -80778.8819692596,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "NZD",
            timestamp: 1722840418,
            user: "Demo:MT4-Demo:xxxxx89:Ph",
            trigger_time: "2024-08-05 16:46:58",
        },

        {
            books: ["B"],
            broker: "Demo",
            exposure: -78089.60626730057,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "GBP",
            timestamp: 1722840399,
            user: "Demo:MT4-Demo:xxxxx99:Tr",
            trigger_time: "2024-08-05 16:46:39",
        },

        {
            books: ["B"],
            broker: "Demo",
            exposure: 52797.1803462,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "AUD",
            timestamp: 1722840407,
            user: "Demo:MT4-Demo:xxxxx46:Tr",
            trigger_time: "2024-08-05 16:46:47",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: -100036.32954412283,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "CHF",
            timestamp: 1722840414,
            user: "Demo:MT4-Demo:xxxxx81:Su",
            trigger_time: "2024-08-05 16:46:54",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: 33152.65379130718,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "AUD",
            timestamp: 1722840399,
            user: "Demo:MT4-Demo:xxxxx00:Tr",
            trigger_time: "2024-08-05 16:46:39",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: 65006.4897465641,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "CAD",
            timestamp: 1722840418,
            user: "Demo:MT4-Demo:xxxxx18:NG",
            trigger_time: "2024-08-05 16:46:58",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: 29338.56,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "USD",
            timestamp: 1722840422,
            user: "Demo:MT4-Demo:xxxxx33:WO",
            trigger_time: "2024-08-05 16:47:02",
        },

        {
            books: ["B"],
            broker: "Demo",
            exposure: -46243.80116917409,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "USD",
            timestamp: 1722840372,
            user: "Demo:MT4-Demo:xxxxx80:NG",
            trigger_time: "2024-08-05 16:46:12",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: 22014.54,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "USD",
            timestamp: 1722840420,
            user: "Demo:MT4-Demo:xxxxx95:CH",
            trigger_time: "2024-08-05 16:47:00",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: 260032.81024226124,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "CAD",
            timestamp: 1722840372,
            user: "Demo:MT4-Demo:xxxxx32:NG",
            trigger_time: "2024-08-05 16:46:12",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: -46090.15901386577,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "GBP",
            timestamp: 1722840422,
            user: "Demo:MT4-Demo:xxxxx24:Ng",
            trigger_time: "2024-08-05 16:47:02",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: 24469.1,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "USD",
            timestamp: 1722840399,
            user: "Demo:MT4-Demo:xxxxx61:Ng",
            trigger_time: "2024-08-05 16:46:39",
        },
        {
            books: ["B"],
            broker: "Demo",
            exposure: -57608.1,
            force: false,
            messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
            priority: 1,
            symbol: "GBP",
            timestamp: 1722840422,
            user: "Demo:MT4-Demo:xxxxx16:Wa",
            trigger_time: "2024-08-05 16:47:02",
        },
    ],
    monitorData:[],
    monitorHeader: [
        { text: "User", value: "user", align: "left" },
        { text: "Books", value: "books", align: "left" },
        { text: "Symbol", value: "symbol", align: "left" },
        { text: "Broker", value: "broker", align: " d-none" },
        { text: "Exposure", value: "exposure", align: "left" },
        { text: "TH", value: "exposureThreshold", align: "left" },
        { text: "Message Type", value: "messageType", align: " d-none" },
        { text: "Timestamp", value: "timestamp", align: " d-none" },
        { text: "Trigger Time", value: "trigger_time", align: "left" },
    ],
    alertData: [],
    alertHeader: [
        { text: "Login", value: "login", align: "left" },
        { text: "Symbol", value: "symbol", align: "left" },
        { text: "Exposure", value: "exposure", align: "left" },
        { text: "Threshold", value: "exposure_threshold", align: "left" },
        { text: "Status", value: "alert_status", align: "left" },
        { text: "Trigger Time", value: "trigger_time", align: "left" },
    ],
    currentBook: "",
    lastSelectedBook: "",
};

const actions = {
    /**
     * Process alert data
     * @param   {[type]}  commit  [commit description]
     * @param   {[type]}  data    [data description]
     *
     * @return  {[type]}          [return description]
     */
    processLargeExposure({ commit, state }, data) {
        if (
            state.currentBook != state.lastSelectedBook ||
            parseInt(Date.now() / 1000) % 5 == 0
        ) {
            let result = [];
            for (let item in data) {
                if (data[item].books[0] == state.currentBook) {
                    let temp = data[item];
                    // temp["symbol"] = temp["symbol"].split("_")[0];
                    temp["trigger_time"] =
                        common.convertUnixtimeToDatetimeString(temp.timestamp);
                    result.push(temp);
                }
            }
            state.lastSelectedBook = state.currentBook;
            commit("UPDATE_MONITOR_DATA", result);
        }
    },
};

const mutations = {
    /**
     * Update monitor data
     * @param   {[type]}  state  [state description]
     * @param   {[type]}  data   [data description]
     * @return  {[type]}         [return description]
     */
    UPDATE_MONITOR_DATA(state, data) {
        state.monitorData = data;
    },
    UPDATE_MONITOR_DATA_DEFAULT(state) {
        state.monitorData = state.default;
    },
    /**
     * Update alert data
     * @param   {[type]}  state  [state description]
     * @param   {[type]}  data   [data description]
     * @return  {[type]}         [return description]
     */
    UPDATE_ALERT_DATA(state, data) {
        state.alertData = data;
    },
    /**
     * Update loading
     * @param   {[type]}  state  [state description]
     * @param   {[type]}  data   [data description]
     * @return  {[type]}         [return description]
     */
    UPDATE_LOADING(state, data) {
        state.loading = data;
    },
    UPDATE_CURRENT_BOOK(state, data) {
        state.currentBook = data;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
