
export function getPNLSummary(query) {
    const mockData = {
        status: 200,
        data: []
    };
    const returnVal = new Promise((resolve, _) => resolve(mockData));
    return returnVal;
}

