
export function getLoginSummary(query) {
    const mockData = {
        status: 200,
        data: [
            {
                "book": "B",
                "group": "M_STD_USD",
                "equity": 428.65,
                "balance": 680.86,
                "credit": 1000,
                "previous_day_pnl": -421.34,
                "total_pnl": -3571.35,
                "total_volume(USD)": 33372253.09,
                "dpm": -125.4135,
                "deposit": 5000,
                "withdrawal": -1000,
                "net_deposit": 4000
            }
        ]
    };
    const returnVal = new Promise((resolve, _) => resolve(mockData));
    return returnVal;
}
