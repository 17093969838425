export function getLPPositionRec(query) {
    const mockData = {
        status: 200,
        data: [
            {
                vol_pk: -32,
                vol_lp: 0,
                diff: -32,
                break: "Yes",
                index: "GBPJPY",
                updated_at: "2023-08-24 10:00:03",
            },
            {
                vol_pk: -828,
                vol_lp: -1461,
                diff: 633,
                break: "Yes",
                index: "XAUUSD",
                updated_at: "2023-08-24 10:00:03",
            },
            {
                vol_pk: 1000,
                vol_lp: 0,
                diff: 1000,
                break: "Yes",
                index: "BTCUSD",
                updated_at: "2023-08-24 10:00:03",
            },
            {
                vol_pk: -8000,
                vol_lp: -8000,
                diff: 0,
                break: "No",
                index: "USDJPY",
                updated_at: "2023-08-24 10:00:03",
            },
            {
                vol_pk: 16000,
                vol_lp: 16000,
                diff: 0,
                break: "No",
                index: "USDNOK",
                updated_at: "2023-08-24 10:00:03",
            },
            {
                vol_pk: -865000,
                vol_lp: -865000,
                diff: 0,
                break: "No",
                index: "USDSEK",
                updated_at: "2023-08-24 10:00:03",
            },
            {
                vol_pk: 3.14,
                vol_lp: 3.14,
                diff: 0,
                break: "No",
                index: "GBPAUD",
                updated_at: "2023-08-24 10:00:03",
            },
            {
                vol_pk: 10000,
                vol_lp: 10000,
                diff: 0,
                break: "No",
                index: "EURUSD",
                updated_at: "2023-08-24 10:00:03",
            },
            {
                vol_pk: 2000,
                vol_lp: 2000,
                diff: 0,
                break: "No",
                index: "EURAUD",
                updated_at: "2023-08-24 10:00:03",
            },
            {
                vol_pk: -3000,
                vol_lp: -3000,
                diff: 0,
                break: "No",
                index: "GBPUSD",
                updated_at: "2023-08-24 10:00:03",
            },
            {
                vol_pk: 2,
                vol_lp: 2,
                diff: 0,
                break: "No",
                index: "GBPCAD",
                updated_at: "2023-08-24 10:00:03",
            },
        ],
    };
    const returnVal = new Promise((resolve, _) => resolve(mockData));
    return returnVal;
}
