
export function getPermission() {
    const mockData = {
        status: 200,
        data: [
            {
                id: 1,
                permission_name: "Root",
                permission: {
                    "broker": [],
                    "frontPermission": {
                        "book": {
                            "child": [],
                            "function": [
                                "homeLoginDetailSummary",
                                "extraSymbolDetails",
                                "dailySummaryIcon",
                                "mtdVolumeColumn",
                                "mtdPnlColumn",
                                "loginSummaryInfo",
                                "loginSummaryStatistic",
                                "loginSummaryTrades"
                            ]
                        },
                        "lp": {
                            "child": []
                        },
                        "search": {
                            "child": []
                        },
                        "alert": {
                            "child": [
                                "overview"
                            ]
                        },
                        "validation": {
                            "child": [
                                "net"
                            ]
                        },
                        "settings": {
                            "child": [
                                "alertConfig",
                                "brokerConfig",
                                "serverConfig",
                                "bookConfig",
                                "pkTaker",
                                "routingConfig",
                                "copyTradeConfig",
                                "lpConfig",
                                "notificationConfig"
                            ],
                            "function": [
                                "alertConfigEdit"
                            ]
                        },
                        "system": {
                            "child": [
                                "group",
                                "bookGroupRules",
                                "userPerformance",
                                "snapshot",
                                "symbolRule",
                                "tick",
                                "ticklive",
                                "alertConfiguration",
                                "notification"
                            ]
                        },
                        "k8s": {
                            "child": [
                                "index"
                            ]
                        },
                        "account": {
                            "child": [
                                "profile",
                                "config",
                                "permission",
                                "management"
                            ]
                        },
                        "risk": {
                            "child": [
                                "prometheus",
                                "notification"
                            ],
                            "function": []
                        },
                        "copyTrade": {
                            "child": [
                                "index"
                            ]
                        }
                    },
                    "backendPermission": "*",
                    "allowJsonEditor": 1,
                    "whitelist": [
                        "*"
                    ],
                    "blocklist": []
                },
                created_at: "2022-08-16 21:43:29",
                updated_at: "2023-08-24 12:09:43",
            },
            {
                id: 2,
                permission_name: "Basic",
                permission: {
                    broker: [],
                    frontPermission: {
                        book: {
                            child: [],
                            function: [
                                "homeLoginDetailSummary",
                                "loginSummaryTrades",
                            ],
                        },
                        account: {
                            child: ["profile", "config"],
                        },
                        alert: {
                            child: ["overview"],
                        },
                        settings: {
                            child: [
                                "alertConfig",
                                "bookConfig",
                                "routingConfig",
                            ],
                            function: ["alertConfigEdit"],
                        },
                    },
                    allowJsonEditor: 1,
                    whitelist: ["*"],
                    blocklist: [],
                },
                created_at: "2022-09-01 16:31:37",
                updated_at: "2023-03-03 15:24:27",
            },
        ],
    };
    const returnVal = new Promise((resolve, _) => resolve(mockData));
    return returnVal;
}

export function postPermission(data) {
    const mockData = {
        status: 200,
        data:  { 'msg': 'Create permission successfully' }
    };
    const returnVal = new Promise((resolve, _) => resolve(mockData));
    return returnVal;
}

export function putPermission(data) {
    const mockData = {
        status: 200,
        data:  { 'msg': 'Update permission successfully' }
    };
    const returnVal = new Promise((resolve, _) => resolve(mockData));
    return returnVal;
}

export function deletePermission(data) {
    const mockData = {
        status: 200,
        data:  { 'msg': 'Delete permission successfully' }
    };
    const returnVal = new Promise((resolve, _) => resolve(mockData));
    return returnVal;
}
