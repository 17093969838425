
export function getDataFastTrade(query) {
    const mockData = {
        status: 200,
        data: {
            "data": [
                {
                    "server_id": 35,
                    "login": "xxxxxx01",
                    "name": "LAI",
                    "volume": 1,
                    "symbol": "USTEC",
                    "books": "[B]",
                    "order": 203693693,
                    "direction": "LONG",
                    "threshold_volume": 0.2,
                    "threshold_time": 120,
                    "open_time": "2024-08-05 03:26:11",
                    "close_time": "2024-08-05 03:27:00",
                    "server_name": "MT4-Demo",
                    "trigger_time": "2024-08-05 03:27:00",
                    "duration": 49
                },
                {
                    "server_id": 35,
                    "login": "xxxxxx01",
                    "name": "LAI",
                    "volume": 50,
                    "symbol": "JP225",
                    "books": "[B]",
                    "order": 203693734,
                    "direction": "LONG",
                    "threshold_volume": 0.2,
                    "threshold_time": 120,
                    "open_time": "2024-08-05 03:26:39",
                    "close_time": "2024-08-05 03:26:42",
                    "server_name": "MT5-Demo",
                    "trigger_time": "2024-08-05 03:26:42",
                    "duration": 3
                },
                {
                    "server_id": 35,
                    "login": "xxxxxx01",
                    "name": "LAI",
                    "volume": 50,
                    "symbol": "JP225",
                    "books": "[B]",
                    "order": 203693742,
                    "direction": "LONG",
                    "threshold_volume": 0.2,
                    "threshold_time": 120,
                    "open_time": "2024-08-05 03:26:40",
                    "close_time": "2024-08-05 03:26:42",
                    "server_name": "MT4-Demo",
                    "trigger_time": "2024-08-05 03:26:42",
                    "duration": 2
                },
                {
                    "server_id": 15,
                    "login": "xxxxxx21",
                    "name": "Cedeno",
                    "volume": 3,
                    "symbol": "US500",
                    "books": "[B]",
                    "order": 103231789,
                    "direction": "LONG",
                    "threshold_volume": 0.2,
                    "threshold_time": 120,
                    "open_time": "2024-08-05 03:25:27",
                    "close_time": "2024-08-05 03:26:28",
                    "server_name": "MT5-Demo",
                    "trigger_time": "2024-08-05 03:26:28",
                    "duration": 61
                }
            ],
            "count": 4
        }
    };
    const returnVal = new Promise((resolve, _) => resolve(mockData));
    return returnVal;
}
