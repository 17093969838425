
export function getRawHistory(query) {
    const mockData = {
        status: 200,
        data: [
            {
                login: "xxxxx13",
                ticket: "xxxxx48",
                symbol: "XTIUSD",
                cmd: 1,
                volume: 10,
                open_time: "2023-02-08 10:00:26",
                open_price: 77.621,
                close_time: "2023-02-08 10:36:34",
                close_price: 77.288,
                profit: 33.3,
                sl: 0,
                tp: 0,
                swaps: 0,
                commission: -2,
                comment: "",
                reason: 0,
                book_a_name: "B",
                percentage_a: 100,
                book_b_name: "S",
                percentage_b: 0,
            },
            {
                login: "xxxxx13",
                ticket: "xxxxx53",
                symbol: "XTIUSD",
                cmd: 1,
                volume: 100,
                open_time: "2023-02-07 18:06:01",
                open_price: 75.848,
                close_time: "2023-02-08 10:51:24",
                close_price: 77.771,
                profit: -1923,
                sl: 0,
                tp: 0,
                swaps: 3.62,
                commission: -20,
                comment: "so: 46.6%/215.0/461.4",
                reason: 0,
                book_a_name: "B",
                percentage_a: 100,
                book_b_name: "S",
                percentage_b: 0,
            },
            {
                login: "xxxxx08",
                ticket: "xxxxx55",
                symbol: "GBPUSD",
                cmd: 0,
                volume: 1,
                open_time: "2023-02-03 15:30:33",
                open_price: 1.2158,
                close_time: "2023-02-03 15:58:45",
                close_price: 1.21227,
                profit: -3.53,
                sl: 1.1997,
                tp: 1.299,
                swaps: 0,
                commission: 0,
                comment: "",
                reason: 5,
                book_a_name: "B",
                percentage_a: 100,
                book_b_name: "S",
                percentage_b: 0,
            },
            {
                login: "xxxxx28",
                ticket: "xxxxx52",
                symbol: "GBPUSD",
                cmd: 0,
                volume: 1,
                open_time: "2023-02-03 15:30:33",
                open_price: 1.21583,
                close_time: "2023-02-03 15:58:22",
                close_price: 1.21261,
                profit: -3.22,
                sl: 1.1997,
                tp: 1.299,
                swaps: 0,
                commission: 0,
                comment: "",
                reason: 5,
                book_a_name: "B",
                percentage_a: 100,
                book_b_name: "S",
                percentage_b: 0,
            },
            {
                login: "xxxxx12",
                ticket: "xxxxx77",
                symbol: "XAUUSD",
                cmd: 1,
                volume: 8,
                open_time: "2023-02-01 21:46:46",
                open_price: 1939.92,
                close_time: "2023-02-01 21:47:41",
                close_price: 1939.72,
                profit: 1.6,
                sl: 0,
                tp: 0,
                swaps: 0,
                commission: 0,
                comment: "",
                reason: 5,
                book_a_name: "B",
                percentage_a: 100,
                book_b_name: "S",
                percentage_b: 0,
            },
            {
                login: "xxxxx80",
                ticket: "xxxxx02",
                symbol: "XAUUSD",
                cmd: 1,
                volume: 4,
                open_time: "2023-01-31 15:30:00",
                open_price: 1912.03,
                close_time: "2023-02-01 21:00:26",
                close_price: 1922.18,
                profit: -40.6,
                sl: 0,
                tp: 1915,
                swaps: 0.72,
                commission: -0.4,
                comment: "",
                reason: 5,
                book_a_name: "B",
                percentage_a: 100,
                book_b_name: "S",
                percentage_b: 0,
            },
            {
                login: "xxxxx38",
                ticket: "xxxxx35",
                symbol: "XAUUSD",
                cmd: 0,
                volume: 1,
                open_time: "2023-01-31 10:14:11",
                open_price: 1909.61,
                close_time: "2023-01-31 14:47:05",
                close_price: 1909.7,
                profit: 0.09,
                sl: 0,
                tp: 1911,
                swaps: 0,
                commission: -0.1,
                comment: "",
                reason: 5,
                book_a_name: "B",
                percentage_a: 100,
                book_b_name: "S",
                percentage_b: 0,
            },
            {
                login: "xxxxx87",
                ticket: "xxxxx36",
                symbol: "XAUUSD",
                cmd: 0,
                volume: 3,
                open_time: "2023-01-31 10:12:34",
                open_price: 1910,
                close_time: "2023-01-31 15:35:52",
                close_price: 1915.05,
                profit: 15.15,
                sl: 1899,
                tp: 1925,
                swaps: 0,
                commission: -0.6,
                comment: "",
                reason: 5,
                book_a_name: "B",
                percentage_a: 100,
                book_b_name: "S",
                percentage_b: 0,
            },
        ],
    };
    const returnVal = new Promise((resolve, _) => resolve(mockData));
    return returnVal;
}
