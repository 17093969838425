import { getDailyLoser } from "@services/alert/daily-loser";
import common from "@assets/js/common.js";

const state = {
    autoRefreshTimeOffset: 1,
    loading: false,
    alertData: [
        {
            books: "B_EUR",
            broker: "Demo",
            force: false,
            messageType: "MSG_TYPE_MONITOR_DAILY_WINNER_LOSER",
            priority: 1,
            profitClosed: -178.75,
            profitClosedMT4: -178.75,
            recordDate: "2024-08-05",
            threshold: -100,
            timestamp: 1722843379,
            user: "Demo:MT4-Demo:xxxxx99:",
            server_name: "MT4-Demo",
            login: "xxxxx99",
            name: "Maur",
            closed_profit_eod: -178.75,
            closed_profit: -178.75,
        },
        {
            books: "B",
            broker: "Demo",
            force: false,
            messageType: "MSG_TYPE_MONITOR_DAILY_WINNER_LOSER",
            priority: 1,
            profitClosed: -149.29,
            profitClosedMT4: -109.48,
            recordDate: "2024-08-05",
            threshold: -100,
            timestamp: 1722843375,
            user: "Demo:MT4-Demo:xxxxx21:",
            server_name: "MT4-Demo",
            login: "xxxxx21",
            name: "Mari",
            closed_profit_eod: -149.29,
            closed_profit: -109.48,
        },
        {
            books: "B",
            broker: "Demo",
            force: false,
            messageType: "MSG_TYPE_MONITOR_DAILY_WINNER_LOSER",
            priority: 1,
            profitClosed: -1486.85,
            profitClosedMT4: -1486.85,
            recordDate: "2024-08-05",
            threshold: -100,
            timestamp: 1722843356,
            user: "Demo:MT4-Demo:xxxxx60:",
            server_name: "MT4-Demo",
            login: "xxxxx60",
            name: "SUMA",
            closed_profit_eod: -1486.85,
            closed_profit: -1486.85,
        },
        {
            books: "B_EUR",
            broker: "Demo",
            force: false,
            messageType: "MSG_TYPE_MONITOR_DAILY_WINNER_LOSER",
            priority: 1,
            profitClosed: -1111.9,
            profitClosedMT4: -1111.9,
            recordDate: "2024-08-05",
            threshold: -100,
            timestamp: 1722843375,
            user: "Demo:MT4-Demo:xxxxx85:",
            server_name: "MT4-Demo",
            login: "xxxxx85",
            name: "Gius",
            closed_profit_eod: -1111.9,
            closed_profit: -1111.9,
        },
        {
            books: "TEST",
            broker: "Demo",
            force: false,
            messageType: "MSG_TYPE_MONITOR_DAILY_WINNER_LOSER",
            priority: 1,
            profitClosed: -304,
            profitClosedMT4: -304,
            recordDate: "2024-08-05",
            threshold: -100,
            timestamp: 1722843377,
            user: "Demo:MT4-Demo:xxxxx50:",
            server_name: "MT4-Demo",
            login: "xxxxx50",
            name: "Truo",
            closed_profit_eod: -304,
            closed_profit: -304,
        },
        {
            books: "TEST",
            broker: "Demo",
            force: false,
            messageType: "MSG_TYPE_MONITOR_DAILY_WINNER_LOSER",
            priority: 1,
            profitClosed: -558.51,
            profitClosedMT4: -2970.66,
            recordDate: "2024-08-05",
            threshold: -100,
            timestamp: 1722843351,
            user: "Demo:MT4-Demo:xxxxx74:",
            server_name: "MT4-Demo",
            login: "xxxxx74",
            name: "Nguy",
            closed_profit_eod: -558.51,
            closed_profit: -2970.66,
        },
        {
            books: "TEST",
            broker: "Demo",
            force: false,
            messageType: "MSG_TYPE_MONITOR_DAILY_WINNER_LOSER",
            priority: 1,
            profitClosed: -390.4,
            profitClosedMT4: -390.4,
            recordDate: "2024-08-05",
            threshold: -100,
            timestamp: 1722843360,
            user: "Demo:MT4-Demo:xxxxx09:",
            server_name: "MT4-Demo",
            login: "xxxxx09",
            name: "Tran",
            closed_profit_eod: -390.4,
            closed_profit: -390.4,
        },
        {
            books: "TEST",
            broker: "Demo",
            force: false,
            messageType: "MSG_TYPE_MONITOR_DAILY_WINNER_LOSER",
            priority: 1,
            profitClosed: -7660,
            profitClosedMT4: -7660,
            recordDate: "2024-08-05",
            threshold: -100,
            timestamp: 1722843398,
            user: "Demo:MT4-Demo:xxxxx24:",
            server_name: "MT4-Demo",
            login: "xxxxx24",
            name: "DUON",
            closed_profit_eod: -7660,
            closed_profit: -7660,
        },
        {
            books: "TEST",
            broker: "Demo",
            force: false,
            messageType: "MSG_TYPE_MONITOR_DAILY_WINNER_LOSER",
            priority: 1,
            profitClosed: -1813.9,
            profitClosedMT4: -1813.9,
            recordDate: "2024-08-05",
            threshold: -100,
            timestamp: 1722843360,
            user: "Demo:MT4-Demo:xxxxx25:",
            server_name: "MT4-Demo",
            login: "xxxxx25",
            name: "Nguy",
            closed_profit_eod: -1813.9,
            closed_profit: -1813.9,
        },
        {
            books: "B",
            broker: "Demo",
            force: false,
            messageType: "MSG_TYPE_MONITOR_DAILY_WINNER_LOSER",
            priority: 1,
            profitClosed: -4405.2,
            profitClosedMT4: -2341.2,
            recordDate: "2024-08-05",
            threshold: -100,
            timestamp: 1722843365,
            user: "Demo:MT4-Demo:xxxxx02:",
            server_name: "MT4-Demo",
            login: "xxxxx02",
            name: "NGUY",
            closed_profit_eod: -4405.2,
            closed_profit: -2341.2,
        },
        {
            books: "TEST",
            broker: "Demo",
            force: false,
            messageType: "MSG_TYPE_MONITOR_DAILY_WINNER_LOSER",
            priority: 1,
            profitClosed: -5590,
            profitClosedMT4: -5590,
            recordDate: "2024-08-05",
            threshold: -100,
            timestamp: 1722843392,
            user: "Demo:MT4-Demo:xxxxx78:",
            server_name: "MT4-Demo",
            login: "xxxxx78",
            name: "Ngo ",
            closed_profit_eod: -5590,
            closed_profit: -5590,
        },
        {
            books: "TEST",
            broker: "Demo",
            force: false,
            messageType: "MSG_TYPE_MONITOR_DAILY_WINNER_LOSER",
            priority: 1,
            profitClosed: -296,
            profitClosedMT4: 1081.24,
            recordDate: "2024-08-05",
            threshold: -100,
            timestamp: 1722843406,
            user: "Demo:MT4-Demo:xxxxx06:",
            server_name: "MT4-Demo",
            login: "xxxxx06",
            name: "Nguy",
            closed_profit_eod: -296,
            closed_profit: 1081.24,
        },
        {
            books: "TEST",
            broker: "Demo",
            force: false,
            messageType: "MSG_TYPE_MONITOR_DAILY_WINNER_LOSER",
            priority: 1,
            profitClosed: -371.6,
            profitClosedMT4: -371.6,
            recordDate: "2024-08-05",
            threshold: -100,
            timestamp: 1722843399,
            user: "Demo:MT4-Demo:xxxxx87:",
            server_name: "MT4-Demo",
            login: "xxxxx87",
            name: "Nguy",
            closed_profit_eod: -371.6,
            closed_profit: -371.6,
        },
        {
            books: "B",
            broker: "Demo",
            force: false,
            messageType: "MSG_TYPE_MONITOR_DAILY_WINNER_LOSER",
            priority: 1,
            profitClosed: -761.1,
            profitClosedMT4: -761.1,
            recordDate: "2024-08-05",
            threshold: -100,
            timestamp: 1722843360,
            user: "Demo:MT4-Demo:xxxxx94:",
            server_name: "MT4-Demo",
            login: "xxxxx94",
            name: "Nguy",
            closed_profit_eod: -761.1,
            closed_profit: -761.1,
        },
        {
            books: "TEST",
            broker: "Demo",
            force: false,
            messageType: "MSG_TYPE_MONITOR_DAILY_WINNER_LOSER",
            priority: 1,
            profitClosed: -2329.8,
            profitClosedMT4: -2329.8,
            recordDate: "2024-08-05",
            threshold: -100,
            timestamp: 1722843375,
            user: "Demo:MT4-Demo:xxxxx53:",
            server_name: "MT4-Demo",
            login: "xxxxx53",
            name: "Nguy",
            closed_profit_eod: -2329.8,
            closed_profit: -2329.8,
        },
        {
            books: "TEST",
            broker: "Demo",
            force: false,
            messageType: "MSG_TYPE_MONITOR_DAILY_WINNER_LOSER",
            priority: 1,
            profitClosed: -236.2,
            profitClosedMT4: -236.2,
            recordDate: "2024-08-05",
            threshold: -100,
            timestamp: 1722843379,
            user: "Demo:MT4-Demo:xxxxx75:",
            server_name: "MT4-Demo",
            login: "xxxxx75",
            name: "Tran",
            closed_profit_eod: -236.2,
            closed_profit: -236.2,
        },
        {
            books: "B",
            broker: "Demo",
            force: false,
            messageType: "MSG_TYPE_MONITOR_DAILY_WINNER_LOSER",
            priority: 1,
            profitClosed: -401.84,
            profitClosedMT4: -401.84,
            recordDate: "2024-08-05",
            threshold: -100,
            timestamp: 1722843379,
            user: "Demo:MT4-Demo:xxxxx94:",
            server_name: "MT4-Demo",
            login: "xxxxx94",
            name: "Pham",
            closed_profit_eod: -401.84,
            closed_profit: -401.84,
        },
        {
            books: "TEST",
            broker: "Demo",
            force: false,
            messageType: "MSG_TYPE_MONITOR_DAILY_WINNER_LOSER",
            priority: 1,
            profitClosed: -1000,
            profitClosedMT4: -1000,
            recordDate: "2024-08-05",
            threshold: -100,
            timestamp: 1722843365,
            user: "Demo:MT4-Demo:xxxxx45:",
            server_name: "MT4-Demo",
            login: "xxxxx45",
            name: "Xian",
            closed_profit_eod: -1000,
            closed_profit: -1000,
        },
        {
            books: "TEST",
            broker: "Demo",
            force: false,
            messageType: "MSG_TYPE_MONITOR_DAILY_WINNER_LOSER",
            priority: 1,
            profitClosed: -1331.55,
            profitClosedMT4: -1331.55,
            recordDate: "2024-08-05",
            threshold: -100,
            timestamp: 1722843360,
            user: "Demo:MT4-Demo:xxxxx84:",
            server_name: "MT4-Demo",
            login: "xxxxx84",
            name: "Bui ",
            closed_profit_eod: -1331.55,
            closed_profit: -1331.55,
        },
        {
            books: "B",
            broker: "Demo",
            force: false,
            messageType: "MSG_TYPE_MONITOR_DAILY_WINNER_LOSER",
            priority: 1,
            profitClosed: -211.68,
            profitClosedMT4: -211.68,
            recordDate: "2024-08-05",
            threshold: -100,
            timestamp: 1722843390,
            user: "Demo:MT4-Demo:xxxxx25:",
            server_name: "MT4-Demo",
            login: "xxxxx25",
            name: "Nguy",
            closed_profit_eod: -211.68,
            closed_profit: -211.68,
        },
        {
            books: "TEST",
            broker: "Demo",
            force: false,
            messageType: "MSG_TYPE_MONITOR_DAILY_WINNER_LOSER",
            priority: 1,
            profitClosed: -279,
            profitClosedMT4: 2794,
            recordDate: "2024-08-05",
            threshold: -100,
            timestamp: 1722843379,
            user: "Demo:MT4-Demo:xxxxx58:",
            server_name: "MT4-Demo",
            login: "xxxxx58",
            name: "Bui ",
            closed_profit_eod: -279,
            closed_profit: 2794,
        },
        {
            books: "B",
            broker: "Demo",
            force: false,
            messageType: "MSG_TYPE_MONITOR_DAILY_WINNER_LOSER",
            priority: 1,
            profitClosed: -371.83,
            profitClosedMT4: -1890.49,
            recordDate: "2024-08-05",
            threshold: -100,
            timestamp: 1722843407,
            user: "Demo:MT4-Demo:xxxxx89:",
            server_name: "MT4-Demo",
            login: "xxxxx89",
            name: "Pham",
            closed_profit_eod: -371.83,
            closed_profit: -1890.49,
        },
        {
            books: "B",
            broker: "Demo",
            force: false,
            messageType: "MSG_TYPE_MONITOR_DAILY_WINNER_LOSER",
            priority: 1,
            profitClosed: -205.25,
            profitClosedMT4: -435.24,
            recordDate: "2024-08-05",
            threshold: -100,
            timestamp: 1722843360,
            user: "Demo:MT4-Demo:xxxxx88:",
            server_name: "MT4-Demo",
            login: "xxxxx88",
            name: "LE M",
            closed_profit_eod: -205.25,
            closed_profit: -435.24,
        },
        {
            books: "B",
            broker: "Demo",
            force: false,
            messageType: "MSG_TYPE_MONITOR_DAILY_WINNER_LOSER",
            priority: 1,
            profitClosed: -593.53,
            profitClosedMT4: -700.33,
            recordDate: "2024-08-05",
            threshold: -100,
            timestamp: 1722843367,
            user: "Demo:MT4-Demo:xxxxx00:",
            server_name: "MT4-Demo",
            login: "xxxxx00",
            name: "0xb7",
            closed_profit_eod: -593.53,
            closed_profit: -700.33,
        },
        {
            books: "TEST",
            broker: "Demo",
            force: false,
            messageType: "MSG_TYPE_MONITOR_DAILY_WINNER_LOSER",
            priority: 1,
            profitClosed: -800,
            profitClosedMT4: -800,
            recordDate: "2024-08-05",
            threshold: -100,
            timestamp: 1722843377,
            user: "Demo:MT4-Demo:xxxxx46:",
            server_name: "MT4-Demo",
            login: "xxxxx46",
            name: "Yuen",
            closed_profit_eod: -800,
            closed_profit: -800,
        },
        {
            books: "B",
            broker: "Demo",
            force: false,
            messageType: "MSG_TYPE_MONITOR_DAILY_WINNER_LOSER",
            priority: 1,
            profitClosed: -330.78,
            profitClosedMT4: -330.78,
            recordDate: "2024-08-05",
            threshold: -100,
            timestamp: 1722843351,
            user: "Demo:MT4-Demo:xxxxx02:",
            server_name: "MT4-Demo",
            login: "xxxxx02",
            name: "Chua",
            closed_profit_eod: -330.78,
            closed_profit: -330.78,
        },
        {
            books: "TEST",
            broker: "Demo",
            force: false,
            messageType: "MSG_TYPE_MONITOR_DAILY_WINNER_LOSER",
            priority: 1,
            profitClosed: -2194.51,
            profitClosedMT4: -2197.06,
            recordDate: "2024-08-05",
            threshold: -100,
            timestamp: 1722843351,
            user: "Demo:MT4-Demo:xxxxx45:",
            server_name: "MT4-Demo",
            login: "xxxxx45",
            name: "Bui ",
            closed_profit_eod: -2194.51,
            closed_profit: -2197.06,
        },
        {
            books: "TEST",
            broker: "Demo",
            force: false,
            messageType: "MSG_TYPE_MONITOR_DAILY_WINNER_LOSER",
            priority: 1,
            profitClosed: -1159.25,
            profitClosedMT4: -1159.25,
            recordDate: "2024-08-05",
            threshold: -100,
            timestamp: 1722843356,
            user: "Demo:MT4-Demo:xxxxx49:",
            server_name: "MT4-Demo",
            login: "xxxxx49",
            name: "Pham",
            closed_profit_eod: -1159.25,
            closed_profit: -1159.25,
        },
        {
            books: "B",
            broker: "Demo",
            force: false,
            messageType: "MSG_TYPE_MONITOR_DAILY_WINNER_LOSER",
            priority: 1,
            profitClosed: -1948.07,
            profitClosedMT4: -1947.95,
            recordDate: "2024-08-05",
            threshold: -100,
            timestamp: 1722843360,
            user: "Demo:MT4-Demo:xxxxx01:",
            server_name: "MT4-Demo",
            login: "xxxxx01",
            name: "Wenl",
            closed_profit_eod: -1948.07,
            closed_profit: -1947.95,
        },
        {
            books: "B",
            broker: "Demo",
            force: false,
            messageType: "MSG_TYPE_MONITOR_DAILY_WINNER_LOSER",
            priority: 1,
            profitClosed: -220.3,
            profitClosedMT4: -235.16,
            recordDate: "2024-08-05",
            threshold: -100,
            timestamp: 1722843386,
            user: "Demo:MT4-Demo:xxxxx98:",
            server_name: "MT4-Demo",
            login: "xxxxx98",
            name: "Nguy",
            closed_profit_eod: -220.3,
            closed_profit: -235.16,
        },
        {
            books: "TEST",
            broker: "Demo",
            force: false,
            messageType: "MSG_TYPE_MONITOR_DAILY_WINNER_LOSER",
            priority: 1,
            profitClosed: -292.6,
            profitClosedMT4: -292.6,
            recordDate: "2024-08-05",
            threshold: -100,
            timestamp: 1722843404,
            user: "Demo:MT4-Demo:xxxxx04:",
            server_name: "MT4-Demo",
            login: "xxxxx04",
            name: "Nguy",
            closed_profit_eod: -292.6,
            closed_profit: -292.6,
        },
    ],
    alertHeader: [
        { text: "Login", value: "login", align: "left" },
        { text: "Name", value: "name", align: "left" },
        { text: "Books", value: "books", align: "left" },
        {
            text: "Closed Profit EOD",
            value: "closed_profit_eod",
            align: "right",
        },
    ],
    alertDataAmount: 0,
    csvHeader: {
        "Server Name": "server_name",
        Login: "login",
        Books: "books",
        "Closed Profit EOD": "closed_profit_eod",
        "Closed Profit MT": "closed_profit",
    },
    selectedDate: null,
};

const actions = {
    getDailyLoserAction({ commit }, data) {
        if (common.getMT4TimeString().split(" ")[0] !== state.selectedDate) {
            commit("UPDATE_LOADING", true);
            getDailyLoser(data).then((res) => {
                res.data.data.forEach((item) => {
                    item["books"] = item.books.replace(/[[\]]/g, "");
                });
                commit("UPDATE_ALERT_DATA", res.data.data);
                commit("UPDATE_AMOUNT", res.data.count);
                commit("UPDATE_LOADING", false);
            });
        }
    },
    processDailyLoser({ commit }, data) {
        if (common.getMT4TimeString().split(" ")[0] === state.selectedDate) {
            commit("UPDATE_LOADING", true);
            let result = [];
            for (let item in data) {
                if (item.includes("--loser")) {
                    let temp = data[item];
                    temp.books = temp.books.join(",");
                    temp.server_name = temp.user.split(":")[1];
                    temp.login = temp.user.split(":")[2];
                    temp.name = item
                        .replaceAll("--loser", "")
                        .split(":")
                        .slice(3)
                        .join(" ");
                    temp.closed_profit_eod = temp.profitClosed;
                    temp.closed_profit = temp.profitClosedMT4;
                    result.push(temp);
                }
            }
            console.log(result);
            commit("UPDATE_ALERT_DATA", result);
            commit("UPDATE_AMOUNT", result.length);
            commit("UPDATE_LOADING", false);
        }
    },
};

const mutations = {
    /**
     * Update loading
     * @param   {[type]}  state  [state description]
     * @param   {[type]}  data   [data description]
     * @return  {[type]}         [return description]
     */
    UPDATE_LOADING(state, data) {
        state.loading = data;
    },
    UPDATE_ALERT_DATA(state, data) {
        state.alertData = data;
    },
    UPDATE_ALERT_HEADER(state, data) {
        state.alertHeader = data;
    },
    UPDATE_SELECTED_DATE(state, data) {
        state.selectedDate = data;
    },
    UPDATE_AMOUNT(state, data) {
        state.alertDataAmount = data;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
