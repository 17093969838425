import { getConfig } from "@services/alert/config";

const state = {
    loading: false,
    monitorData: [],
    monitorHeader: [
        { text: "Book", value: "book", align: "left" },
        { text: "Bymbol", value: "symbol", align: "left" },
        {
            text: "Floating Profit EOD",
            value: "floatingProfitEod",
            align: "left",
        },
        { text: "Net Volume", value: "netVolume", align: "left" },
        { text: "Net Volume TH", value: "netVolumeThreshold", align: "left" },
        { text: "Profit Threshold", value: "profitThreshold", align: "left" },
        { text: "Trigger Time", value: "trigger_time", align: "left" },
    ],
    alertData: [],
    alertHeader: [
        { text: "Book", value: "book", align: "left" },
        { text: "Bymbol", value: "symbol", align: "left" },
        { text: "Net Volume", value: "net_volume", align: "left" },
        { text: "Net Volume TH", value: "net_volume_threshold", align: "left" },
        {
            text: "Floating Profit EOD",
            value: "floating_profit_eod",
            align: "left",
        },
        { text: "Profit Threshold", value: "profit_threshold", align: "left" },
        { text: "Alert Status", value: "alert_status", align: "left" },
        { text: "Trigger Time", value: "trigger_time", align: "left" },
    ],
    yValue: [],
    yName: [],
    mockYValue: [
        {
            volume: -9,
            pnl: -1538.44,
        },
        {
            volume: 3.21,
            pnl: 12.94,
        },
        {
            volume: 2.54,
            pnl: 349.59,
        },
        {
            volume: -1,
            pnl: -200.57,
        },
        {
            volume: -1,
            pnl: 291.81,
        },
        {
            volume: -1,
            pnl: -430.51,
        },
        {
            volume: 1,
            pnl: -14.18,
        },
        {
            volume: 0.09,
            pnl: -324.36,
        },
        {
            volume: 0,
            pnl: -3.53,
        },
        {
            volume: 0,
            pnl: 114.85,
        },
        {
            volume: 0,
            pnl: -74.54,
        },
    ],
    mockYName: [
        "USDJPY",
        "XAUUSD",
        "BTCUSD",
        "WTIUSD",
        "EURAUD",
        "JP225",
        "GBPCAD",
        "EURUSD",
        "GBPJPY",
        "GBPAUD",
        "GBPUSD",
        // "US100",
        // "GBPAUD",
    ],
    currentBook: "B",
    profitThreshold: 0,
    volumeThreshold: 0,
};

const actions = {
    getMandateConfigAction({ commit, state }) {
        getConfig("mandate").then((res) => {
            if (res.data.length) {
                state.profitThreshold = res.data[0].profit_threshold;
                state.volumeThreshold = res.data[0].volume_threshold;
            }
        });
        setTimeout(() => {
            commit("UPDATE_Y_VALUE", state.mockYValue);
            commit("UPDATE_Y_NAME", state.mockYName);
        }, 200);
    },
    processChartMandate({ commit, state }, data) {
        let filteredAlertData = Object.keys(data).map((k) => {
            return data[k];
        });

        let tempData = filteredAlertData.filter(
            (item) => item.book === state.currentBook
        );
        tempData = tempData.sort((a, b) =>
            a.net_volume > b.net_volume
                ? 1
                : b.net_volume > a.net_volume
                ? -1
                : 0
        );
        let yName = tempData.map((item) => item.symbol);
        let yValue = tempData.map((item) => {
            return { volume: item.netVolume, pnl: item.floatingProfitEod };
        });
    },
};

const mutations = {
    UPDATE_Y_NAME(state, data) {
        state.yName = data;
    },
    UPDATE_Y_VALUE(state, data) {
        state.yValue = data;
    },
    /**
     * Update monitor data
     * @param   {[type]}  state  [state description]
     * @param   {[type]}  data   [data description]
     * @return  {[type]}         [return description]
     */
    UPDATE_MONITOR_DATA(state, data) {
        state.monitorData = data;
    },
    /**
     * Update alert data
     * @param   {[type]}  state  [state description]
     * @param   {[type]}  data   [data description]
     * @return  {[type]}         [return description]
     */
    UPDATE_ALERT_DATA(state, data) {
        state.alertData = data;
    },
    /**
     * Update loading
     * @param   {[type]}  state  [state description]
     * @param   {[type]}  data   [data description]
     * @return  {[type]}         [return description]
     */
    UPDATE_LOADING(state, data) {
        state.loading = data;
    },
    /**
     * Update current book
     * @param   {[type]}  state  [state description]
     * @param   {[type]}  data   [data description]
     * @return  {[type]}         [return description]
     */
    UPDATE_CURRENT_BOOK(state, data) {
        state.currentBook = data;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
