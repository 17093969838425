
export function getLPSymbolMap(query) {
    const mockData = {
        status: 200,
        data: [
            {
                "lp_id": 1,
                "lp_name": "lp-1",
                "api_code": "AUD/CNH",
                "pk": "AUDCNH",
                "symbol": "AUD/CNH",
                "ratio": 1,
                "created_at": "2022-08-25 00:00:00",
                "updated_at": "2022-08-25 00:00:00",
                "id": 38
            },
            {
                "lp_id": 1,
                "lp_name": "lp-1",
                "api_code": "AUDJPY",
                "pk": "AUDJPY",
                "symbol": "AUD/JPY",
                "ratio": 1,
                "created_at": "2022-02-11 15:43:03",
                "updated_at": "2022-02-11 15:43:03",
                "id": 39
            },
            {
                "lp_id": 1,
                "lp_name": "lp-1",
                "api_code": "AUD/NZD",
                "pk": "AUDNZD",
                "symbol": "AUD/NZD",
                "ratio": 1,
                "created_at": "2022-08-25 00:00:00",
                "updated_at": "2022-08-25 00:00:00",
                "id": 40
            },
            {
                "lp_id": 2,
                "lp_name": "lp-2",
                "api_code": "AUD/USD",
                "pk": "AUDUSD",
                "symbol": "AUD/USD",
                "ratio": 1,
                "created_at": "2022-08-25 00:00:00",
                "updated_at": "2022-08-25 00:00:00",
                "id": 41
            },
            {
                "lp_id": 2,
                "lp_name": "lp-2",
                "api_code": "CAD/CHF",
                "pk": "CADCHF",
                "symbol": "CAD/CHF",
                "ratio": 1,
                "created_at": "2022-08-25 00:00:00",
                "updated_at": "2022-08-25 00:00:00",
                "id": 42
            },
            {
                "lp_id": 2,
                "lp_name": "lp-2",
                "api_code": "CADJPY",
                "pk": "CADJPY",
                "symbol": "CAD/JPY",
                "ratio": 1,
                "created_at": "2022-02-11 15:43:03",
                "updated_at": "2022-02-11 15:43:03",
                "id": 43
            },
            {
                "lp_id": 2,
                "lp_name": "lp-2",
                "api_code": "CHFJPY",
                "pk": "CHFJPY",
                "symbol": "CHF/JPY",
                "ratio": 1,
                "created_at": "2022-02-11 15:43:03",
                "updated_at": "2022-02-11 15:43:03",
                "id": 44
            },
            {
                "lp_id": 2,
                "lp_name": "lp-2",
                "api_code": "EUR/AUD",
                "pk": "EURAUD",
                "symbol": "EUR/AUD",
                "ratio": 1,
                "created_at": "2022-08-25 00:00:00",
                "updated_at": "2022-08-25 00:00:00",
                "id": 45
            },
            {
                "lp_id": 2,
                "lp_name": "lp-2",
                "api_code": "EUR/CHF",
                "pk": "EURCHF",
                "symbol": "EUR/CHF",
                "ratio": 1,
                "created_at": "2022-08-25 00:00:00",
                "updated_at": "2022-08-25 00:00:00",
                "id": 46
            },
            {
                "lp_id": 2,
                "lp_name": "lp-2",
                "api_code": "EURJPY",
                "pk": "EURJPY",
                "symbol": "EUR/JPY",
                "ratio": 1,
                "created_at": "2022-02-11 15:43:03",
                "updated_at": "2022-02-11 15:43:03",
                "id": 47
            },
            {
                "lp_id": 2,
                "lp_name": "lp-2",
                "api_code": "EUR/NZD",
                "pk": "EURNZD",
                "symbol": "EUR/NZD",
                "ratio": 1,
                "created_at": "2022-08-25 00:00:00",
                "updated_at": "2022-08-25 00:00:00",
                "id": 48
            },
        ]
    };
    const returnVal = new Promise((resolve, _) => resolve(mockData));
    return returnVal;
}

export function BatchAddSymbolMap(params) {
    const mockData = {
        status: 200,
        data: { 'msg': 'Add symbol map successfully' }
    };
    const returnVal = new Promise((resolve, _) => resolve(mockData));
    return returnVal;
}

export function updateLPSymbolMap(params) {
    const mockData = {
        status: 200,
        data: { 'msg': 'Updated symbol map successfully' }
    };
    const returnVal = new Promise((resolve, _) => resolve(mockData));
    return returnVal;
}

export function deleteLPSymbolMap(params) {
    const mockData = {
        status: 200,
        data: { 'msg': 'Delete symbol map successfully' }
    };
    const returnVal = new Promise((resolve, _) => resolve(mockData));
    return returnVal;
}
