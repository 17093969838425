
export function getTransaction (query) {
    const mockData = {
        status: 200,
        data: [ {
            "login": "xxxxxx92",
            "group": "manager",
            "book": "S",
            "task_withdraw": 0,
            "withdraw": 0,
            "task_deposit": 0,
            "deposit": 0,
            "credit": 0
        },
        {
            "login": "xxxxxx89",
            "group": "manager",
            "book": "S",
            "task_withdraw": 0,
            "withdraw": 0,
            "task_deposit": 0,
            "deposit": 0,
            "credit": 0
        },
        {
            "login": "xxxxxx73",
            "group": "demoforex",
            "book": "B",
            "task_withdraw": 0,
            "withdraw": 0,
            "task_deposit": 0,
            "deposit": 0,
            "credit": 0
        },
        {
            "login": "xxxxxx17",
            "group": "system",
            "book": "Test",
            "task_withdraw": 0,
            "withdraw": 0,
            "task_deposit": 0,
            "deposit": 0,
            "credit": 0
        },
        {
            "login": "xxxxxx04",
            "group": "system",
            "book": "B",
            "task_withdraw": 0,
            "withdraw": 0,
            "task_deposit": 0,
            "deposit": 0,
            "credit": 0
        },
        {
            "login": "xxxxxx03",
            "group": "demoforex",
            "book": "S",
            "task_withdraw": 0,
            "withdraw": 0,
            "task_deposit": 0,
            "deposit": 0,
            "credit": 0
        },
        {
            "login": "xxxxxx01",
            "group": "preliminary",
            "book": "B",
            "task_withdraw": 0,
            "withdraw": 0,
            "task_deposit": 0,
            "deposit": 0,
            "credit": 0
        },
        {
            "login": "xxxxxx64",
            "group": "preliminary",
            "book": "B",
            "task_withdraw": 0,
            "withdraw": 0,
            "task_deposit": 0,
            "deposit": 0,
            "credit": 0
        },
        {
            "login": "xxxxxx78",
            "group": "manager",
            "book": "B",
            "task_withdraw": 0,
            "withdraw": 0,
            "task_deposit": 0,
            "deposit": 0,
            "credit": 0
        },]
    };
    const returnVal = new Promise((resolve, _) => resolve(mockData));
    return returnVal;
}